import React from "react";
import "./PaymentModal.css";
import {AdvancePaymentIcon, closeIcon} from "../../../helpers/Icons";
import { useNavigate } from "react-router-dom";
const PaymentModal = ({productID,buyMachineId,modalAction}) => {
    const navigate=useNavigate()
    return (
        <div className="bi-popup-wrap">
            <div className="back" onClick={() => modalAction(false)}></div>
            <div className="inner">
                <button onClick={() =>navigate(`/buy/machine-page?id=${productID}&buyMachineId=${buyMachineId}`)} className="close">{closeIcon({width:16,height:16})}</button>
                <div className="heading-600-20 heading-600-20-16">Advance Payment Successful</div>
                <div className="payment-icon">{AdvancePaymentIcon({width:189,height:189})}</div>
                <div className="heading-400-14-12">Advance amount has been paid successfully. You have almost completed the purchase!!</div>
                <button type="button" className="continue-btn" onClick={()=>navigate(`/buy/machine-page?id=${productID}&buyMachineId=${buyMachineId}`)}>Continue</button>
            </div>
        </div>
    );
}
export default PaymentModal;