// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  position: relative;
  margin: 10px 0;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: top 0.3s, font-size 0.3s;
  font-size: 16px;
}

.input-label-top {
  top: 0;
  font-size: 12px;
  background-color: #FFF;
}

/* Additional styles you can apply to the input fields, e.g., focus styles */
input:focus {
  outline: none;
  border: none
}

.contactusForm {
  position: absolute;
  top: -80%;
  right: 0;
}

.contactContainer{
  padding: 3rem !important;
  margin: 0 !important;
}

.css-yy8y9l-JoyTextarea-root{
  border : 1px solid #AAA !important;
  background-color: #FFF !important;
}
 
.css-sws92j-JoyTextarea-textarea {
color: #211E24 !important;
}
@media only screen and (min-width:320px) and (max-width:768px) {
  .contactusForm {
    position: static !important;
    top: 0;
    right: 0;
  }
  .contactContainer{
    padding: 1rem !important;
    margin: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/Contactus/Form.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,MAAM;EACN,eAAe;EACf,sBAAsB;AACxB;;AAEA,4EAA4E;AAC5E;EACE,aAAa;EACb;AACF;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,wBAAwB;EACxB,oBAAoB;AACtB;;AAEA;EACE,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;AACA,yBAAyB;AACzB;AACA;EACE;IACE,2BAA2B;IAC3B,MAAM;IACN,QAAQ;EACV;EACA;IACE,wBAAwB;IACxB,oBAAoB;EACtB;AACF","sourcesContent":[".input-container {\r\n  position: relative;\r\n  margin: 10px 0;\r\n}\r\n\r\ninput {\r\n  width: 100%;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  font-size: 16px;\r\n}\r\n\r\nlabel {\r\n  position: absolute;\r\n  pointer-events: none;\r\n  left: 10px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  transition: top 0.3s, font-size 0.3s;\r\n  font-size: 16px;\r\n}\r\n\r\n.input-label-top {\r\n  top: 0;\r\n  font-size: 12px;\r\n  background-color: #FFF;\r\n}\r\n\r\n/* Additional styles you can apply to the input fields, e.g., focus styles */\r\ninput:focus {\r\n  outline: none;\r\n  border: none\r\n}\r\n\r\n.contactusForm {\r\n  position: absolute;\r\n  top: -80%;\r\n  right: 0;\r\n}\r\n\r\n.contactContainer{\r\n  padding: 3rem !important;\r\n  margin: 0 !important;\r\n}\r\n\r\n.css-yy8y9l-JoyTextarea-root{\r\n  border : 1px solid #AAA !important;\r\n  background-color: #FFF !important;\r\n}\r\n \r\n.css-sws92j-JoyTextarea-textarea {\r\ncolor: #211E24 !important;\r\n}\r\n@media only screen and (min-width:320px) and (max-width:768px) {\r\n  .contactusForm {\r\n    position: static !important;\r\n    top: 0;\r\n    right: 0;\r\n  }\r\n  .contactContainer{\r\n    padding: 1rem !important;\r\n    margin: 0 !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
