import React, { useEffect } from 'react'
import { closeIcon } from '../../../helpers/Icons';
import { gql } from '@apollo/client';
import client from '../../Services/ServicesPopup/apolloclient';


const REASON_REJCTION_API=gql`
query customerScheduleVisitsRejectMymachine($ompUserId:String!) {
    customerScheduleVisitsRejectMymachine(ompUserId: $ompUserId) {
      message
      code
      response
    }
  }
  
`
const  ReasonRejectionPopup = ({modalAction,productDetails}) => {
  const onRejectHndler=async()=>{
try {
    const {data}=await client.mutate({
        mutation:REASON_REJCTION_API,
        variables:{"ompUserId": "VXNlcjo2Mw=="}
    })
    console.log("visit REjected==>>>>",data);
} catch (error) {
    console.log("Rejection API ERROR",error);
}
  }
   useEffect(()=>{
onRejectHndler()
   },[]) 
    return (
        <div className="bi-popup-wrap">
            <div className="back" onClick={() => modalAction(false)}></div>
            <div className="inner align-items-center">
                <button onClick={() => modalAction(false)} className="close">{closeIcon({width:16,height:16})}</button>
               
                <div className="heading-600-20-16">Reason for Visit Rejection</div>
                <div className='pt-4'>
                <img src='asset/ReasonRejection.png' alt='ReasonRejection.png'/>
                </div>
               <p className='heading-400-14-12 op-80'>Unavailable on the selected dates, please schedule for a later date</p>
               <div className='w-100 text-center'>
               <button type="button" className="continue-btn" onClick={()=>modalAction(false)}>Submit</button>
               
                </div>
            </div>
        </div>
    );
}

export default  ReasonRejectionPopup