
import React,{useEffect} from 'react'
import FinaceFifthSection from '../Finance/FinaceFifthSection'
import FinancefirstSection from '../Finance/FinancefirstSection'
import { maximumValue,genuineBuyers,quickSell ,newHomePage1,newHomePage2,newHomePage3,newHomePage4,newHomePage5} from '../../helpers/Icons'
import HomePageBanner from './HomePageBanner'
import NewFooter from '../Footer/NewFooter'

const NewHomePage = () => {
    const items=[
    {imagesrc:newHomePage1({width:60 ,height:60}),title:"Buy Machine",para:"50 + Brands Available",navi:"#",btnName:"Buy Now"},
    {imagesrc:newHomePage2({width:60 ,height:60}),title:"Service & Maintainence",para:"500 + Satisfied Customers",navi:"#",btnName:"Buy Now"},
    {imagesrc:newHomePage3({width:60, height:60}),title:"Loan and Lease Machine",para:"400 + Equipment Financed",navi:"#",btnName:"Buy Now"},
    {imagesrc:newHomePage4({width:60, height:60}),title:"Sell Machine",para:"1500  Machines  + Daily Visitors",navi:"#",btnName:"Buy Now"},
    {imagesrc:newHomePage5({width:60, height:60}),title:"Tools Spares and Consumables",para:"500 + Products Available",navi:"#",btnName:"Buy Now"}
    ]

    const bannerTopSection={title:"India’s 1st One Stop Platform for All Equipment Needs",para:"We strive for your satisfaction, making us the preferred choice for reliable and affordable equipment solutions"}
    
    const product={  imgurl:"/origaReach.svg",heading: "We cater to over 35 Industries",
    para: "From Industrial Engineering, Medical, Pharma to Automotive and many more"}
    const topSliderData = [
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        { message: "Radiology",imgsrc:"/asset/ftopslider1.png" },{ message: "Nephrology",imgsrc:"/asset/ftopslider2.png" },{ message: "Ophthalmology",imgsrc:"/asset/ftopslider3.png" },
        ];
        const bottomSliderData = [
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        { message: "Oncology",imgsrc:"/asset/fbottomslider1.png"  },{ message: "Automotive",imgsrc:"/asset/fbottomslider2.png" },{ message: "Fabrication",imgsrc:"/asset/fbottomslider3.png" },
        ];
    const advantageData = [
        {title: "",description: "50+ quality checks on machines",icon: quickSell({width:60,height:60})},
        {title: "",description: "Quick 24-hour Response To Machine Breakdowns",icon: genuineBuyers({width:60,height:60})},
        {title: "",description: "Customised Financial Solutions",icon:  maximumValue({width:60,height:60})},
        {title: "",description: "Best value for your machine",icon: genuineBuyers({width:60,height:60})},
        {title: "",description: "Unified Hub for Tools, Spares & Consumables",icon:  maximumValue({width:60,height:60})}
      ];
      const advantageTopSection={imgurl:"/OrigaWhy.svg",title:"Origa Advantage",para:"Step into a World of Superior Equipment Management Experience"}
    useEffect(() => {
    window.scrollTo(0,0)
    }, [])
    
      return (
        <>
        <HomePageBanner items={items} bannerTopSection={bannerTopSection}/>
       
    <FinancefirstSection  topSliderData={topSliderData} product={product} bottomSliderData={bottomSliderData}/>

    <div className="container-fluid sectionfour">
    <div className="max-container text-center">
    <img src={advantageTopSection.imgurl} alt='whyOriga'/>
    <div className='text-center heading-600-24-20 c-green'>{advantageTopSection.title}</div>
    <div className='heading-400-16-14 op-80 text-center ori-addvantage-para'>{advantageTopSection.para}</div>
        <div className="row mt-32-992">
            {advantageData.map((advantage,index) => (
                <div key={index} className="col-lg-2 col-6 mx-auto step-p-16">
                    <div className="cust-box-card">
                        <div className="svg-icon mb-2" >{advantage.icon}</div>
                        <div className="title heading-600-16-14">{advantage.title}</div>
                        <div className="desc heading-400-14-12 op-80">{advantage.description}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>
    
    <FinaceFifthSection title={"Looking for a specific Brand?"} para={"From Machines to tools to finance everything you need in one place"} searchHide={true}/>
    <NewFooter/>   
        </>
      )
    }
export default NewHomePage