import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import Footer from "../../Footer/Footer";
import {
  barIcon,
  botIcon,
  editIcon,
  inspectionIcon,
  eyeIcon,
  heratfillIcon,
  registrationIcon,
  documnetIcon,
  downloadIcon,
  shareIcon,
  enListingIcon,
  paymentRecievedIcon,
  downIcon,
  ratingIcon,
  ratingFillIcon,
  ratinghalfIcon,
  upIcon,
} from "../../../helpers/Icons";
import MachineSaleImage from "./MachineSaleImage";
import RemoveMachineModal from "../Modals/RemoveMachineModal";
import ScheduleModal from "../Modals/ScheduleModal";
import UserModal from "../Modals/UserModal";
import MachineLocationModal from "../Modals/MachineLocationModal";
import UploadDocumentModal from "../Modals/UploadDocumentModal";
import VisitingDaysModal from "../Modals/VisitingDaysModal";
import LoginModal from "../Modals/LoginModal";
import "./ViewMachineSale.css";
import { useLocation, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import GoldenStart from "../../SubComponent/AllSvgs/GoldenStart";
import HalfGoldenStar from "../../SubComponent/AllSvgs/HalfGoldenStar";
import Loader from "../../SubComponent/Loader";
const clientMachine = new ApolloClient({
  uri: "https://devextension.origa.market/graphql/",
  cache: new InMemoryCache(),
});

const GET_MACHINE_DETAILS = gql`
  query customerSellMachineDetails($customerId: String!, $pdId: BigInt!) {
    customerSellMachineDetails(customerId: $customerId, pdId: $pdId) {
      message
      code
      response
    }
  }
`;

const UpdateProductDraftSchedule = gql`
  mutation UpdateProductDraftSchedule(
    $draftData: UpdateProductDraftScheduleInput!
  ) {
    updateProductdraftschedule(draftData: $draftData) {
      message
      __typename
    }
  }
`;

const ViewMachineSale = () => {
  const breadcrumbsItems = [{ name: "Account", link: "/myaccount" },{ name:"My Machine", link: "/buy/my-machine" }];
  const boldtitle = "Up for Sale";
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loggedin = localStorage.getItem("userToken");
  const [showUserModel, setShowUserModel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [productDETAILS, setProductDETAILS] = useState({});
const [isShowPriceBreackdoen,setIsShowPriceBreackdoen]=useState(false)
  const productId = queryParams.get("id");

  if (loggedin) {
  }
  const [tabsClasses, setTabsClasses] = useState({
    registration: "active",
    inspection: "pending",
    documentation: "pending",
    enlisting: "pending",
    sale: "pending",
    payment: "pending",
  });
  const [machineDetail, setMachineDetail] = useState({
    name: "CNC Machine",
    status: "processing",
    brand: "Hitachi",
    images: [
      "/asset/image529(1).png",
      "/asset/image529(1).png",
      "/asset/image529(1).png",
    ],
    activeStep: "registration",
    is_scheduled: false,
    registration_date: "12th June 2023",
    inspection_date: "",
    documentation_date: "",
    enlisting_date: "",
    sale_date: "",
    payment_date: "",
    dates: [],
    times: [],
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    address1: "",
    zipcode: "",
    documents: [],
    visiting_days: [],
    visiting_hours: [],
    listed_price: "₹4,00,000",
    inspection_price: "₹3,50,000",
    wishlist: "+24",
    wishlist_percent: "+23%",
    wishlist_total: "164",
    view: "+145",
    view_percent: "+10%",
    view_total: "1200",
    years_old: "7 Years Old",
    amount_received: "₹ 3,50,000",
    transaction_id: "53574647",
    transaction_date: "22nd July 2023",
    rating: "4.5",
    review:
      "Selling at Origa has been a great experience, once I filled out the form they really took care of everything else. I got a much better deal then what I was being offered earlier.",
  });
  const [hasMultipleForm, setHasMultipleForm] = useState(true);
  const [showRemoveRequestModal, setShowRemoveRequestModal] = useState(false);
  const [showScheduledModal, setShowScheduledModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showVisitingDaysModal, setShowVisitingModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading,setIsLoading]=useState(true)
  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const handleCustomChange = (name, value) => {
    setMachineDetail((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("id");
        const productId = queryParams.get("id");
        let reqBody = { customerId: id, pdId: productId }; // Ensure the variable names match your GraphQL query
        console.log(reqBody);

        const { data } = await clientMachine.mutate({
          mutation: GET_MACHINE_DETAILS,
          variables: reqBody,
        });

        console.log("Main data useEffect", data);
        // Handle the response data as needed (not shown in the provided code).
        setProductDETAILS(data?.customerSellMachineDetails?.response);
        setIsLoading(false);
        machineDetail.first_name =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.firstname;
        machineDetail.last_name =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.lastname;
        machineDetail.email =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.useremailid;
        machineDetail.address =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.address1;
        machineDetail.address1 =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.address2;
        machineDetail.zipcode =
          data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.pincode;
        machineDetail.activeStep =
          data?.customerSellMachineDetails?.response?.process_details[
            data?.customerSellMachineDetails?.response?.process_details.length -
              1
          ]["task_name"];
        console.log(
          "API Response ==>",
          data?.customerSellMachineDetails?.response
        );
        console.log("machine details data===>>>", machineDetail);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const productId = queryParams.get("id");
      let reqBody = { customerId: id, pdId: productId }; // Ensure the variable names match your GraphQL query
      console.log(reqBody);

      const { data } = await clientMachine.mutate({
        mutation: GET_MACHINE_DETAILS,
        variables: reqBody,
      });

      // Handle the response data as needed (not shown in the provided code).
      setProductDETAILS(data?.customerSellMachineDetails?.response);
      setIsLoading(false);
      machineDetail.first_name =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.firstname;
      machineDetail.last_name =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.lastname;
      machineDetail.email =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.contactdetails?.useremailid;
      machineDetail.address =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.address1;
      machineDetail.address1 =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.address2;
      machineDetail.zipcode =
        data?.customerSellMachineDetails?.response?.inspection_details?.contact_info?.machinelocation?.pincode;
      // machineDetail.activeStep=data?.customerSellMachineDetails?.response?product_status
      console.log(
        "API Response ==>",
        data?.customerSellMachineDetails?.response
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(machineDetail, "machineDetail");
  const hasScheduled = () => {
    // inspection_details
    console.log(machineDetail, "machineDetail");
    let shown = false;
    if (
      machineDetail.dates.length == 0 ||
      machineDetail.times.length == 0 ||
      !machineDetail.first_name ||
      !machineDetail.last_name ||
      !machineDetail.email ||
      !machineDetail.address ||
      !machineDetail.zipcode
    ) {
      shown = true;
    }
    if (!shown && !machineDetail.is_scheduled) {
      // setTimeout(() => {
      //     setHasMultipleForm(false);
      //     handleCustomChange("is_scheduled",true);
      //     handleCustomChange("activeStep","inspection");
      //     handleCustomChange("inspection_date","15th June 2023");
      //     setTabsClasses((prevState) => ({...prevState,registration:"completed",inspection:"active"}));
      // },20000);
    }
    return productDETAILS?.inspection_details?.inspection_date_time
      ? false
      : true;
  };
  const handleRemoveRequestModal = (status) => {
    if (status) {
      setShowRemoveRequestModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowRemoveRequestModal(false);
      document.body.classList.remove("no-overflow");
    }
  };
  const handleScheduledModal = (status) => {
    if (status) {
      setShowScheduledModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowScheduledModal(false);
      document.body.classList.remove("no-overflow");
    }
  };
  const handleUserModal = (status) => {
    if (status) {
      setShowUserModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowUserModal(false);
      document.body.classList.remove("no-overflow");
    }
  };
  const handleLocationModal = (status) => {
    if (status) {
      setShowLocationModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowLocationModal(false);
      document.body.classList.remove("no-overflow");
    }
  };
  const handleUploadDocumentModal = (status) => {
    if (status) {
      setShowUploadDocumentModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowUploadDocumentModal(false);
      document.body.classList.remove("no-overflow");
      if (
        machineDetail.documents.length > 0 &&
        machineDetail.activeStep == "Inspection"
      ) {
        setTimeout(() => {
          handleCustomChange("activeStep", "Enlisting");
          handleCustomChange("documentation_date", "17th June 2023");
          handleCustomChange("enlisting_date", "20th June 2023");
          setTabsClasses((prevState) => ({
            ...prevState,
            inspection: "completed",
            documentation: "completed",
            enlisting: "active",
          }));
        }, 2000);
      }
    }
  };
  const handleVisitingDaysModal = (status) => {
    if (status) {
      setShowVisitingModal(status);
      document.body.classList.add("no-overflow");
    } else {
      setShowVisitingModal(false);
      document.body.classList.remove("no-overflow");
    }
  };
  const renderDatesHtml = (dates) => {
    let dateArry = [];
    // dates.map((selectedDate) => {
    //     dateArry.push(`${selectedDate.day} ${selectedDate.month.shortName} ${selectedDate.year}`)
    // });
    return dateArry.join(", ");
  };

  const renderRatingStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span key={i}>{ratingFillIcon({ width: 24, height: 23 })}</span>
      );
    }
    if (hasHalfStar) {
      stars.push(
        <span key="half">{ratinghalfIcon({ width: 24, height: 24 })}</span>
      );
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(
        <span key={`empty-${i}`}>{ratingIcon({ width: 24, height: 24 })}</span>
      );
    }

    return stars;
  };

  const handleSchedule = async () => {
    let inspectionschedule = {};
    let i = 1;

    machineDetail.dates.map((selectedDate) => {
      inspectionschedule[
        `date${i}`
      ] = `${selectedDate.year}-${selectedDate.month.number}-${selectedDate.day}`;
      inspectionschedule[`time${i}`] = machineDetail.times[i - 1];
      i++;
    });

    let contactdetails = {
      firstname: machineDetail.first_name,
      lastname: machineDetail.last_name,
      useremailid: machineDetail.email,
      phoneno: machineDetail.phone,
      alternateno: machineDetail.alter_phone,
    };

    let machinelocation = {
      address1: machineDetail.address,
      address2: machineDetail.address1,
      pincode: machineDetail.zipcode,
    };

    const draftData = {
      pdid: productDETAILS?.pdid,
      inspectionschedule: inspectionschedule,
      machinelocation: {
        contactdetails: contactdetails,
        machinelocation: machinelocation,
      },
    };

    console.log(draftData);
    const { data } = await clientMachine.mutate({
      mutation: UpdateProductDraftSchedule,
      variables: { draftData: draftData },
    });
    fetchData();
    // console.log(data);
    // console.log({form})
    // setShowSkipModel(true);
  };

  console.log(productDETAILS, "<<<-----productDETAILS");

  if (
    machineDetail.activeStep == "Enlisting" &&
    machineDetail.visiting_days.length &&
    machineDetail.visiting_hours.length
  ) {
    setTimeout(() => {
      handleCustomChange("activeStep", "payment");
      handleCustomChange("sale_date", "20th July 2023");
      handleCustomChange("payment_date", "22nd July 2023");
      setTabsClasses((prevState) => ({
        ...prevState,
        enlisting: "completed",
        sale: "completed",
        payment: "completed",
      }));
    }, 30000);
  }
  console.log(
    productDETAILS.process_details &&
      productDETAILS.process_details[0]?.task_name === "Registration"
      ? true
      : false,
    "productDETAILS Registration"
  );

  const modalAction = (status) => {
    setShowModel(status);
  };
  const token = localStorage.getItem("userToken");
  if (!token && !showModel) {
    setShowModel(true);
  }

  if(isLoading){
    return <Loader/>
  }
  return (
    <>
      {showModel ? (
        <LoginModal
          modalAction={modalAction}
          setShowUserModel={setShowUserModel}
          type="phone"
        />
      ) : null}
      {showRemoveRequestModal ? (
        <RemoveMachineModal
          id={productDETAILS.pdid}
          modalAction={handleRemoveRequestModal}
        />
      ) : null}
      {showScheduledModal ? (
        <ScheduleModal
          modalAction={handleScheduledModal}
          hasMultipleForm={hasMultipleForm}
          setShowUserModel={handleUserModal}
          machineDetail={machineDetail}
          handleCustomChange={handleCustomChange}
        />
      ) : null}
      {showUserModal ? (
        <UserModal
          modalAction={handleUserModal}
          hasMultipleForm={hasMultipleForm}
          setShowLocationModal={handleLocationModal}
          machineDetail={productDETAILS.inspection_details?.contact_info?.contactdetails}
          handleCustomChange={handleCustomChange}
        />
      ) : null}
      {showLocationModal ? (
        <MachineLocationModal
          modalAction={handleLocationModal}
          hasMultipleForm={hasMultipleForm}
          machineDetail={machineDetail}
          handleCustomChange={handleCustomChange}
          handleSchedule={handleSchedule}
        />
      ) : null}
      {showUploadDocumentModal ? (
        <UploadDocumentModal
          modalAction={handleUploadDocumentModal}
          machineDetail={machineDetail}
          handleCustomChange={handleCustomChange}
        />
      ) : null}
      {showVisitingDaysModal ? (
        <VisitingDaysModal
          modalAction={handleVisitingDaysModal}
          machineDetail={machineDetail}
          handleCustomChange={handleCustomChange}
        />
      ) : null}
      <div className="container-fluid col-cust">
        <div className="max-container my-5">
          <Breadcrumbs
            items={breadcrumbsItems}
            boldtitle={boldtitle}
            backnavi={() => navigate("/buy/my-machine")}
          />

          <div className="name-wrap sale-sold-top-section">
            <div className="heading-wrap">
              <div className="sale-sold-name-and-brand">
               <span className="heading-600-24-14">{productDETAILS.draft_machine_details?.product_name}</span> 
                <span className={`procesing-btn ${productDETAILS.product_status==="Cancel" ? "cna-btn":productDETAILS.product_status==="Listed" ? "owned":productDETAILS.product_status==="Sold" ? "gray-btn":"procesing-btn"} heading-500-12-10`}>
                  {productDETAILS.product_status}
                </span>
              </div>
              <div className="heading-500-16 light-txt">{productDETAILS?.machine_details?.data?.product?.attributes?.Brands}</div>
            </div>
            <button
              type="button"
              className="contact-btn heading-400-14-12"
              onClick={() => navigate("/contactus")}
            >
              Contact Origa
            </button>
          </div>
        </div>
      </div>
      <MachineSaleImage
        media={productDETAILS.machine_details?.data?.product?.media}
      />
      <div className="container-fluid col-cust">
        <div className="max-container my-4">
          <div className="machine-sell-progress">
            <div className="machine-content">
              <div
              
              className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[0]?.status
                    ? 
                    `${productDETAILS?.process_details[0]?.status.toLowerCase()}`: "pending"
                }`}
               
              >
                <span className="first">
                  {registrationIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c hide-576">Registration</div>
                <div className="t-a-c show-576">Regis-</div>
                <div className="t-a-c m-0 show-576">tration</div>
                {productDETAILS.process_details &&
                productDETAILS.process_details[0]?.task_date ? (
                  <div className="t-a-c light-txt">
                    {productDETAILS.process_details &&
                      productDETAILS.process_details[0]?.task_date}
                  </div>
                ) : null}
              </div>
              <div
                  
              className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[1]?.status
                    ? 
                    `${productDETAILS?.process_details[1]?.status.toLowerCase()}`: "pending"
                }`}
              >
                <span className="second svg-fill inspection-after">
                  {inspectionIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c hide-576">Inspection</div>
                <div className="t-a-c show-576">Inspe-</div>
                <div className="t-a-c show-576 m-0">ction</div>
                {productDETAILS.process_details &&
                productDETAILS.process_details[1]?.task_date ? (
                  <div className="t-a-c light-txt">
                    {productDETAILS.process_details &&
                      productDETAILS.process_details[1]?.task_date}
                  </div>
                ) : null}
              </div>
              <div
                  
              className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[2]?.status
                    ? 
                    `${productDETAILS?.process_details[2]?.status.toLowerCase()}`: "pending"
                }`}
              >
                <span className={`second svg-fill ${productDETAILS.process_details &&
                  productDETAILS.process_details[2]?.status==="Completed" ? "doc-after":"" } `}>
                  {documnetIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c hide-576">Documentation</div>
                <div className="t-a-c show-576">Docum-</div>
                <div className="t-a-c show-576 m-0">entation</div>
                {productDETAILS.process_details &&
                productDETAILS.process_details[2]?.task_date ? (
                  <div className="t-a-c light-txt">
                    {productDETAILS.process_details &&
                      productDETAILS.process_details[2]?.task_date}
                  </div>
                ) : null}
              </div>
              <div
                className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[3]?.status
                    ? 
                    `${productDETAILS?.process_details[3]?.status.toLowerCase()}`: "pending"
                }`}
              >
                <span className="second svg-fill">
                  {enListingIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c">Enlisting</div>

                {productDETAILS.process_details &&
                productDETAILS.process_details[3]?.task_date ? (
                  <div className="t-a-c light-txt pt-18-576">
                    {productDETAILS.process_details &&
                      productDETAILS.process_details[3]?.task_date}
                  </div>
                ) : null}
              </div>
              <div
                 className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[4]?.status
                    ? 
                    `${productDETAILS?.process_details[4]?.status.toLowerCase()}`: "pending"
                }`}
              >
                <span className="second svg-fill sale-after">
                  {inspectionIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c">Sale</div>
                {productDETAILS.process_details &&
                productDETAILS.process_details[4]?.task_date ? (
                  <div className="t-a-c light-txt pt-18-576">
                    {productDETAILS.process_details &&
                      productDETAILS.process_details[4]?.task_date}
                  </div>
                ) : null}
              </div>
              <div
                 className={`content-item ${
                  productDETAILS.process_details &&
                  productDETAILS.process_details[5]?.status
                    ? 
                    `${productDETAILS?.process_details[5]?.status.toLowerCase()}`: "pending"
                }`}
              >
                <span className="second after-last-child">
                  {paymentRecievedIcon({ width: 28, height: 28 })}
                </span>
                <div className="t-a-c ">Payment Received</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasScheduled() ? (
        <div className="container-fluid col-cust">
          <div className="max-container my-4">
         {productDETAILS?.process_details?.[1]["task_name"]==="Inspection" && <div className="bi-box-wrap">
          <div className="bi-box-wrap">
            <div className="bi-box-item">
              {productDETAILS?.process_details?.[1]["task_name"]==="Inspection" && (
                <>  <div className="inner">
                    <div className="inner-info">
                      <div className="inner-wrap-content">
                        <div className="inner-info-1">
                          <div className="heading heading-400-14-10 light-txt">
                            Additional Documents Needed
                          </div>
                          <div className="date-details heading-500-16-14 mt-2">
                            We require you to upload the documents for timely
                            processing of your Machine and listing it
                            successfully.
                          </div>
                          <div className="pdf-details heading-500-16-14 mt-3">
                            PNG, PDF, JPEG,JPG |Max Size 5 MB
                          </div>
                        </div>
                        <div className="edit-icon">
                          {editIcon({ width: 24, height: 24 })}
                        </div>
                      </div>
                      <div className="inner-info-1 pt-4">
                        <div className="inner-info-top">
                          <div className="heading heading-400-14">
                            Machine Ownership Document
                          </div>
                          <div className="date-details heading-400-14">
                            Ownweship.pdf {barIcon({ width: 3, height: 12 })}
                          </div>
                        </div>
                        <div className="inner-info-top">
                          <div className="agreement heading-400-14">
                            Machine Sale Agreement
                          </div>
                          <button type="button" className="upload-btn">
                            {" "}
                            <a
                              href={
                                productDETAILS.inspection_details
                                  ?.inspection_report?.[0]
                              }
                              download="your-file-name.pdf"
                              className="download-link"
                            >
                              Download Document
                            </a>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inner remove-req">
                <div className="heading-400-14-12 light-txt d-flex align-items-center">
                  Wish to remove your selling request from Origa?
                </div>
                <button
                  className="button heading-600-14-12"
                  onClick={() => handleRemoveRequestModal(true)}
                >
                  Remove Request
                </button>
              </div></>
                )}
              </div>
              <div className="bi-box-item ">
              <div className="inner">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt mb-2">
                Point of Contact Details
              </div>
              <div className="heading-500-16-14 name">
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.firstname} {productDETAILS.inspection_details?.contact_info?.contactdetails?.lastname}
            </div>
            <div className="email heading-500-16-14">
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.useremailid} &nbsp;&nbsp;{" "}
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.phoneno}
            </div>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                handleUserModal(true);
              }}
            >
              {editIcon({ width: 24, height: 24 })}
            </div>
          </div>
          <div className="inner">
          <div className="inner-info">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt">
                Preferred Inspection Date34
              </div>
              <div className="date-details heading-500-16-14">
                {
                  productDETAILS.inspection_details
                    ?.inspection_date_time?.date1
                }
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.date2 && (
                  <span>
                    {" "}
                    ,
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.date2
                    }
                  </span>
                )}{" "}
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.date3 && (
                  <span>
                    ,{" "}
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.date3
                    }
                  </span>
                )}
              </div>
            </div>
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt">
                Preferred Inspection Time
              </div>
              <div className="date-details heading-500-16-14">
                {
                  productDETAILS.inspection_details
                    ?.inspection_date_time?.time1
                }
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.time2 && (
                  <span>
                    {" "}
                    ,
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.time2
                    }
                  </span>
                )}{" "}
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.time3 && (
                  <span>
                    ,{" "}
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.time3
                    }
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className="edit-icon"
            onClick={() => handleScheduledModal(true)}
          >
            {editIcon({ width: 24, height: 24 })}
          </div>
        </div>
          <div className="inner">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt mb-2">
                Machine Location
              </div>
              <div className="addresss heading-500-16-14">
                {machineDetail.address}
                {machineDetail.address1
                  ? ` ${machineDetail.address1}`
                  : ""}{" "}
                {machineDetail.zipcode}
              </div>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                handleLocationModal(true);
              }}
            >
              {editIcon({ width: 24, height: 24 })}
            </div>
          </div>
          
              </div>
            </div>

           
            
            </div>}
            {/*
            <div className="bi-box-wrap bi-wrap">
              <div className="bi-box-item">
                <div className="inner remove-req">
                  <div className="heading-400-14-12 light-txt d-flex align-items-center">
                    Wish to remove your selling request from Origa?
                  </div>
                  <button
                    className="button-purple heading-600-14-12"
                    onClick={() => handleRemoveRequestModal(true)}
                  >
                    Remove Request
                  </button>
                </div>
              </div>
             
              <div className="bi-box-item">
                <div className="inner-1">
                  <div className="schedule-heading heading-400-14-12">
                    Schedule your free Inspection
                  </div>
                  <div className="text heading-500-16">
                    Our qualified team will come to inspect your machine and
                    help enlist your machine on Origa so you can find the best
                    deal for your machine
                  </div>
                  <button
                    type="button"
                    className="schdule-btn heading-400-16-12"
                    onClick={() => handleScheduledModal(true)}
                  >
                    Schedule now
                  </button>
                </div>
                            </div>
              
            </div>*/}

            
            
            
          </div>
        </div>
      ) : (
        <div className="container-fluid col-cust">
          <div className="max-container my-4">
         {productDETAILS?.process_details?.[1]["task_name"]==="Inspection" && <div className="bi-box-wrap">
          <div className="bi-box-wrap">
            <div className="bi-box-item">
              {productDETAILS?.process_details?.[1]["task_name"]==="Inspection" && (
                productDETAILS?.product_status!=="Sold" ? <>  
                <div className="inner">
                    <div className="inner-info">
                      <div className="inner-wrap-content">
                        <div className="inner-info-1">
                          <div className="heading heading-400-14-10 light-txt">
                            Additional Documents Needed
                          </div>
                          <div className="date-details heading-500-16-14 mt-2">
                            We require you to upload the documents for timely
                            processing of your Machine and listing it
                            successfully.
                          </div>
                          <div className="pdf-details heading-500-16-14 mt-3">
                            PNG, PDF, JPEG,JPG |Max Size 5 MB
                          </div>
                        </div>
                        <div className="edit-icon">
                          {editIcon({ width: 24, height: 24 })}
                        </div>
                      </div>
                      <div className="inner-info-1 pt-4">
                        <div className="inner-info-top">
                          <div className="heading heading-400-14">
                            Machine Ownership Document
                          </div>
                          <div className="date-details heading-400-14">
                            Ownweship.pdf {barIcon({ width: 3, height: 12 })}
                          </div>
                        </div>
                        <div className="inner-info-top">
                          <div className="agreement heading-400-14">
                            Machine Sale Agreement
                          </div>
                          <button type="button" className="upload-btn">
                            {" "}
                            <a
                              href={
                                productDETAILS.inspection_details
                                  ?.inspection_report?.[0]
                              }
                              download="your-file-name.pdf"
                              className="download-link"
                            >
                              Download Document
                            </a>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inner remove-req">
                <div className="heading-400-14-12 light-txt d-flex align-items-center">
                  Wish to remove your selling request from Origa?
                </div>
                <button
                  className="button heading-600-14-12"
                  onClick={() => handleRemoveRequestModal(true)}
                >
                  Remove Request
                </button>
              </div></>:<>
              <div className="inner">
                    <div className="inner-info w-100">
                      <div className={`inner-wrap-content w-100`} style={{borderBottom:isShowPriceBreackdoen ? "":"none"}}>
                        <div className="inner-info-1 w-100">
                          <div className="heading d-flex justify-content-between">
                          <span className="heading-400-14-10 light-txt">Buyer Name</span>
                         {!isShowPriceBreackdoen && <span className="curser-pointer" onClick={()=>setIsShowPriceBreackdoen(true)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M6 9L11.2929 14.6854C11.6834 15.1049 12.3166 15.1049 12.7071 14.6854L18 9" stroke="#211E24" stroke-width="2" stroke-linecap="round"/>
</svg>
                          </span>}
                          </div>
                          <div className="heading heading-500-16-14">Ramesh Patel</div>
                          <div className="sold-amount-details">
                           <div>
                           <div className="date-details heading-400-14-12">Amount Recieved</div>
                           <div className="date-details heading-500-16-14">₹ 4,00,000</div>
                           </div>
                           <div>
                           <div className="date-details heading-400-14-12">Transaction ID</div>
                           <div className="date-details heading-500-16-14">53574647</div>
                           </div>
                           <div>
                           <div className="date-details heading-400-14-12">Transaction Date</div>
                           <div className="date-details heading-500-16-14">22nd July 2023</div>
                           </div>
                          </div>
                          
                        </div>
                       
                      </div>
                      {isShowPriceBreackdoen&&<div className="inner-info-1 pt-4">
                        <div className="inner-info-top">
                          <div className="heading heading-400-14 curser-pointer" onClick={()=>setIsShowPriceBreackdoen(false)}>
                          View Price Breakdown&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18 15L12.7071 9.31461C12.3166 8.89513 11.6834 8.89513 11.2929 9.31461L6 15" stroke="#000" stroke-width="2" stroke-linecap="round"/>
</svg>
                          </div>
                         
                        </div>
                        <div className="inner-info-top" style={{display:"block"}}>
                          <div className="agreement d-flex" style={{gap:"10px"}}>
                          <span className=" heading-500-16-14">₹ 3,50,000</span>  <span>|</span><span className="heading-400-14-12 v-center">Payable to Seller</span>
                          </div>
                          <div className="agreement d-flex" style={{gap:"10px"}}>
                          <span className=" heading-500-16-14">₹ 3,50,000</span>  <span>|</span><span className="heading-400-14-12 v-center">Repairs before selling</span>
                          </div>
                          <div className="agreement d-flex" style={{gap:"10px"}}>
                          <span className=" heading-500-16-14">₹ 3,50,000</span>  <span>|</span><span className="heading-400-14-12 v-center">1 Year AMC</span>
                          </div>
                          <div className="agreement d-flex" style={{gap:"10px"}}>
                          <span className=" heading-500-16-14">₹ 3,50,000</span>  <span>|</span><span className="heading-400-14-12 v-center">Transportation</span>
                          </div>
                          <div className="agreement d-flex" style={{gap:"10px"}}>
                          <span className=" heading-500-16-14">₹ 3,50,000</span>  <span>|</span><span className="heading-400-14-12 v-center">Origa Profit</span>
                          </div>

                        </div>
                        
                      </div>}
                    </div>
                  </div>
              </>
                )}
              </div>
              {productDETAILS?.product_status!=="Sold" ?<div className="bi-box-item ">
              <div className="inner">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt mb-2">
                Point of Contact Details
              </div>
              <div className="heading-500-16-14 name">
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.firstname} {productDETAILS.inspection_details?.contact_info?.contactdetails?.lastname}
            </div>
            <div className="email heading-500-16-14">
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.useremailid} &nbsp;&nbsp;{" "}
              {productDETAILS.inspection_details?.contact_info?.contactdetails?.phoneno}
            </div>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                handleUserModal(true);
              }}
            >
              {editIcon({ width: 24, height: 24 })}
            </div>
          </div>
          <div className="inner">
          <div className="inner-info">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt">
                Preferred Inspection Date34
              </div>
              <div className="date-details heading-500-16-14">
                {
                  productDETAILS.inspection_details
                    ?.inspection_date_time?.date1
                }
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.date2 && (
                  <span>
                    {" "}
                    ,
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.date2
                    }
                  </span>
                )}{" "}
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.date3 && (
                  <span>
                    ,{" "}
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.date3
                    }
                  </span>
                )}
              </div>
            </div>
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt">
                Preferred Inspection Time
              </div>
              <div className="date-details heading-500-16-14">
                {
                  productDETAILS.inspection_details
                    ?.inspection_date_time?.time1
                }
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.time2 && (
                  <span>
                    {" "}
                    ,
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.time2
                    }
                  </span>
                )}{" "}
                {productDETAILS.inspection_details?.inspection_date_time
                  ?.time3 && (
                  <span>
                    ,{" "}
                    {
                      productDETAILS.inspection_details
                        ?.inspection_date_time?.time3
                    }
                  </span>
                )}
              </div>
            </div>
          </div>
         
        </div>
          <div className="inner">
            <div className="inner-info-1">
              <div className="heading heading-400-14-10 light-txt mb-2">
                Machine Location
              </div>
              <div className="addresss heading-500-16-14">
                {machineDetail.address}
                {machineDetail.address1
                  ? ` ${machineDetail.address1}`
                  : ""}{" "}
                {machineDetail.zipcode}
              </div>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                handleLocationModal(true);
              }}
            >
              {editIcon({ width: 24, height: 24 })}
            </div>
          </div>
          
              </div>:<div className="bi-box-item ">
              <div className="inner">
            <div className="inner-info-1">
              <div className="heading sold-rating-div">
              <div className="heading-400-14-10 light-txt mb-2"><div>Rate your Experience with Origa</div>
               <div className='d-flex pt-2' style={{gap:"8px"}}>{[0,1,2].map(()=><div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M11.9989 18.26L4.94594 22.208L6.52094 14.28L0.585938 8.792L8.61294 7.84L11.9989 0.5L15.3849 7.84L23.4119 8.792L17.4769 14.28L19.0519 22.208L11.9989 18.26Z" fill="#73509E"/>
</svg></div>)}  <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94594 22.208L11.9989 18.26L19.0519 22.208L17.4769 14.28L23.4119 8.792L15.3849 7.84L11.9989 0.5L8.61294 7.84L0.585938 8.792L6.52094 14.28L4.94594 22.208ZM16.2459 18.345L11.9989 15.968V5.275L14.0369 9.694L18.8699 10.267L15.2969 13.572L16.2459 18.345Z" fill="#73509E"/>
</svg>  </div>
<div>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94594 22.208L11.9989 18.26L19.0519 22.208L17.4769 14.28L23.4119 8.792L15.3849 7.84L11.9989 0.5L8.61294 7.84L0.585938 8.792L6.52094 14.28L4.94594 22.208ZM16.2459 18.345L11.9989 15.968L7.75194 18.345L8.70094 13.572L5.12794 10.267L9.96094 9.695L11.9989 5.275L14.0369 9.694L18.8699 10.267L15.2969 13.572L16.2459 18.345Z" fill="#73509E"/>
</svg>
</div>
</div>
              </div>
              <div className="edit-icon heading-600-14-12 text-end"> Write a reivew &nbsp;{editIcon({ width: 24, height: 24 })}</div>
              </div>
              <div className="addresss heading-500-16-14">
              Selling at Origa has been a great experience, once I filled out the form they really took care of everything else. I got a much better deal then what I was being offered earlier.
              </div>
            </div>
            
          </div>
              </div>}
            </div>

           
            
            </div>}
            {/*
            <div className="bi-box-wrap bi-wrap">
              <div className="bi-box-item">
                <div className="inner remove-req">
                  <div className="heading-400-14-12 light-txt d-flex align-items-center">
                    Wish to remove your selling request from Origa?
                  </div>
                  <button
                    className="button-purple heading-600-14-12"
                    onClick={() => handleRemoveRequestModal(true)}
                  >
                    Remove Request
                  </button>
                </div>
              </div>
             
              <div className="bi-box-item">
                <div className="inner-1">
                  <div className="schedule-heading heading-400-14-12">
                    Schedule your free Inspection
                  </div>
                  <div className="text heading-500-16">
                    Our qualified team will come to inspect your machine and
                    help enlist your machine on Origa so you can find the best
                    deal for your machine
                  </div>
                  <button
                    type="button"
                    className="schdule-btn heading-400-16-12"
                    onClick={() => handleScheduledModal(true)}
                  >
                    Schedule now
                  </button>
                </div>
                            </div>
              
            </div>*/}

            
            
            
          </div>
        </div>
      )}
      <div className="max-container view-machine-bot">
        <div className="bot-icon-wrap">
          <div className="bot-icon">{botIcon({ width: 37, height: 37 })}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ViewMachineSale;
