import React, {useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {leftArrowIcon,botIcon} from "../../../helpers/Icons";
import FooterBottom from "../../Footer/FooterBottom";
import "./ApplyLoan.css";
import ApplyLoanModal from "../Modals/ApplyLoanModal";

const ApplyLoan = () => {
    const [showModal, setShowModal] = useState(false);
    const location=useLocation();
    const navi=location?.state?.navi

    console.log('ApplyLoanModal---->>>',navi);
    const navigate = useNavigate();
    const handleModal = (status) => {
        if(status){
            setShowModal(status);
            document.body.classList.add('no-overflow');
        }else{
            setShowModal(false);
            document.body.classList.remove('no-overflow');
        }
    }
    return (
        <>
            {showModal && (
                <ApplyLoanModal modalAction={handleModal} onNavi={navi}/>
            )}
            <div className="container-fluid">
                <div className="max-container my-5">
                    <div className="apply-loan">
                        <div className="btn-wrap">
                            <button onClick={() => navigate(-1)} className="back-btn">{leftArrowIcon({width:24,height:24})}</button>
                        </div>
                        <div className="content-wrap">
                            <div className="body-wrap">
                                <div className="heading-600-24 heading-600-24-20 heading-600-24-16 heading-600-24-14 heading">Apply for lease or Loan</div>
                                <p className="heading-para heading-400-14-12 light-txt">Grow your Business by giving it the right fuel it need . Dont worry about financing, Team Origa has you covered for that.</p>
                                <div className="product-wrap">
                                    <div className="left-wrap">
                                        <div className="heading-600-16 title">Tell us your Contact Details</div>
                                        <p className="tille-para heading-400-14-12 light-txt">Set your requirements for this project, the estimated price will be based on the project requirements</p>
                                    </div>
                                    <div className="right-wrap">
                                        <form>
                                            <div className="bi-form-group">
                                                <input type="text" name="name" id="name" className="bi-form-field bg-white" placeholder="Name"/>
                                                <label htmlFor="name" className="heading-400-14-12 bi-form-label">Name</label>
                                            </div>
                                            <div className="bi-form-group">
                                                <input type="email" name="email" id="email" className="bi-form-field bg-white" placeholder="Email"/>
                                                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Email Id</label>
                                            </div>
                                            <div className="bi-form-group">
                                                <input type="text" name="phone" id="phone" className="bi-form-field bg-white" placeholder="Number"/>
                                                <label htmlFor="phone" className="heading-400-14-12 bi-form-label">Contact No.</label>
                                            </div>
                                            <button type="button" className="submit-btns" onClick={() => handleModal(true)}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="bot-icon-wrap mt-5">
                        <div className="bot-icon">{botIcon({width:37,height:37})}</div>
                    </div>
                </div>
            </div>
            <FooterBottom/>
        </>
    );
}
export default ApplyLoan;