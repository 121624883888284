import React, { useState } from 'react'
import { Container, Accordion } from 'react-bootstrap'
const TermsConditions = () => {
  
  const [activeAccordion, setActiveAccordion] = useState(0);

  const onAccordionClick = (index) => {
    if (index === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };
  return (
    <div className='max-container privacypolicy-maindiv mb-5'>
      
    <Accordion activeKey={activeAccordion}  flush className='accordionborder' key={0}>
      <Accordion.Item eventKey={0} >
        <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(0)}>Terms of Use</Accordion.Header>
        <Accordion.Body>

          <p className='legal-para op-60'>
          This document is an electronic record under Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws. This electronic record is generated by a computer system and does not require any physical or digital signatures.
          </p>
          <p className='legal-para op-60'>This document is published and shall be construed in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of www.origa.market (“website”).</p>
          <h1 className='privacypolicy-heading'>User Agreement</h1>
          <Container style={{paddingLeft:"40px"}}>
            <p className='legal-para op-60'>Your use of www.origa.market and all the web-pages, hyper-links, tools and services provided thereunder (hereinafter referred to as "the Website" which expression shall include any successor or replacement website(s)) is governed by the following terms and conditions as applicable to the Website ("User Agreement"). This User Agreement shall come into effect upon each visit or usage of the Website, or upon your registration, or upon you providing any information on the Website. In this User Agreement, you are contracting with Origa Lease Finance Private Limited, a company incorporated under Companies Act, 2013 with its registered office at Office No.212, 1st Floor, Building No. 2, Solitaire Corporate Park, Andheri-Kurla Road, Chakala, Andheri (E), Mumbai – 400093, Maharashtra, India, hereinafter referred to as “Origa” which expression unless the context otherwise require shall mean and include its successors, liquidators and assigns.
            </p>
           <p className='legal-para op-60 pt-2'>
           For the purpose of the User Agreement, "Registered User", "Visitor", and wherever the context may so require, "You" shall mean any natural or legal person who has agreed to become a member of the Website by providing Registration Data (as defined hereinafter) while registering on the Website as a Registered User using the computer systems of the Website, or who has used the Website without becoming a Registered User, and, in both the cases, accepted this electronic version / electronic record of the User Agreement. A Registered User is one who has allocated himself a unique identification user name ("User ID" and "Password") to become Registered User.
The term and expression "You" shall, unless the context otherwise requires, include your legal
heir, receivers, successors, liquidators and permitted assigns.
           </p>
           <p className='legal-para op-60 pt-2'>
           As a Registered User, this User Agreement shall be effective and binding upon your 'acceptance'. "Acceptance" shall mean your affirmative action in clicking on the 'check box' and/or on the ‘continue’ button and/or or any affirmative action as provided on the registration page or using or accessing the Website through logging in by Your third party website user ID and password, including that of www.facebook.com, websites owned by Yahoo Inc. or its subsidiaries, Google Inc. or its subsidiaries, Twitter or any other social media website as permitted on the Website or generally using the Website in any manner, with or without using Your User ID and Password. If you do not agree or are not willing to be bound by the terms and conditions of this User
           Agreement, please do not click on the ‘check box’ or on the ‘continue’ button or any other button
and do not seek to obtain access to or otherwise use the Website.

           </p>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <Accordion activeKey={activeAccordion}  flush className='accordionborder' key={1}>
    <Accordion.Item eventKey={1} >
      <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(1)}>Amendments to the User Agreement</Accordion.Header>
      <Accordion.Body>

        <p className='legal-para op-60'>The Origa may amend this User Agreement at any time by posting a revised version on the Website. The revised version will be effective at the time Origa posts it on the Website. You are advised to regularly check for any amendments or updates to the terms and conditions contained in this User Agreement.</p>
       
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>

  <Accordion activeKey={activeAccordion}  flush className='accordionborder' key={2}>
  <Accordion.Item eventKey={2} >
    <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(2)}>Important Disclaimers:</Accordion.Header>
    <Accordion.Body>
      <p className='legal-para op-60'>The following disclaimers are the key to determining any relationship between You and the Origa for Your use of the Website and for Origa providing any services on the Website:</p>
    <ul>
    <li className='legal-para op-60'> Origa and its suppliers, affiliates and service providers provide the Website and services on "as is" basis and without any warranty or condition, express, implied or statutory and specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement. You expressly agree that your use of the Website is at your risk.</li>
    <li className='legal-para op-60 pt-1'>The Origa, its associates, affiliates and service providers and technology partners make no representations or Warranties about the information, software, text, graphics, links or communications provided on or through the use of the Website or that the operation of the Website will be error free and/or uninterrupted.</li>
  
    <li className='legal-para op-60 pt-1'>Origa neither recommends You to buy any goods or services on the Website nor endorses any such goods or services nor provides any guarantee, warranty or assurance with respect to any goods or services made available on the Website.</li>
    <li className='legal-para op-60 pt-1'>All guarantee and/or warranty and/or assurance with respect to any goods or services made available on the Website, are provided by the Brand/manufacturer/dealer, as the case maybe.</li>
    <li className='legal-para op-60 pt-1'>Your sole and exclusive remedy and Origa's sole and exclusive responsibility and liability is to provide You necessary information (subject to the Privacy Policy and applicable laws) for You to deal with Brand/manufacturer/dealer with respect to any dispute.</li>
    <li className='legal-para op-60 pt-1'>Pursuant to the provisions of the Foreign Exchange Management Act, 1999, and applicable laws, the Origa may provide certain support services to facilitate the transactions on the Websites and such independent services may include payment facilitation through payment gateways under applicable laws. You understand, agree and acknowledge that the Origa will engage independent third party service providers to perform these services. The Origa may on reasonable efforts basis procure ordinary industry standard warranties from these third parties. You further understand, agree and acknowledge that the Origa will not be held liable or responsible towards You or towards any person claiming under or through You for any actions, commission, omission, misconduct, fraud or negligence (whether in contract or tort or under public policy) of these third parties unless such actions, commission, omission or negligence of the third
    party is attributed to a direct cause of misconduct and gross negligence of the Origa and in such an event the total aggregate liability of the Origa shall be limited to the extent provided in the clause titled "Limitation of Liability".
    </li>
    <li className='legal-para op-60 pt-1'>You understand, agree and acknowledge that Origa uses third party service providers to store and process Your personal information and other information which You provide to the Origa (as more specifically identified in the Privacy Policy) and such third parties may store and process your personal information in a country or state which may not have jurisdiction over You and / or such country or state may not have any data protection or data privacy laws or such country's or state's data protection or data privacy laws may not be as strong as the country or state having jurisdiction over You or over the Origa. You agree and understand that Origa provides no guarantee, warranty or assurance that such third-party service providers will protect Your personal information and Origa shall not be liable for any actions, commission, omission, misconduct, fraud or negligence (whether in contract or tort or under public policy) of these third parties unless such actions, commission, omission or negligence of the third party is attributed to a direct cause of misconduct and gross negligence of Origa.</li>
    <li className='legal-para op-60 pt-1'>You understand, agree and acknowledge that the Brand/Manufacturer/Dealer is solely responsible for the logistics, delivery and installation, if required, of the good purchased from the website. The estimated delivery timelines as maybe displayed on the Website are provided based on the delivery timelines provided by the relevant Brand/Manufacturer/Dealer. Any delay in delivery shall be the sole liability and responsibility of the Brand/Manufacturer/Dealer. In the event of delayed delivery Your sole and exclusive remedy and Origa's sole and exclusive responsibility and liability is to assist you to connect with the relevant Brand/Manufacturer/Dealer and expedite the delivery.</li>
    <li className='legal-para op-60 pt-1'>You understand, agree and acknowledge that provision of a GST number is a mandatory to purchase goods and/or service on the Website. In the event of provision of incorrect GST number, forged or incorrect GST certificate, then We shall be entitled to cancel the order without being bound to provide refund to you.</li>
    </ul>
    <p  className='legal-para op-60'>You agree that the aforesaid disclaimers shall be in addition to any other disclaimers given in this User Agreement</p>
      </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={3}>
<Accordion.Item eventKey={3} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(3)}>
  Electronic Communications
  </Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>When You use the Website or send emails or other data, information or communication to the Origa, You agree and understand that You are communicating with the Website and Origa and all other Registered Users and Visitors through electronic records which are legally identifiable and enforceable, and You consent to receive communications via electronic records from the Website, Origa and all other Registered Users and Visitors and as and when posted, communicated or required.</p>
  <p className='legal-para op-60'>Service of Notice: The Registration Data and email ID or Your account details of the third-party website through which You register with the Website will be construed as Your 'designated electronic address' and the Origa, Website, other Registered Users, Visitors, third parties and law enforcement agencies will communicate with You on your designated electronic address which will be deemed adequate service of notice / electronic record.
 <p className='legal-para op-60'>By impliedly or expressly accepting this User Agreement, You also accept and agree to be bound by various policies of the Origa as provided from time to time in various hyperlinks on the Website.</p>
  </p> 
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={4}>
<Accordion.Item eventKey={4} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(4)}>Membership Eligibility</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872 and any other applicable laws for this purpose. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 and any other applicable laws for this purpose including minors, un-discharged insolvents etc. are not eligible to use the Website. If you are a minor i.e. under the age of 18 years, you shall not register as a member of the Website and shall not purchase or bid for any items on the Website. As a minor if you wish to purchase an item on the Website such purchase may be made by your legal guardian or parents who have registered as users of the Website. Origa reserves the right to terminate your membership and refuse to provide you with access to the Website if it is brought to Origa's notice or if it is discovered that you are under the age of 18 years or are otherwise "incompetent to contract". If you are registering as or on behalf of a business entity, you represent that you are duly authorized by the business entity to accept this User Agreement and you have the authority to bind that business entity to this User Agreement.</p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={5}>
<Accordion.Item eventKey={5} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(5)}>Membership Benefits</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>As a registered member of Origa, your address, email-id, and other delivery details become automatically saved in the "My Account" section of the Website, accessible only to you. You can track all your orders by logging in to the "My Account" section with the User ID and Password set by you. You can save items you are interested in, using the "My Wishlist" feature, for easy purchases. Further, if you choose to opt for our e-mail updates, all information regarding new additions to the Website by various sellers, as well as promotional schemes, will be e-mailed to you at regular intervals.</p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={6}>
<Accordion.Item eventKey={6} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(6)}>Your Account and Registration Obligations</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>If you use the Website as a Registered User, you are responsible for maintaining the confidentiality of your User ID and Password. You are responsible for all activities that occur under your User ID and Password. You agree, inter alia, to provide true, accurate, current and complete information about yourself as prompted by Website registration form or provided by You as a Visitor or user of a third party site through which You access the Website. If you provide any information that is untrue, inaccurate, not current or incomplete or Origa has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the User Agreement, Origa has the right to indefinitely suspend or terminate or block access of your membership with the Website and refuse to provide you with access to the Website.
<br/>
You hereby grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub- licensable (through multiple tiers) right to exercise the copyright, publicity, and database rights you have in Your information, in any media or medium now known or developed, produced, invented or used in future, with respect to Your information.


    </p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={7}>
<Accordion.Item eventKey={7} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(7)}>Fees and Services</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>Membership and browsing on the Website is free. All additional fees that may be charged shall be visible for confirmation on the order checkout page.</p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={8}>
<Accordion.Item eventKey={8} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(8)}>Taxes</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>You are responsible for paying all fees (if any) associated with the use of the Website and you agree to bear any and all applicable taxes, charges, cesses etc. levied thereon</p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={9}>
<Accordion.Item eventKey={9} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(9)}>Privacy</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>The Origa collects, stores, processes and use Your information in accordance with Origa Privacy Policy. By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website by Origa in accordance with the Privacy Policy.</p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={10}>
<Accordion.Item eventKey={10} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(10)}>Payment Facility</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>
    Origa may from time to time contract with third party payment service providers including banks to open nodal bank accounts under applicable Indian laws, to facilitate the payment between users and Origa. These third party payment service providers may include third party banking or credit card payment gateways, payment aggregators, cash on delivery or demand draft / pay order on delivery service providers, mobile payment service providers or through any facility as may be authorized by the Reserve Bank of India for collection, refund and remittance, as the case may be of payment or supporting the same in any manner.
    </p>
   
  </Accordion.Body>
</Accordion.Item>
</Accordion>


<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={11}>
<Accordion.Item eventKey={11} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(11)}>Cancellation</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>
    You expressly agree, confirm and accept that once the purchase order placed by you is confirmed by us, you may cancel the order on the terms as mentioned below:
    </p>
    <p className='legal-para op-60'>
    If order cancelled, for any reason whatsoever, within 3 days from the date of confirmation of Order, then 15% of the amount paid while placing the order shall be levied as the cancelation fees and the balance shall be refunded to you.</p>
  <p className='legal-para op-60'>If order cancelled, for any reason whatsoever, anytime after 3 days from the date of confirmation of Order, then 100% of amount paid while placing the order shall be levied as the cancelation fees and you shall not be entitled for any refund.</p>
  <p className='legal-para op-60'>In case of order is placed by paying 10% as advance, balance payment needs to be remitted within 48 hours of order placement. If balance payment is not remitted within 48 hours then advance amount i.e. 10% will be forfeited and the order will be cancelled. In the event the customer wishes to cancel the order, within 48 hours of order placement, even then the advance amount i.e. 10% will be forfeited and the order will be cancelled. Cancellation is not permitted after 48 hours of order placement under advance payment option.</p>
  <p className='legal-para op-60'>You agree that Origa has the right to amend the cancellation terms and conditions, at its sole discretion.</p>  
  </Accordion.Body>
</Accordion.Item>
</Accordion>


<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={12}>
<Accordion.Item eventKey={12} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(12)}>Breach</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>Without limiting other rights and remedies, Origa may limit your activity, immediately remove your information or buys, immediately temporarily/indefinitely suspend or terminate or block your membership, and/or refuse to provide you with access to the Website in the event, but not limited to:</p>
   <ul>
   <li className='legal-para op-60'>If you breach the User Agreement or any terms or policies of the Website.</li>
   <li className='legal-para op-60'>If your actions may cause legal liability for you, other users or Origa.</li>
   </ul>
   <p className='legal-para op-60'>Origa may at any time at its sole discretion reinstate suspended users. Origa reserves the right to recover any amounts due and owing by you to Origa and to take strict legal action including but not limited to a referral to the appropriate police or other authorities for initiating criminal or other proceedings against you.</p>
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={13}>
<Accordion.Item eventKey={13} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(13)}>Limitation of Liability</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>In no event shall Origa or its affiliates and service providers be liable for any indirect, incidental, special, incidental, consequential, punitive or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website, its services or this agreement (however arising, including negligence or under torts or under public policy).</p>
    <p className='legal-para op-60'>
    In addition to any limitation of liability and various disclaimers provided elsewhere in this User Agreement, Origa's entire liability and Your sole and exclusive remedy in any circumstance is limited to the purchase price paid by you.
    </p> 
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={14}>
<Accordion.Item eventKey={14} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(14)}>Indemnity</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>You shall fully indemnify, keep indemnified, defend and hold harmless Origa and Origa's shareholders, subsidiaries, affiliates, third-parties and their respective shareholders, officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of your breach of the User Agreement or any rules and policies contained herein, or your violation of any law, rules or regulations or the rights of a third party.</p>
     
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={15}>
<Accordion.Item eventKey={15} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(15)}>General</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>None of the provisions of the User Agreement shall be deemed to constitute a partnership or agency between You and Origa and You shall have no authority to bind Origa in any manner whatsoever nor shall Origa have any authority to bind you in any manner whatsoever. All the services provided by the Origa shall be as independent contractor and on principal to principal basis.</p>
    <p className='legal-para op-60'>
    If any clause of the User Agreement shall be deemed invalid, void or for any reason unenforceable, such clause shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the User Agreement.
    </p> 
    <p className='legal-para op-60'>This Agreement sets forth the entire understanding and agreement between You and Origa with respect to the subject matter hereof.</p>
    <p className='legal-para op-60'>In Origa's sole discretion, Origa may transfer its rights and obligations under this Agreement, assign this Agreement or novate this Agreement without your prior express consent. However, Origa on reasonable efforts basis and if practicable may provide You with an advance intimation of any such acts.</p>
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={16}>
<Accordion.Item eventKey={16} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(16)}>Grievance Officer</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>In accordance with Information Technology Act, 2000 and rules made there under and the Consumer Protection Act,2019 and the Consumer Protection (E-Commerce) Rules, 2020 the name and the contact details of the Grievance Officer are provided below:</p>
    <p className='legal-para op-60'>
    Mr. Ram Subramanian<br/>
    Office no. 212, Building no. 2, Solitaire Corporate Park, Andheri Ghatkopar Link Road, Chakala, Andheri (East), Mumbai – 400 093
<br/>
Phone Number –<a href="tel:02248948989" className='curser-pointer no-underline'>02248948989</a> 
<br/>
Email id – <a href="mailto:ram.subramanian@origaleasing.com" className='curser-pointer no-underline' >ram.subramanian@origaleasing.com</a>
    </p> 
    <p className='legal-para op-60'>Further for any product or services related complaint or query, please contact +918828099099 (9.30 am to 5.30 pm), (Customer Care). To register your complaint or query, you may send an email at info@origaleasing.com</p>
    
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={17}>
<Accordion.Item eventKey={17} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(17)}>Other Complaints</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>In the event you have any complaints or concerns with respect to the website or our services, please contact our Customer Support at +918828099099 (9.30 am to 5.30 pm) or you may send an email  at info@origaleasing.com.</p>
     
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={18}>
<Accordion.Item eventKey={18} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(18)}>Governing Law</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>This User Agreement and all the rules and policies contained herein and any of Your usage of Website and Your dealings with Origa shall be governed and construed in accordance with the laws of India.</p>
     
    </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={19}>
<Accordion.Item eventKey={19} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(19)}>Arbitration</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>If any dispute arises between You and Origa during Your use of the Website or Your dealing with the Origa in relation to any activity on the Website, in connection with the validity, interpretation, implementation or alleged breach of any provision of the User Agreement and all the rules and policies contained herein, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by Origa. The Arbitration & Conciliation Act, 1996, shall govern the arbitration proceedings. The arbitration proceedings shall be in the English language. The place of arbitration shall be Mumbai.</p>
     
    </Accordion.Body>
</Accordion.Item>
</Accordion>


<Accordion activeKey={activeAccordion}  flush className='accordionborder' key={20}>
<Accordion.Item eventKey={20} >
  <Accordion.Header className="accordion-header" onClick={() => onAccordionClick(20)}>MAINTENACE SERVICE</Accordion.Header>
  <Accordion.Body>

    <p className='legal-para op-60'>The Terms and Conditions laid down here shall be exclusively applicable for Maintenance Service availed by End Customer and shall be read together with the existing Terms of Use and other policies as stated on our website www.origa.market("Website") and as applicable from time to time.</p>
     <p className='legal-para op-60'>Origa, after thorough background and quality check has empaneled through it’s Franchisees highly skilled and expert Service Engineers for provision of Maintenance Service to the End Customers, on behalf of Origa.</p>
     <h1 className='privacypolicy-heading'>General Terms and Conditions:</h1>
     <Container className='pl-2'>
     
       <div className='d-flex legal-para op-60'>1)&nbsp;&nbsp;<p className='listp'>The end customer shall be responsible for providing true and correct information in relation to the machine to Origa and the Service Engineers. Neither Origa nor the Service Engineers shall be responsible for any inadequate or incorrect deliverables due to misleading or inaccurate information provided by the end customer.</p></div>
       <div className='d-flex legal-para op-60'>2)&nbsp;&nbsp;<p className='listp'>The Service Engineers shall not be providing any services directly to the customer without Origa’s knowledge during the term of the Service Agreement. The customer shall not approach the Service Engineers without the knowledge of Origa.</p></div>
       <div className='d-flex legal-para op-60'>3)&nbsp;&nbsp;<p className='listp'>The end customer and the Service Engineers shall be responsible for adhering to all the terms and conditions as stated in the Service Agreement along with the SOPs, guidelines and other policies of Origa.</p></div>
       <div className='d-flex legal-para op-60'>4)&nbsp;&nbsp;<p className='listp'>Origa reserves the right to extend or restrict the scope of this initiative to various locations in India.</p></div>
      
       <div className='d-flex legal-para op-60'>5)&nbsp;&nbsp;<p className='listp'>Origa shall not be liable for any kind of direct or indirect as well as financial or non- financial liabilities which may arise in the future due to, including but not limited to, customer complaints, deficiency in services, misleading information etc. under the Maintenance Service and/or Service Agreement.</p></div>
       <div className='d-flex legal-para op-60'>6)&nbsp;&nbsp;<p className='listp'>The end customer shall be responsible to make timely remittance. Delay in remittance by the customer will entitle the Service Engineers to stop the provision of service. Any costs incurred due to such delayed remittance by the customer shall solely be the responsibility of the customer. Neither Origa nor the Service Engineers shall be held accountable by the customer for such delays or increased costs.</p></div>
       <div className='d-flex legal-para op-60'>7)&nbsp;&nbsp;<p className='listp'>The customer shall not be entitled to cancel the Order once the Service Agreement is executed. The customer shall not be entitled to any refund once the customer remits the fees.</p></div>
       <div className='d-flex legal-para op-60'>8)&nbsp;&nbsp;<p className='listp'>Origa neither warrants the services provided by the Service Engineers nor extends any kind of implied or explicit guarantees/warranties in relation to the services/ products rendered under the Maintenance Service</p></div>
       
       <div className='d-flex legal-para op-60'>9)&nbsp;&nbsp;<p className='listp'>Any additions or changes in the scope will be treated as additional work and extra fees shall be payable.</p></div>
       <div className='d-flex legal-para op-60'>10)&nbsp;&nbsp;<p className='listp'>Origa is not responsible for the delays in delivery, quality of materials, deficiency in service and omissions by the Service Engineers.</p></div>
       <div className='d-flex legal-para op-60'>11)&nbsp;&nbsp;<p className='listp'>Every service and product provided herein shall be covered by the brand warranties as provided by the Service Engineers.</p></div>
       <div className='d-flex legal-para op-60'>12)&nbsp;&nbsp;<p className='listp'>The customer expressly consents to receive all communications from Origa/Service Engineers through email and SMS and agree to give timely approvals for the same.</p></div>
      
       <div className='d-flex legal-para op-60'>13)&nbsp;&nbsp;<p className='listp'>Before confirming of the Order, the customer will be responsible to check the scope, description and costing before signing off the same as an act of acceptance.</p></div>
       <div className='d-flex legal-para op-60'>14)&nbsp;&nbsp;<p className='listp'>The procurement of permissions and/or statutory approvals if any, will be the responsibility of the customer.</p></div>
       <div className='d-flex legal-para op-60'>15)&nbsp;&nbsp;<p className='listp'>Origa/Service Engineers shall not be responsible for co-ordinating with 3rd party vendors appointed by the customer.</p></div>
      
     </Container>
   
     </Accordion.Body>
</Accordion.Item>
</Accordion>

</div>
  )
}

export default TermsConditions