// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fourthImg {
    /* width: 584px; */
    height: 193px;
}

.fouthContainer {
    overflow: hidden;
    border-radius: 12px;
    background: #fff;
}

.headingpara {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

.fourthHoverEffect{
    background-color: #9B9E51;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: none;
    transition: all 0.1s; */

}

.bar2Fourth::after {
    content: "";
    background-color: white;
    display: block;
    width: 83px;
    height: 2px;
    margin: 5px 0;
  }
.hoverEffectImgdiv{
    height: 100%;
    width:100%;
}
.fouthBar::after{
    background-color: #fff !important;
}

@media screen and (min-width:320px) and (max-width:480px) {
    .svgsection svg {
        width: 35px !important;
    }
    .hoverEffectImgdiv{
        height: 197.601px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage/FourthPage/fourthpagecss.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;EACjB;;AAEF;IACI,yBAAyB;IACzB;;;;;2BAKuB;;AAE3B;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,cAAc;IACd,WAAW;IACX,WAAW;IACX,aAAa;EACf;AACF;IACI,YAAY;IACZ,UAAU;AACd;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".fourthImg {\r\n    /* width: 584px; */\r\n    height: 193px;\r\n}\r\n\r\n.fouthContainer {\r\n    overflow: hidden;\r\n    border-radius: 12px;\r\n    background: #fff;\r\n}\r\n\r\n.headingpara {\r\n    margin-top: 5rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n.fourthHoverEffect{\r\n    background-color: #9B9E51;\r\n    /* position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%,-50%);\r\n    display: none;\r\n    transition: all 0.1s; */\r\n\r\n}\r\n\r\n.bar2Fourth::after {\r\n    content: \"\";\r\n    background-color: white;\r\n    display: block;\r\n    width: 83px;\r\n    height: 2px;\r\n    margin: 5px 0;\r\n  }\r\n.hoverEffectImgdiv{\r\n    height: 100%;\r\n    width:100%;\r\n}\r\n.fouthBar::after{\r\n    background-color: #fff !important;\r\n}\r\n\r\n@media screen and (min-width:320px) and (max-width:480px) {\r\n    .svgsection svg {\r\n        width: 35px !important;\r\n    }\r\n    .hoverEffectImgdiv{\r\n        height: 197.601px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
