import React,{useState} from 'react'

import { uploadIcon,fileIcon } from '../../../helpers/Icons';
const hidden={ overflowX: "clip"}
const FinanceDocumentUploder = ({id,title}) => {

    const [uploadFiles, setUploadFiles] = useState([]);
    const handleBrowse = () => {
      document.querySelector(`.bi-upload-file-${id}`).value = '';
      document.querySelector(`.bi-upload-file-${id}`).click();
      };
      const handleFileChange = async (files) => {
        if (!files.length) {
          return;
        }
        setUploadFiles((prevFiles) => [...prevFiles, ...files]);

      };
    
      console.log(uploadFiles)
  
    
      const onDropHandler=(e)=>{
        e.preventDefault()
        console.log("iam drop",e.dataTransfer.files);
        handleFileChange(e.dataTransfer.files)
      }
      const onDragOverHandler=(e)=>{
        e.preventDefault()
        // console.log("iam drag",e);
      }    
  return (<>
    <p className='heading-600-12 m-0' >{title}</p>
    <div className="upload-wrap" key={id} style={{marginTop:"8px",marginBottom:"24px" ,gap:"8px"}}  onClick={handleBrowse} onDragOver={onDragOverHandler} onDrop={onDropHandler}>
    {uploadIcon({width:40,height:40})}
    <div className="desc heading-600-12">Upload the filled in template here</div>
    <div className='heading-400-12-10'>Drag and drop the file or click to select</div>
    {uploadFiles.length > 0 && (
                    <div className="uploading-wrap">
                        {uploadFiles.map((uploadFile,index) => (
                            <div className="uploading-item" key={index}>
                                <div className="file-icon">{fileIcon({width:24,height:30})}</div>
                                <div className="file-data" style={hidden}>
                                    <div className="name heading-600-14">{uploadFile.name}</div>
                                    <div className="process">
                                        <div className="process-inner"></div>
                                    </div>
                                    <div className="size-status-wrap">
                                       <div className="status heading-400-14">uploaded...100%</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
    </div>
    <input type="file" name="file" key={id}   className={`bi-upload-file bi-upload-file-${id}`} onChange={(e) => handleFileChange(e.target.files)} multiple/>
  
         </>     
  )
}

export default FinanceDocumentUploder