import React from 'react'

const BlogSecond = () => {
    const title="How is Leasing changing Healthcare:"
    const para="In India, one of the industries that is witnessing massive growth in entrepreneurship is the healthcare industry. It is expected to become a USD 280 billion juggernaut by 2020 from the current size of USD 100 billion. Imagine the number of jobs it will create! Imagine its addition to the overall GDP of India! Government can even fund significant part of its fiscal deficit if it decides to bring a small part of the industry under the current GST regime!"
  const blogQA=[
    {
    qus:"Mantra 1 : keep it Simple",
    ans:"Do not create a cobweb of companies to deliver healthcare solution. Keep your corporate structure lean, simple and easy to administer. Doctors and medical practitioners should spend more time with their patients rather than their chartered accountants!"
  }, {
    qus:"Mantra 2: Budget for working capital requirement",
    ans:"In most of the greenfield and expansion projects, all the capital expenditure costs are accurately considered. What is missed out is the requirement of working capital to run the new outfit. Budgeting for working capital will not lead to delays in starting the operations. When you are thinking of investment, don’t think only of land, building and equipment; think also of working capital."
  }, {
    qus:"Mantra 3: Dream big but gradually",
    ans:"Financial management is best achieved when growth rate is calibrated. Aggressive expansion can bring significant amount of stress to the financials of the existing establishment. It also forces individual doctors to pile up unsecured loans which can impact their CIBIL score."
  }, {
    qus:"Mantra 4: Analyse financial viability while taking loans",
    ans:"Today there is a tendency of medical professionals, hospitals etc. to add more and more loans since most of the times, financial institutions do not ask for a collateral from medical fraternity. It is important that a detailed analysis of the future cashflows is done to ensure that the repayment is not affected. Taking a loan to pay EMIs of the earlier loan can have serious consequences."
  }, {
    qus:"Mantra 5: Choose the right type of capital",
    ans:"One of the most critical points in maximizing return on investment is to lower cost of financing. Lowering it comes when you select the right kind of capital for the right nature of expense. Ideally equity should finance people and marketing, leasing and debt should finance equipment and working capital should finance day to day operations and cashflow mismatch."
  }]
    return (
   <div className='container-fluid'>
    <div className='max-container pt-5'>
    <div className='heading-600-24-20'>{title}</div>
    <div className='heading-500-16-12 pt-2'>{para}</div>
    <div className='w-100 pt-4'>
    <img className='w-100' src='/asset/image555a.png' alt='Blog'/>
    </div>
    </div>
    {blogQA.map((items,index)=>(
        <div className='max-container pt-4' key={index}>
        <div className='max-container pt-4'>
        <div className='heading-500-24-14'>
        {items.qus}
        </div>
        <div className='heading-400-14-12 op-60 pt-2'>
        {items.ans}
        </div>
    
        </div>
    
        </div>
    ))
   }
   </div>
  )
}

export default BlogSecond