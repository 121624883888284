// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img-wrap-main{max-width:600px;padding: 30px 0 20px;}
.sell-img-wrap{margin:-24px 0 0;}
.sell-img-wrap .product{padding:40px;position:relative;transition:.2s;}
.sell-img-wrap .product:hover{background:#9B9E51;}
.sell-img-wrap .product .product-img{display:flex;width:100%;max-width:350px;margin:0 auto;}

.sell-img-wrap .slick-slider .slick-arrow{display: none !important;}
.product-img-wrap-main .viewall-div .framre svg path{fill:#fff;}
.product-img-wrap-main .viewall-div{position: relative;}
 .viewall-div .drag{background:#73509ec9;position: absolute; width:100px;height:100px;color:#fff;display:flex;align-items:center;justify-content:center;border-radius:100%;overflow:hidden;cursor:pointer;}
 .viewall-div .framreplus{background:#73509E;right: 32px;top: 300px;z-index: 1; position: absolute; width:48px;height:48px;display:flex;align-items:center;justify-content:center;border-radius:10px;overflow:hidden;border:none;}
`, "",{"version":3,"sources":["webpack://./src/component/Selling/MachineSale/MachineSaleImage.css"],"names":[],"mappings":";AACA,eAAe,eAAe,CAAC,oBAAoB,CAAC;AACpD,eAAe,gBAAgB,CAAC;AAChC,wBAAwB,YAAY,CAAC,iBAAiB,CAAC,cAAc,CAAC;AACtE,8BAA8B,kBAAkB,CAAC;AACjD,qCAAqC,YAAY,CAAC,UAAU,CAAC,eAAe,CAAC,aAAa,CAAC;;AAE3F,0CAA0C,wBAAwB,CAAC;AACnE,qDAAqD,SAAS,CAAC;AAC/D,oCAAoC,kBAAkB,CAAC;CACtD,mBAAmB,oBAAoB,CAAC,kBAAkB,EAAE,WAAW,CAAC,YAAY,CAAC,UAAU,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,eAAe,CAAC,cAAc,CAAC;CACzM,yBAAyB,kBAAkB,CAAC,WAAW,CAAC,UAAU,CAAC,UAAU,EAAE,kBAAkB,EAAE,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,eAAe,CAAC,WAAW,CAAC","sourcesContent":["\r\n.img-wrap-main{max-width:600px;padding: 30px 0 20px;}\r\n.sell-img-wrap{margin:-24px 0 0;}\r\n.sell-img-wrap .product{padding:40px;position:relative;transition:.2s;}\r\n.sell-img-wrap .product:hover{background:#9B9E51;}\r\n.sell-img-wrap .product .product-img{display:flex;width:100%;max-width:350px;margin:0 auto;}\r\n\r\n.sell-img-wrap .slick-slider .slick-arrow{display: none !important;}\r\n.product-img-wrap-main .viewall-div .framre svg path{fill:#fff;}\r\n.product-img-wrap-main .viewall-div{position: relative;}\r\n .viewall-div .drag{background:#73509ec9;position: absolute; width:100px;height:100px;color:#fff;display:flex;align-items:center;justify-content:center;border-radius:100%;overflow:hidden;cursor:pointer;}\r\n .viewall-div .framreplus{background:#73509E;right: 32px;top: 300px;z-index: 1; position: absolute; width:48px;height:48px;display:flex;align-items:center;justify-content:center;border-radius:10px;overflow:hidden;border:none;}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
