import React from "react";

const Dollar = ({fill}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      opacity="60%"
    >
      <path
        d="M45 18C37.71 18 31.05 20.97 26.19 25.65C26.01 25.65 25.92 25.74 25.83 25.83C25.74 25.92 25.65 26.1 25.65 26.19C20.88 31.14 18 37.71 18 45C18 59.85 30.15 72 45 72C59.85 72 72 59.85 72 45C72 30.15 59.85 18 45 18ZM63 62.73L53.19 52.65C52.83 52.29 52.29 52.29 51.93 52.65C51.57 53.01 51.57 53.55 51.93 53.91L61.74 63.9C57.24 67.86 51.39 70.29 45 70.29C31.05 70.29 19.71 58.95 19.71 45C19.71 38.34 22.23 32.31 26.46 27.81L35.82 37.44C36 37.62 36.18 37.71 36.45 37.71C36.63 37.71 36.9 37.62 37.08 37.44C37.44 37.08 37.44 36.54 37.08 36.18L27.72 26.64C32.22 22.41 38.34 19.8 45 19.8C58.95 19.8 70.29 31.14 70.29 45.09C70.29 51.84 67.5 58.14 63 62.73Z"
        fill={fill ? fill:"#211E24"}
      />
      <path
        d="M45.9901 43.56C43.2901 42.48 42.1201 41.67 42.1201 40.05C42.1201 38.79 43.0201 37.35 45.4501 37.35C47.4301 37.35 48.6901 38.07 49.4101 38.43L50.2201 36.36C49.3201 35.82 48.0601 35.37 46.1701 35.28V32.22H44.2801V35.37C41.4001 35.82 39.6001 37.8 39.6001 40.41C39.6001 43.11 41.5801 44.55 44.7301 45.81C47.0701 46.71 48.2401 47.79 48.2401 49.5C48.2401 51.3 46.7101 52.56 44.5501 52.56C42.8401 52.56 41.2201 51.93 40.0501 51.21L39.3301 53.28C40.4101 54.09 42.3001 54.63 44.1001 54.63V57.78H45.9001V54.54C49.1401 54 50.8501 51.75 50.8501 49.23C50.8501 46.44 49.2301 44.82 45.9901 43.56Z"
        fill={fill ? fill:"#211E24"}
      />
    </svg>
  );
};

export default Dollar;
