// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sixthsection-maindiv {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.sixthsection-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.sixthsection-maindiv h1 {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.75rem;
    letter-spacing: 0em;
    text-align: left;
}

.sixthsection-heading p {
    opacity: 60%;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.sixthSectionSlider {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding: 10px;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/component/Annual/slider/slider.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".sixthsection-maindiv {\r\n    margin-top: 10rem;\r\n    display: flex;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    overflow: hidden;\r\n}\r\n\r\n.sixthsection-heading {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.sixthsection-maindiv h1 {\r\n    font-family: Poppins;\r\n    font-size: 2rem;\r\n    font-weight: 600;\r\n    line-height: 2.75rem;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n}\r\n\r\n.sixthsection-heading p {\r\n    opacity: 60%;\r\n    font-family: Poppins;\r\n    font-size: 1rem;\r\n    font-weight: 400;\r\n    line-height: 28px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n}\r\n\r\n.sixthSectionSlider {\r\n    display: flex;\r\n    gap: 1rem;\r\n    overflow-x: scroll;\r\n    scroll-behavior: smooth;\r\n    white-space: nowrap;\r\n    padding: 10px;\r\n    overflow-x: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
