import React from 'react'

const SellMachine = ({fill}) => {
  return (
    <svg
      width="100"
      height="90"
      //   stroke={stroke}
      viewBox={fill ? "4 5 90 90":"0 0 100 100"}
      fill={"none" || fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1000003281">
        <g id="Group">
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector"
                d="M75.6098 64.5394L54.5905 34.1514C53.0291 32.59 51.1073 31.3889 48.7051 31.3889L40.5376 31.7492C39.3365 28.9867 37.7751 26.3442 36.6941 24.7828C32.3701 18.5371 26.9651 13.4924 23.8423 15.4142C21.0797 16.9756 25.764 25.9839 28.5266 30.1878C29.2472 31.7492 30.4483 32.9503 31.6494 34.0313C31.2891 34.3916 30.9288 35.2324 30.4483 35.9531L25.764 47.6038C25.0434 49.5256 25.0434 51.9278 26.4847 53.8495L47.504 84.2375C48.7051 85.7989 49.7861 86.5196 51.828 87C52.1883 87 52.5486 87 53.0291 87C54.2302 87 55.7916 86.6397 56.8726 85.7989L74.0484 73.7879C75.6098 72.5868 76.3305 71.5058 76.8109 69.4639C76.8109 67.6622 76.4506 66.1008 75.6098 64.5394ZM30.088 29.1068C26.2445 23.7018 24.683 17.4561 25.0434 16.6153C25.764 16.255 30.088 18.5371 34.7723 25.6236C36.3337 27.9057 37.5348 29.9476 38.2555 31.8693H36.3337C35.1326 31.8693 34.0516 32.2297 32.8505 33.0704C32.0098 31.8693 30.9288 30.6682 30.088 29.1068ZM72.9674 71.9862L55.7916 83.9972C54.5905 84.7179 53.5095 84.7179 52.3084 84.7179C51.1073 84.3576 50.0263 83.9972 49.1856 82.7961L28.1662 52.5283C27.4456 51.3272 26.9651 49.7658 27.8059 48.2043L32.4902 36.5536C33.2109 34.9922 34.7723 33.7911 36.3337 33.7911H39.0963C39.8169 36.0732 40.2974 37.6346 39.8169 38.1151C39.8169 38.1151 39.4566 38.1151 37.8952 37.3944C37.5348 37.0341 36.6941 37.3944 36.6941 37.7547C36.3337 38.1151 36.6941 38.9558 37.0544 38.9558C37.7751 39.3162 38.9762 40.1569 40.1773 40.1569C40.5376 40.1569 40.8979 40.1569 41.3784 39.7966C42.099 39.076 42.099 36.6737 41.3784 33.9112L49.1856 33.5509C50.747 33.5509 51.9481 34.2715 53.0291 35.4726L74.0484 65.8606C74.7691 67.0617 74.7691 68.1427 74.7691 69.3438C74.5288 70.4248 73.6881 71.1454 72.9674 71.9862Z"
                fill={fill ? fill : "#211E24"}
              />
              <path
                id="Vector_2"
                d="M58.9144 59.8551C57.3529 57.573 55.0708 57.0926 51.948 57.9333C49.1854 58.654 47.624 58.654 46.9033 57.573C46.1827 56.3719 46.1827 54.8105 48.1044 53.7295C49.6659 52.5284 51.2273 52.5284 51.5876 52.5284L51.2273 50.6066C50.0262 50.6066 48.9452 50.9669 47.3838 52.168L45.7022 48.925L44.1408 50.1262L45.7022 52.4082C43.7804 54.33 43.4201 57.0926 44.9815 59.0143C46.543 61.2964 48.8251 61.2964 51.948 60.5758C54.23 60.2154 55.7915 60.2154 56.6323 61.7769C57.8334 63.3383 56.9926 64.8997 55.4311 66.1008C53.8697 66.8215 52.3083 67.6623 51.1072 67.6623L51.4675 69.584C52.6686 69.584 54.5904 69.2237 56.1518 68.0226L57.7132 70.3047L59.2747 69.1036L57.7132 66.9416C60.1155 64.5394 60.1155 61.7769 58.9144 59.8551Z"
                fill={fill ? fill : "#211E24"}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SellMachine


