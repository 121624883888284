import React,{useState} from "react";
import "./MachineSold.css";
import {soldAllIcon1, soldAllIcon2} from "../../../helpers/Icons";
const MachineSold = () => {
    const [hoveredItems, setHoveredItems] = useState([]);
    const handleMouseEnter = (index) => {
        setHoveredItems((prevHovered) => [...prevHovered, index]);
      };
    
      const handleMouseLeave = (index) => {
        setHoveredItems((prevHovered) => prevHovered.filter((item) => item !== index));
      };
    
    const machineData = [
        {image: "/asset/image-536.png",processStatus: "Sold",name: "CNC MACHINE",name1:"| Hitachi",processingWork:"Processing Paperwork",buttonText:"View Details",buyersText:"Buyers Name",buyersName:"Manoj Shah",amountRecived:"Amount Received",recivedText:"Received",paymentStatus:"Payment Status",status:"Recieved",transactionText:"Transaction ID",transID:"53574646",transDate:"Transaction Date",date:"11th June 2023"},
        {image: "/asset/image-536.png",processStatus: "Sold",name: "CNC MACHINE",name1:"| Hitachi",processingWork:"Processing Paperwork",buttonText:"View Details",buyersText:"Buyers Name",buyersName:"Manoj Shah",amountRecived:"Amount Received",recivedText:"Received",paymentStatus:"Payment Status",status:"Recieved",transactionText:"Transaction ID",transID:"53574646",transDate:"Transaction Date",date:"11th June 2023"},
    ];
    return (          
        <>
            {machineData.map((machine, index) => (
                <div  key={index} className={`max-container sold-wrap ${hoveredItems.includes(index) ? 'hovered' : ''}`} onMouseEnter={() => handleMouseEnter(index)}onMouseLeave={() => handleMouseLeave(index)}>
                    <div className="img-wrap-1">
                        <img className="img-1" src={machine.image || "/asset/placeholder.png"} alt="cnc machine"/>
                    </div>
                    <div className="price-wrap">
                        <div className="top">
                            <div className="left-wrap">
                                <button className="process-btn">{machine.processStatus}</button>
                                <div className="name heading-600-20 heading-600-20-16">{machine.name} <span className="heading-400-20 heading-400-20-14">{machine.name1}</span></div>
                                <div className="paperwork">{machine.processingWork}</div>
                            </div>
                            <div className="right">
                                {hoveredItems.includes(index) ? (
                                    soldAllIcon1({ width: 388, height: 38 })
                                ) : (
                                    soldAllIcon2({ width: 388, height: 38 })
                                )}
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="bottom-left-wrap">
                                <div className="info"><span className="light-txt heading-400-12-10">{machine.buyersText}</span><span className="info-heading heading-600-14 heading-600-14-12">{machine.buyersName}</span></div>
                                <div className="info"><span className="light-txt heading-400-12-10">{machine.amountRecived}</span><span className="info-heading heading-600-14 heading-600-14-12">{machine.recivedText}</span></div>
                                <div className="info"><span className="light-txt heading-400-12-10">{machine.paymentStatus}</span><span className="info-heading heading-600-14 heading-600-14-12">{machine.status}</span></div>
                                <div className="info"><span className="light-txt heading-400-12-10">{machine.transactionText}</span><span className="info-heading heading-600-14 heading-600-14-12">{machine.date}</span></div>
                                <div className="info"><span className="light-txt heading-400-12-10">{machine.transDate}</span><span className="info-heading heading-600-14 heading-600-14-12">{machine.date}</span></div>
                            </div>
                            <div className="advance-info">
                                <button className="view-btn" type="button" onClick={() => window.location="/sell/machine-sold-grid"}>{machine.buttonText}</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default MachineSold;
