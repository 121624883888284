import React,{useState} from "react";
import ImageSlider from "../../Buying/Modals/ImageSlider";
import {addIcon} from "../../../helpers/Icons";
import "./MachineSaleImage.css";
import Slider from "react-slick";

const MachineSaleImage = (media) => {
    console.log(media?.media,'dad')
    const [showModal, setShowModal] = useState(false);
    const handleModal = (status) => {
        if(status){
            setShowModal(status);
        }else{
            setShowModal(false);
        }
    }
    const product = [
        {is_product_images: true, images: [
                {image: "/asset/image529(1).png", button_type: "view_all"},
                {image: "/asset/image529(1).png"},
                {image: "/asset/image529(1).png", button_type: "add"}
        ]}
    ]
    const options = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            {showModal && (
                <div className="img-wrap">
                    <ImageSlider modalAction={handleModal} sliderImage={media?.media}/>
                </div>
            )}
            {product.map((prodcut) => (
                media?.media && (
                    <div className="product-img-wrap-main">
                    <div className="max-container viewall-div">
                    <div className="drag" onClick={() => handleModal("image-modal")}>View all</div>
                    <button className="framreplus">{addIcon({width:24,height:24,fill:"#fff"})}</button> 
                    </div>
                        <Slider {...options}>   
                            {media?.media.map((image, index) => (
                                <div key={index} className="product">
                                    <img className="product-img" src={image.url} alt={image.url}/>
                                    
                                </div>
                            ))}
                        </Slider>
                    </div>
                )
            ))} 
        </>
    );
}
export default MachineSaleImage;