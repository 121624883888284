import React,{useEffect, useState} from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../SubComponent/Breadcrumbs';
import ReviewSection from '../Product/ReviewSection';
import AccountFooter from '../Account/AccountFooter';

const ReviewPageMob = () => {
  const navigate=useNavigate();
  const location=useLocation();
  const [reviewData,setreviewData]=useState();
  const revi=location?.state?.reviewData
  const breadcrumbsItems = [ { name: "AMC", link: "/service" }, { name: "My Machines", link: "/service" }];
  const breakpoints={
    def:6,
    a:4,
    b:3,
    c:2,
    d:2,
    e:2
  }
  const boldtitle="See All Reviews";
useEffect(() => {
  setreviewData(location?.state?.reviewData)
}, [])
if(!reviewData){
  return <p>Loading...</p>
}
 console.log("review data---->>>>",revi)
 const product=location?.state?.product
  return (
    <>
    <div className='container-fluid pt-4'>
      <Breadcrumbs backnavi={()=>navigate(`/service/annual/productidsecond/${location?.state?.productId}`,{ state: { product } })} items={breadcrumbsItems} boldtitle={boldtitle}/>
      <div className='pt-4'>

      {reviewData.map((product,index)=>
        <>
        {index!==0 && <div className='border'></div>}
        <ReviewSection product={product} breakpoints={breakpoints} />
        </>
        )
    }
      </div>
    
    </div>
    <div className='p-r  text-end' style={{top:"2rem"}}>
    <img src='/asset/Frame1000004018.png' alt='Frame1000004018.png'/>
    </div>
    <AccountFooter/>
    </>
  )
}

export default ReviewPageMob