import React from 'react'

const InterestRateDiv = ({rate}) => {
  return (
    <div className='interest-div'>
    <div 
    className='heading-400-16-14'>Calculated Interest Rate</div>
    <div className='heading-600-24-20' style={{color:"#211E24"}}>{rate}</div>
    </div>
  )
}

export default InterestRateDiv