import React from 'react'
const CallTo = ({fill}) => {
  
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g opacity="0.8">
    <path d="M0.834004 2.49935C0.834004 2.03911 1.2071 1.66602 1.66734 1.66602H7.18187C7.65055 1.66602 8.05636 1.99152 8.15805 2.44904L9.10406 6.70512C9.17824 7.03889 9.07676 7.38743 8.83499 7.6292L7.67317 8.79102C8.58412 10.2097 9.79011 11.4154 11.209 12.326L12.3708 11.1648C12.6126 10.9232 12.9611 10.8218 13.2947 10.8959L17.551 11.842C18.0085 11.9437 18.334 12.3495 18.334 12.8181V18.3327C18.334 18.7929 17.9609 19.166 17.5007 19.166C14.2927 19.171 11.152 18.2459 8.459 16.5027C6.47337 15.2176 4.78239 13.5266 3.49734 11.541C1.75413 8.84798 0.829012 5.70734 0.834004 2.49935ZM2.52317 3.33268C2.66333 5.93266 3.48186 8.45062 4.89734 10.636C6.05431 12.4234 7.57657 13.9457 9.36401 15.1027C11.5494 16.5182 14.0674 17.3367 16.6673 17.4768V13.3527L13.2898 12.6027L11.4782 14.4152L10.9257 14.101C8.82794 12.9088 7.09122 11.1721 5.899 9.07435L5.58484 8.52185L7.39734 6.71018L6.64734 3.33268H2.52317Z" fill={fill} />
  </g>
</svg>
  )
}

export default CallTo