import React from 'react'

const LocationIcon = ({width,height,fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
    <path d="M17.4863 12.7573C17.4863 13.0628 17.3649 13.3557 17.1489 13.5717C16.9329 13.7878 16.6399 13.9091 16.3344 13.9091C16.029 13.9091 15.736 13.7878 15.52 13.5717C15.304 13.3557 15.1826 13.0628 15.1826 12.7573C15.1826 12.4518 15.304 12.1588 15.52 11.9428C15.736 11.7268 16.029 11.6055 16.3344 11.6055C16.6399 11.6055 16.9329 11.7268 17.1489 11.9428C17.3649 12.1588 17.4863 12.4518 17.4863 12.7573Z" stroke={fill} stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M22.67 13.335C22.67 16.8342 20.3664 20.8218 16.335 25.4291C12.3036 20.8218 10 16.8342 10 13.335C10 11.6549 10.6674 10.0435 11.8555 8.85548C13.0435 7.66744 14.6549 7 16.335 7C18.0151 7 19.6265 7.66744 20.8145 8.85548C22.0026 10.0435 22.67 11.6549 22.67 13.335Z" stroke={fill} stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
  )
}

export default LocationIcon