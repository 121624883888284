import React, { useState } from 'react'
import './ProductBlock.css'
import { Container } from 'react-bootstrap'
import Button from '../../Button/Button';
import Correct from '../../SubComponent/AllSvgs/Correct';
import Wrong from '../../SubComponent/AllSvgs/Wrong';

const priceConvert = (price) => {
    price = typeof price === 'string' ? price : String(price);
    let count=1;
        let comma=3;
         let formatedPrice=""
         for(let i=price.length-1;i>=0;i--){
             formatedPrice=price[i]+formatedPrice
             if(count===comma){
                  formatedPrice=","+formatedPrice
                 comma=2;
                 count=0;
             }count++;
          
         }
         console.log("==>>",formatedPrice)
            if(formatedPrice[0]===","){
                formatedPrice =formatedPrice.slice(1, formatedPrice.length)
             }
             return formatedPrice;

};

const ProductBlock = (props) => {   
    const [isHovered, setIsHovered] = useState(false);
    
    const { title, price, imageUrl,id,onProductBlockNavigate,product} = props;

    const onMouseEnterhandler = () => {
        setIsHovered(true);
    };

    const onMouseLeavehandler = () => {
        setIsHovered(false);
    };
    const onClickhandle = () => {
        window.scrollTo(0, 0);
        // navigate('/product');
        // navigate(`/product/${planId}`);
        console.log(id,"Show Product")
        onProductBlockNavigate(product)
    }
// {title}
    return (
        <div className='amc-min-height'> 
        <div className={`amc-productblock ${isHovered ? "hovered" : ""}`} onMouseEnter={onMouseEnterhandler} onMouseLeave={onMouseLeavehandler}>
            <div className='product-top-section'>
                <div className='left-side-data'>
                    <p className='heading-600-24-20 m-0'>PRO Plan</p>
                    <p className='heading-600-32-24 text-start m-0'>₹{priceConvert(price)}</p>
                    <strike className="heading-400-16-12 op-60"><p>MRP ₹35,000</p></strike>
                    <p className='heading-600-16-12 c-active m-0'>10% OFF</p>
                    <p className='heading-300-14-10 m-0'>Limited time offer 2 Days</p> 
                </div>
                <div className="right-side-img">
                    <img className='w-100' src={imageUrl} alt={imageUrl} />
                </div>
            </div>
            <div className=' row'>
            <div className='col col-12 pr-4'>
                <p className='break-line'></p>
            </div>
                <div className='col-lg-6 col-6 d-flex'>
                  <Correct fill={isHovered ? "#FFFFFF":null}/> <p className='pl-1 heading-400-14-12'>Evolve program</p> 
                </div>
                <div className='col-lg-6 col-6 d-flex'>
                <Correct fill={isHovered ? "#FFFFFF":null}/><p className='pl-1 heading-400-14-12'>Safety Checks</p>
         
                </div>
                <div className='col-lg-6 col-6 d-flex'>
                <Correct fill={isHovered ? "#FFFFFF":null}/><p className='pl-1 heading-400-14-12'>Remote Assist</p> 
                </div>
                <div className='col-lg-6 col-6 d-flex'>
                <Correct fill={isHovered ? "#FFFFFF":null}/> <p className='pl-1 heading-400-14-12'>Quality Checks</p> 
                </div>
                <div className='col-lg-6 col-6 d-flex op-60'>
               <Wrong fill={isHovered ? "#FFFFFF":null} inner={isHovered ? "#000000":null}/> <p className='pl-1 heading-400-14-12'>Preventive <br/> Maintenance</p> 
                </div>
               
                <div className='col-lg-6 col-6 d-flex op-60'>
                <Wrong fill={isHovered ? "#FFFFFF":null} inner={isHovered ? "#000000":null}/><p className='pl-1 heading-400-14-12'>Updates</p> 
                </div>
                </div>
                <div className='col col-12 d-flex justify-content-end'>{isHovered && <Button message={"View Plan"} callFunction={onClickhandle} />}</div>
           
        </div>
        </div>
    )
}

export default ProductBlock