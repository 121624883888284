// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-image{position: relative;}
.banner-image-div .view-all{position: absolute;width:102px;height:102px;background:#73509E;opacity: 0.8;border-radius:50%;bottom:75px;left:161px;display: flex;align-items: center;justify-content: center;color:#FFF;z-index:1;cursor: pointer;}
@media (max-width:991px){
    .banner-image-div .view-all{width:102px;height:102px;bottom:164px;left:267px;}
    .liner-background .max-container .pt-5 .row{padding-top:0 !important;}
    .liner-background .max-container .pt-5 .row .pt-5{padding-top:0 !important;}
    .liner-background .max-container .pt-5 .row .pt-5 .flex-column .pr-5{padding-top:16px !important;}
    .liner-background .max-container .pt-5 .row .pt-5 .flex-column .pr-5 h1{margin-bottom:0 !important;}
}
@media (max-width:767px){
    .banner-image-div .view-all{display: none;}
}`, "",{"version":3,"sources":["webpack://./src/component/Selling/Home/FirstSection.css"],"names":[],"mappings":"AAAA,cAAc,kBAAkB,CAAC;AACjC,4BAA4B,kBAAkB,CAAC,WAAW,CAAC,YAAY,CAAC,kBAAkB,CAAC,YAAY,CAAC,iBAAiB,CAAC,WAAW,CAAC,UAAU,CAAC,aAAa,CAAC,mBAAmB,CAAC,uBAAuB,CAAC,UAAU,CAAC,SAAS,CAAC,eAAe,CAAC;AAChP;IACI,4BAA4B,WAAW,CAAC,YAAY,CAAC,YAAY,CAAC,UAAU,CAAC;IAC7E,4CAA4C,wBAAwB,CAAC;IACrE,kDAAkD,wBAAwB,CAAC;IAC3E,qEAAqE,2BAA2B,CAAC;IACjG,wEAAwE,0BAA0B,CAAC;AACvG;AACA;IACI,4BAA4B,aAAa,CAAC;AAC9C","sourcesContent":[".banner-image{position: relative;}\r\n.banner-image-div .view-all{position: absolute;width:102px;height:102px;background:#73509E;opacity: 0.8;border-radius:50%;bottom:75px;left:161px;display: flex;align-items: center;justify-content: center;color:#FFF;z-index:1;cursor: pointer;}\r\n@media (max-width:991px){\r\n    .banner-image-div .view-all{width:102px;height:102px;bottom:164px;left:267px;}\r\n    .liner-background .max-container .pt-5 .row{padding-top:0 !important;}\r\n    .liner-background .max-container .pt-5 .row .pt-5{padding-top:0 !important;}\r\n    .liner-background .max-container .pt-5 .row .pt-5 .flex-column .pr-5{padding-top:16px !important;}\r\n    .liner-background .max-container .pt-5 .row .pt-5 .flex-column .pr-5 h1{margin-bottom:0 !important;}\r\n}\r\n@media (max-width:767px){\r\n    .banner-image-div .view-all{display: none;}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
