import React from 'react'
import { Container } from 'react-bootstrap'
import './Listofservicewithoutdes.css'
const ListofServiceWithoutdes = ({ svgcode, para,title }) => {
  const onClickHandler=()=>{
    return
  }
  return (
    <Container fluid className="listofservicewithoutdes p-0 curser-pointer">
      <div   onClick={onClickHandler}>{svgcode}</div>
      {title && <p className='heading-600-18 text-center'>{title}</p>}
      <p className="heading-400-14-12 text-center op-80">{para}</p>
    </Container>
  )
}

export default ListofServiceWithoutdes