import React from 'react'

const BuyMachine = ({fill}) => {
  return (
    <svg
      width="86"
      height="90"
      viewBox={!fill ?"0 0 96 72":"0 5 86 62"}
      fill={"none" || fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1000003877">
        <path
          id="Vector"
          class="svg-path"
          d="M1.07129 61H84.929"
          stroke={fill ? fill : "#211E24"}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M36.9136 60.2302L45.1386 50.9028"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_3"
            d="M70.2378 60.3998L59.2993 50.9028"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_4"
            d="M52.4732 55.8209C56.243 55.8209 59.2991 52.7648 59.2991 48.9949C59.2991 45.225 56.243 42.1689 52.4732 42.1689C48.7033 42.1689 45.6472 45.225 45.6472 48.9949C45.6472 52.7648 48.7033 55.8209 52.4732 55.8209Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_5"
            d="M52.5157 51.4963C53.8738 51.4963 54.9747 50.3954 54.9747 49.0373C54.9747 47.6792 53.8738 46.5782 52.5157 46.5782C51.1576 46.5782 50.0566 47.6792 50.0566 49.0373C50.0566 50.3954 51.1576 51.4963 52.5157 51.4963Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_6"
            d="M60.2073 28.021C63.9772 28.021 67.0333 24.965 67.0333 21.1951C67.0333 17.4252 63.9772 14.3691 60.2073 14.3691C56.4374 14.3691 53.3813 17.4252 53.3813 21.1951C53.3813 24.965 56.4374 28.021 60.2073 28.021Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_7"
            d="M60.2498 23.6965C61.6079 23.6965 62.7088 22.5956 62.7088 21.2375C62.7088 19.8794 61.6079 18.7784 60.2498 18.7784C58.8917 18.7784 57.7908 19.8794 57.7908 21.2375C57.7908 22.5956 58.8917 23.6965 60.2498 23.6965Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_8"
            d="M48.1912 43.5257L55.0595 25.9733"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_9"
            d="M59.3411 46.5217L62.2254 27.8953"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_10"
            d="M29.4883 4.49646L57.3986 14.4109"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_11"
            d="M29.2383 10.3285L53.4828 23.2422"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_12"
            d="M24.1562 1C27.0071 1 29.3217 3.31458 29.3217 6.16549V13.0183C29.3217 13.2811 29.1083 13.4945 28.8456 13.4945H19.5145C19.2254 13.4945 18.9907 13.2598 18.9907 12.9707V6.16549C18.9907 3.31458 21.3053 1 24.1562 1Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_13"
            d="M24.3086 24.3752C25.6667 24.3752 26.7677 23.2743 26.7677 21.9162C26.7677 20.5581 25.6667 19.4572 24.3086 19.4572C22.9506 19.4572 21.8496 20.5581 21.8496 21.9162C21.8496 23.2743 22.9506 24.3752 24.3086 24.3752Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_14"
            d="M22.0581 13.5232L22.1518 19.8999L16.0564 23.0883V28.0584L20.1825 35.9356V25.9016L24.3087 24.3751L28.2473 26.1829V35.5605L32.5609 28.3398V22.6194L26.4781 20.0597V13.325"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default BuyMachine

