import React,{useState,useEffect} from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Breadcrumbs from '../SubComponent/Breadcrumbs';
import {useLocation, useNavigate } from 'react-router-dom';
import HeartProductCategory from '../SubComponent/AllBlock/HeartProductCategory';
import { filterIcon,leftArrowIcon,rightArrowIcon } from '../../helpers/Icons';
import CategoryPopup from './Popup/CategoryPopup';
import AccountFooter from './AccountFooter';
const WishlistedMachines = () => {
  const [showModel,setShowModel]=useState(false);
  const [isSmallScreen,setIsSmallScreen]=useState(window.innerWidth<992)
  const [pagiCount, setPagiCount] = useState(1);
  const [pagiLength,setPagiLength]=useState(0); 
  const breadcrumbsItems = [ { name: "Home page", link: "/" }, { name: "My Account", link: "/myaccount" }];
    const navigate=useNavigate();
    const location=useLocation();
    const{state}=location;
    console.log("wishlisted machine===>>>",state.productData);
    const boldtitle="My Machines";
    const currentMachines =state.productData

    const onFilterHandler=()=>{
setShowModel(!showModel)
    }
  
const ITEMS_PER_PAGE = 4;
const BUTTONS_VISIBLE = 7;

console.log("active page==>>",pagiCount);

const totalPages = Math.ceil(pagiLength / ITEMS_PER_PAGE);

  const onPrevHandler = () => {
    if (pagiCount > 1) {
      setPagiCount(pagiCount - 1);
    }
  };

  const onNextHandler = () => {
    
    if (pagiCount < totalPages && pagiCount*4<pagiLength) {
        console.log("pagiCount next button===>>>",pagiCount);
      setPagiCount(pagiCount + 1);
    }
  };
  const renderPageButtons = () => {
    const buttons = [];
    const middleButton = Math.ceil(BUTTONS_VISIBLE / 1);
    const startButton = Math.max(1, pagiCount - middleButton + 1);
    const endButton = Math.min(startButton + BUTTONS_VISIBLE - 1, totalPages);
    
    for (let i = startButton; i <= endButton; i++) {
      buttons.push(
        <button
          key={i}
          className={`pagination-btn ${pagiCount === i ? 'active' : ''}`}
          onClick={() => setPagiCount(i)}
          disabled={i*4<pagiLength ? false:true}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };
useEffect(() => {
  setPagiLength(currentMachines.length)
}, [])


    return (
      <>
    <div className='container-fluid m-0'>
    {showModel && <CategoryPopup onHide={onFilterHandler}/>}
    <div className='max-container pt-4'>
    <Breadcrumbs items={breadcrumbsItems} boldtitle={boldtitle} backnavi={()=>navigate('/wishlist')} />
    <div className='pt-5'>
    <h1 className='heading-600-44-24'>Wishlistetd  Machines</h1>
    <p className='heading-400-16-14 op-60'>From Machines to tools to finance everything you need in one place</p>
    </div>
    
    {/*dropdown button*/}
    <div className='hide-992'>
    <div className='container-fluid p-0 m-0 row d-flex justify-content-end'>
    <div className='col col-md-auto'>
    <DropdownButton id="dropdown-basic-button" className='dropdown-button' title="Category">
    <Dropdown.Item href="#/action-1">AMC</Dropdown.Item>
    <Dropdown.Item href="#/action-2">VMC</Dropdown.Item>
    <Dropdown.Item href="#/action-3">CMC</Dropdown.Item>
  </DropdownButton>
    </div>
    <div className='col col-md-auto'>
    <DropdownButton id="dropdown-basic-button" className='dropdown-button' title="Brand">
    <Dropdown.Item href="#/action-1">Brand1</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Brand2</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Brand3</Dropdown.Item>
  </DropdownButton>
    </div>
    <div className='col col-md-auto'>
    <DropdownButton id="dropdown-basic-button" className='dropdown-button' title="Sub category">
    <Dropdown.Item href="#/action-1">Sub category1</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Sub category2</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Sub category3</Dropdown.Item>
  </DropdownButton>
    </div>
    </div>
    </div>
    <div className='show-992'> 
    <div className='container-fluid p-0 m-0 row d-flex justify-content-end'>
    <button className=" button-outline" onClick={onFilterHandler}>{filterIcon({width:22,height:22,fill:"#73509E"})}All Filters</button>
    </div> 
    </div>

<div className='max-container pt-5'>

{!isSmallScreen && (currentMachines.length > 0 ? (
  <div className='d-flex between-to-center flex-wrap'>
      {currentMachines.map((machine,index) => (
        <div className='container-fluid m-0 p-0' style={{height:"500px",maxWidth:"350px"}} key={index}>
          <HeartProductCategory product={machine} index={index}/>
          </div>
      ))}
  </div>
) : (
  <div>No Data</div>
))}


{isSmallScreen && (currentMachines.length > 0 ? (
  <div className='d-flex between-to-center flex-wrap'>
      {currentMachines.slice((pagiCount-1)*4,pagiCount*4).map((machine,index) => (
        <div className='container-fluid m-0 p-0' style={{height:"500px",maxWidth:"350px"}} key={index}>
          <HeartProductCategory product={machine} index={index}/>
          </div>
      ))}
      {pagiLength>4 && 
      <div className='d-flex justify-content-center ' style={{paddingBottom:"100px"}}>
        
      <div className="bi-pagination-wrap">
                                                <div className="inner">
                                                  <button className="outer-btn" onClick={onPrevHandler}>
                                                  {leftArrowIcon({ width: 24, height: 24 })}
                                                  </button>
                                                  <div className="inner-btns">{renderPageButtons()}</div>
                                                  <button className="outer-btn" onClick={onNextHandler} >
                                                  {rightArrowIcon({ width: 24, height: 24 })}
                                                  </button>
                                                </div>
                                        
                                              </div>
      </div>}
  </div>
) : (
  <div>No Data</div>
))}
<div className='p-r p-0 d-flex justify-content-end'>
<img className='p-a right-0 ' style={{top:"-35px"}} src='asset/Frame1000004018.png' alt='Frame1000004018.png'/>
</div>
</div>

    </div>
  
    </div>
  <AccountFooter/>
    </>
  )
}

export default WishlistedMachines