import React from 'react'
import BtnShipped from '../../Button/BtnShipped'
import Button from '../../Button/Button'

const OrderDetails = ({imageurl,}) => {
  return (
    <div className='container-fluid p-0 m-0 row mt-5 border border-black'>
        <div className='col col-md-4 align-self-center'>
            <img src="asset/yourorder.png" alt="yourorder.png"/>
        </div>
        <div className='col col-md-8'>
            <div className='row pt-3'>
            <div className='col d-flex align-item-center'>
                <BtnShipped message={"Shipped "}/><p className='pl-2'>Your item is on the way</p>
                </div>
            </div>
            <div className='row'>
               <div className='col heading-600-20'>
                Welding Machine
                </div>
               </div>
               <div className='row pt-2'> 
           <div className='col col-md-5 heading-500-14'>
           iBELL M200-105 IGBT Inverter 2 in 1 Flux Core/Solid Wire MAG Welding Machine with 1 Year Warranty
           </div>
        </div>
        <div className='row justify-content-between pb-3'>
          <div className='col col-lg-2 col-md-12 heading-600-18 align-self-center'>₹4,465</div>
          <div className='col col-lg-4 col-md-12 text-end'><Button message={"View Plan details"}/></div>
        </div>
        </div>
        
    </div>
  )
}

export default OrderDetails