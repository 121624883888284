import React,{useState,useEffect} from 'react'
import ProductIdThirdSection from '../productIdcomponent/ProductIdThirdSection'
import ProductIdSixSection from '../productIdcomponent/ProductIdSixSection'
import ProductIdSecondSection from '../productIdcomponent/ProductIdSecondSection'
import ProductIdSeventhSection from '../productIdcomponent/ProductIdSeventhSection'
import ProductIdFourthSection from '../productIdcomponent/ProductIdFourthSection'
import ProductIdFirstSection from '../productIdcomponent/ProductIdFirstSection'
import ProductIdEightSection from '../productIdcomponent/ProductIdEightSection'
import ProductIdFifthSection from '../productIdcomponent/ProductIdFifthSection'
import Footer from '../../Footer/Footer'
import { useParams,useLocation } from 'react-router-dom'
import axios from 'axios'
import client from '../../Services/ServicesPopup/apolloclient'
import { gql } from 'graphql-tag';
import LoginModel from '../../Authentication/LoginModel/LoginModel'

const CREATE_PAYMENT= gql`
mutation CreateAmc($amcPlanData: AMCPlanInput!) {
  createAmc(amcPlanData: $amcPlanData) {
    message
    success
    paymenturl
    referenceid
  }
}
`;
const priceConvert = (price) => {
  price = typeof price === 'string' ? price : String(price);


      let count=1;
      let comma=3;
       let formatedPrice=""
       for(let i=price.length-1;i>=0;i--){
           formatedPrice=price[i]+formatedPrice
           if(count===comma){
                formatedPrice=","+formatedPrice
               comma=2;
               count=0;
           }count++;
        
       }
       console.log("==>>",formatedPrice)
          if(formatedPrice[0]===","){
              formatedPrice =formatedPrice.slice(1, formatedPrice.length)
           }
           return formatedPrice;
   

 
};
const ProductIdSecond = () => {
  const {id}=useParams();
  const [pincode, setPincode] = useState('');
  const [messageShow, setMessageShow] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [loginPortal, setLoginPortal] = useState(false);
  console.log("params id===>>>",id);
  const loggedIn= !!localStorage.getItem('userToken') ;
  const location=useLocation();
  const productData= location.state && location.state.product
  console.log("product data=>>",productData);
  
  const handlePincodeChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
  
    // Update the state only if the input is empty or contains valid characters
    if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length<=6) {
      setPincode(sanitizedInput);
    }
  
   
  };
  const onBuyHandler=async()=>{
    if(!loggedIn)
    {
      setLoginPortal(true);
      return;
    }
        try {
          const { data } = await client.mutate({
            mutation:CREATE_PAYMENT ,
            variables:{
              "amcPlanData": {
                "appAmcPlanId": 4,
                "customerId": "VXNlcjoxMTA=",
                "stage": "PAYMENT",
                "serviceType":"AMC"
              }
            }
            
          });
       console.log("API Response paymentgetway==>>>",data);
    setResponseData(data);
    window.location.href = data.createAmc.paymenturl;
      
        } catch (error) {
          console.error('API Error==>', error.message);
      
        }
      }
    
  useEffect(()=>{
  const fetchServiceArea = () => {
    console.log("fetch area=>>",pincode);
    // Define your GraphQL query
    const graphqlQuery = {
        query: `
            query ServiceArea($pincode: Int!) {
                nearestServicearea(userPincode: $pincode) {
                    message
                    code
                }
            }
        `,
        variables: {
            pincode: parseInt(pincode, 10)
        }
    };
    axios.post('https://devextension.origa.market/graphql/', graphqlQuery)
        .then(response => {
            const data = response.data.data.nearestServicearea;
            console.log("nearestServicearea Productmain==>>",data.code);
            if (data && data.code) {
              if(data.code===1003) {
                setMessageShow(true);
              } else{
                setMessageShow(false)
              }
            }
        
            
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  };
  if(pincode.length===6){
    fetchServiceArea()
  }
  
  },[pincode])
  const onHidePortal = () => {
    setLoginPortal(false);
}
  return (
    <>
     {loginPortal && <LoginModel onHide={onHidePortal} />}
    <ProductIdFirstSection pincodeHandle={handlePincodeChange} pincode={pincode}/>
    <ProductIdSecondSection product={productData}/>
    <ProductIdThirdSection product={productData} messageShow={messageShow} pin={pincode}/>
    <ProductIdFourthSection/>
    <ProductIdFifthSection/>
    <ProductIdSixSection/>
    <ProductIdSeventhSection/>
  <ProductIdEightSection product={productData} id={id}/>
    
    <Footer/>
    <div className='bottom-price-stripe'>
                
              
                    <div className='w-100 p-f bottom-0 bg-white'>
                    <div className='max-container plr-15'>
<div className="row d-flex align-items-center py-3">
    <div className="col col-md-4 col-12">
            <p className='heading-600-24-16 m-0'>STAR AMC PLAN</p>
            {messageShow && <p className='heading-500-14 text-danger pt-1'>This plan is not available at the Pincode - {pincode}</p>}  
    </div>
    <div className="col col-md-4 ">
        <p className='heading-600-24-16 m-0'>₹{priceConvert(productData.price)}</p>
    </div>
    <div className="col col-md-4 d-flex justify-content-end ">
    <button className='button-outline' onClick={onBuyHandler}>Buy Now</button>
    {/* <button className='button' >Add to Cart</button> gap-3*/}
    </div>
</div>
</div>
                    </div>
          
            </div>
    </>
  )
}

export default ProductIdSecond