/* 
when 
1=>heart image,  after that
2=>image, after that
3=>title+line,  after that
4=>message1,    after that
5=>message2 (left side)+ message3(right side) 

*/
import React, { useState } from 'react'
import Heart from '../AllSvgs/Heart'
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
const priceConvert = (price) => {
  price = typeof price === 'string' ? price : String(price);
  let count=1;
        let comma=3;
         let formatedPrice=""
         for(let i=price.length-1;i>=0;i--){
             formatedPrice=price[i]+formatedPrice
             if(count===comma){
                  formatedPrice=","+formatedPrice
                 comma=2;
                 count=0;
             }count++;
          
         }
         console.log("==>>",formatedPrice)
            if(formatedPrice[0]===","){
                formatedPrice =formatedPrice.slice(1, formatedPrice.length)
             }
             return formatedPrice;
};

const findYearDifference=(targetYear)=>{
  const currentYear = new Date().getFullYear();
  const yearDifference = currentYear - targetYear;
  return yearDifference;
}
const HeartProductCategory = ({ product, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [heartColor, setHeartColor] = useState(false);
  const navigate = useNavigate();
  const formattedPrice = priceConvert(product.price);

  const onMouseEnterhandler = () => {
    setIsHovered(true);
  };

  const onMouseLeavehandler = () => {
    setIsHovered(false);
  };

  const onSubmitHandler = () => {
    console.log('product---->',product.productId);
    if (product.productId) {
      
      navigate(`/buy/cnc-machine?id=${product.productId}`)
    }
    if (product.productid) {
      
      navigate(`/buy/cnc-machine?id=${product.productid}`)
    }
   

  }
  const onHeartColor = () => {
    setHeartColor(!heartColor);
  }

  console.log("heart block===>>>",product);
  return (

    <div className={`contaienr-fluid p-0 m-0  row h-485 p-2 ${isHovered ? 'bg-green shadow2' : ''}`} onMouseEnter={onMouseEnterhandler} onMouseLeave={onMouseLeavehandler} key={index}>
      <div className=" p-2">
        <div className=' d-flex justify-content-end' >
          <div onClick={onHeartColor} className='curser-pointer'><Heart fill={heartColor ? "#73509E" : ""} /></div>
        </div>
        
        <div className='heart-block-image-size mx-auto'>
          <img src={product.imageurl} className="w-100 h-100" alt={product.imageurl} />
        </div>
      
        <div className="mt-4">
          <p className={`heading-600-20-16 ${isHovered ? "bottom-halfline1" : "bottom-halfline"}`}>{product.title.slice(0,22)}</p>
          <p className="heading-400-14-12 op-50 my-2">{product.para} | {product?.location}</p>
          <div className="pricesection d-flex justify-content-between">
            <p className='heading-400-16-14'>{findYearDifference(product.time)}&nbsp;year old</p>
            <p className="fs-20 fw-bold">₹{formattedPrice}</p>
          </div>

          {isHovered &&
           ( product.isWishlistBlock ? <div className='w-100 d-flex end-to-start'><button className='button' onClick={onSubmitHandler}>Avail Service</button></div> : <button className='button' onClick={onSubmitHandler}>Avail Service</button>)}
        </div>
      </div>


    </div>

  )
}

export default HeartProductCategory