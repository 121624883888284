import React, { useState } from 'react'
import './Finance.css'
import StepperTick from '../SubComponent/AllSvgs/StepperTick'
import Button from '../Button/Button'
import FinanceModel from './FinanceModel'
import InterestRateDiv from './InterestRateDiv'


const priceConvert = (price) => {
   price = typeof price === 'string' ? price : String(price);
 

       let count=1;
       let comma=3;
        let formatedPrice=""
        for(let i=price.length-1;i>=0;i--){
            formatedPrice=price[i]+formatedPrice
            if(count===comma){
                 formatedPrice=","+formatedPrice
                comma=2;
                count=0;
            }count++;
         
        }
        
           if(formatedPrice[0]===","){
               formatedPrice =formatedPrice.slice(1, formatedPrice.length)
            }
            return formatedPrice;
    

  
};

const FinanceSecondSection = () => {
    const [showModel,setShowModel]=useState(false);
    const [showIntrestRate,SetshowIntrestRate]=useState(false);
const [finaceRate,setFinanceRate]=useState({
   revenue:"",
   selectedOption:"",
   profit:"We are Profitable"
})
    const [validation,setValidation]=useState({Mrevenue:"",selectedOption:""})

const onOptionChange = (event) => {
   setFinanceRate((prev)=>({...prev,selectedOption:event.target.value}));
 };
 const onRevanueHandler=(e)=>{
   const newInputString = e.target.value;
   const sanitizedInput = newInputString.replace(/[^0-9]/g, '');

   // Update the state only if the input is empty or contains valid characters

      setFinanceRate((prev)=>({...prev,revenue:priceConvert(sanitizedInput)}))
   

 }

 const onFinanceHandler=()=>{

   console.log("--check>>>",finaceRate.revenue,"--->>>",finaceRate.selectedOption,"--->>>",finaceRate.profit);
   if(finaceRate.revenue==="" || finaceRate.selectedOption==="" || finaceRate.profit===""){
      if(finaceRate.revenue==="" ){setValidation((prev)=>({...prev,Mrevenue:true}))}
      if(finaceRate.selectedOption==="" ){setValidation((prev)=>({...prev,selectedOption:true}))}
return
   }
   setShowModel(true)
 }
  return (
   <>
   {showModel && <FinanceModel modalAction={()=>setShowModel(false)} interestRate={()=>SetshowIntrestRate(true)}/>}
    <div className='container-fluid f-secons-section-div'>
    <div className='max-container'>
     <div className='row'>
        <div className='col col-md-6 col-12 d-flex align-items-center'>
           <div className='profit-text'>
           <img src="/OrigaFinance.svg" alt="OrigaFinance" />
           <h1 className='heading-600-24-20 c-green'>
           Finance Calculator
           </h1>
           <div className='heading-400-16-14 op-80'>Enter the details given below to calculate your loan/ lease interest rate</div>
           
           </div>
        </div>
        <div className='col col-lg-6 '>
<div className='f-check-select'>


<div className={`finance-bi-form-group `}>
<input type="text" name="machine-add1" id="machine-add1" className={`bi-form-field bg-white ${validation.Mrevenue ? "error-red":""}`}  value={finaceRate.revenue} onChange={onRevanueHandler} onClick={()=>setValidation(prev=>({...prev,Mrevenue:false}))} placeholder="Machine Make"/>
<label htmlFor="machine-add1" className={`heading-400-14-12 bi-form-label c-gray ${validation.Mrevenue ? "error-red":""}`}>Enter your revenue in ₹</label>
</div>

<p className='heading-400-14-12 op-80 f-mt-36'>Select a relevant option</p>
<div className=''>
     
<div className={`d-flex justify-content-between f-border ${finaceRate.profit==="We are Profitable" ? 'bg-purple':null}`} onClick={()=>setFinanceRate((prev)=>({...prev,profit:"We are Profitable"}) )}>
<p className={`heading-400-14 v-center pl-3  ${finaceRate.profit==="We are Profitable" ? 'text-white':null} `}>We are Profitable</p>
<StepperTick fill={` ${finaceRate.profit==="We are Profitable"  ? '#73509E':"#FFFFFF"}`}/>
</div>
</div>

<div className=' mt-3'>

<div className={`d-flex justify-content-between f-border ${finaceRate.profit==="We are not Profitable" ? 'bg-purple':null}`} onClick={()=>setFinanceRate((prev)=>({...prev,profit:"We are not Profitable"}))}>
   <p className={`heading-400-14 v-center pl-3  ${finaceRate.profit==="We are not Profitable" ? 'text-white':null} `}>We are not Profitable</p>
   <StepperTick fill={` ${finaceRate.profit==="We are not Profitable" ? '#73509E':"#FFFFFF"}`}/>
</div>
</div>

<div>
                      <div className={`finance-bi-form-group ${validation.selectedOption ? "error-red" : ""} finance-dropdown`}>
                        <select className={`bi-form-field bi-select-dropdown ${finaceRate.selectedOption ? "" : "empty"} ${finaceRate.selectedOption ==="" ? "heading-400-14-12 c-gray":"heading-400-14-12" } ${validation.selectedOption ? "error-red" : ""}`}  placeholder="state" onChange={onOptionChange} autoCapitalize='off'  onClick={()=>setValidation((prev)=>({...prev,selectedOption:false}))}>
                   
                        <option style={{display:"none"}}></option>
                        <option value="11.00 to 12.75%">11.00 to 12.75%</option>
                        <option value="14.00 to 15.75%">14.00 to 15.75%</option>
                        <option value="16.00 to 18.75%">16.00 to 18.75%</option>
                          
                        </select>
                        <label className="heading-400-14-12 f-dropdown-form-label">Select Credit Rating{<span style={{ color: '#CB1923' }}>*</span>}</label>

                      </div>
                    </div>

   <div className='pt-3 d-flex justify-content-end'>
   {showIntrestRate ? <InterestRateDiv rate={finaceRate.selectedOption}/>:<Button message={"Calculate Now"} callFunction={onFinanceHandler}/>} 
   </div>
</div>
 </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default FinanceSecondSection