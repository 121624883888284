// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lookingtobuy{
    margin-top: 2rem;
}
/* .lookingtobuy-heading{
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.9375rem;
    letter-spacing: 0em;
    text-align: left;
    
} */
/* .lookingtobuy-message{
    opacity: 60%;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;
    
} */
.buttonAndLearnBox{
   margin-top: 6.625rem;
   /* width: 17.68rem; */
   display: flex;
   align-items: center;
   gap: 1rem;
}
/* .buttonAndLearnBox p{
    margin: 0;
    padding: 0;
font-family: Poppins;
font-size: 0.875rem;
font-weight: 600;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;

} */
`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/LokkingBuySell/LookingForBuySellLeft/LookingToBuy/LookingToBuy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;;;;;;;GAQG;AACH;;;;;;;;;GASG;AACH;GACG,oBAAoB;GACpB,qBAAqB;GACrB,aAAa;GACb,mBAAmB;GACnB,SAAS;AACZ;AACA;;;;;;;;;;GAUG","sourcesContent":[".lookingtobuy{\r\n    margin-top: 2rem;\r\n}\r\n/* .lookingtobuy-heading{\r\n    font-family: Poppins;\r\n    font-size: 1.5rem;\r\n    font-weight: 600;\r\n    line-height: 1.9375rem;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    \r\n} */\r\n/* .lookingtobuy-message{\r\n    opacity: 60%;\r\n    font-family: Poppins;\r\n    font-size: 1rem;\r\n    font-weight: 400;\r\n    line-height: 1.75rem;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    \r\n} */\r\n.buttonAndLearnBox{\r\n   margin-top: 6.625rem;\r\n   /* width: 17.68rem; */\r\n   display: flex;\r\n   align-items: center;\r\n   gap: 1rem;\r\n}\r\n/* .buttonAndLearnBox p{\r\n    margin: 0;\r\n    padding: 0;\r\nfont-family: Poppins;\r\nfont-size: 0.875rem;\r\nfont-weight: 600;\r\nline-height: 1.5rem;\r\nletter-spacing: 0em;\r\ntext-align: left;\r\n\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
