import React,{useEffect} from 'react';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThreeSection from './ThreeSection';
import FourSection from './FourSection';
import Footer from '../../Footer/Footer';
import FiveSection from './FiveSection';
import SixSection from './SixSection';
const HomePage = () => {
    useEffect(() => {
      window.scrollTo(0,0);
      
    }, [])
    
    return (
        <>
            <FirstSection/>
             <SecondSection/>
             <ThreeSection/> 
            <FourSection/>
            <FiveSection/>
            <SixSection/>
            <Footer/>
        </>
    );
}
export default HomePage