// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust-row-gap{display:flex;flex-direction:column;row-gap:80px;padding-top:80px;padding-bottom:80px;}
.cust-box-wrap{display:flex;align-items:center;column-gap:80px;}
.cust-box-wrap .image-wrap{width:60%;max-width:100%;}
.cust-box-wrap .image-wrap img{width:100%;}
.cust-box-wrap .cust-content-wrap{width:40%;max-width:100%;}

@media(max-width:1199px){
    .cust-row-gap{row-gap:40px;padding-top:60px;padding-bottom:60px;}
    .cust-box-wrap{column-gap:40px;}
}
@media(max-width:991px){
    .cust-row-gap{padding-top:20px;row-gap:60px;}
    .cust-box-wrap:nth-of-type(odd){display:flex;flex-direction:column-reverse;}
    .cust-box-wrap:nth-of-type(even){display:flex;flex-direction:column;}
    .cust-box-wrap .image-wrap{width:100%;}
    .cust-box-wrap .cust-content-wrap{width:100%;}
    .cust-box-wrap .cust-content-wrap{width:100%;margin:25px 0 0;}
    .cust-box-wrap .cust-content-wrap .heading-600-32-20{margin:15px 0;}
}
`, "",{"version":3,"sources":["webpack://./src/component/Selling/Home/SecondSection.css"],"names":[],"mappings":"AAAA,cAAc,YAAY,CAAC,qBAAqB,CAAC,YAAY,CAAC,gBAAgB,CAAC,mBAAmB,CAAC;AACnG,eAAe,YAAY,CAAC,kBAAkB,CAAC,eAAe,CAAC;AAC/D,2BAA2B,SAAS,CAAC,cAAc,CAAC;AACpD,+BAA+B,UAAU,CAAC;AAC1C,kCAAkC,SAAS,CAAC,cAAc,CAAC;;AAE3D;IACI,cAAc,YAAY,CAAC,gBAAgB,CAAC,mBAAmB,CAAC;IAChE,eAAe,eAAe,CAAC;AACnC;AACA;IACI,cAAc,gBAAgB,CAAC,YAAY,CAAC;IAC5C,gCAAgC,YAAY,CAAC,6BAA6B,CAAC;IAC3E,iCAAiC,YAAY,CAAC,qBAAqB,CAAC;IACpE,2BAA2B,UAAU,CAAC;IACtC,kCAAkC,UAAU,CAAC;IAC7C,kCAAkC,UAAU,CAAC,eAAe,CAAC;IAC7D,qDAAqD,aAAa,CAAC;AACvE","sourcesContent":[".cust-row-gap{display:flex;flex-direction:column;row-gap:80px;padding-top:80px;padding-bottom:80px;}\r\n.cust-box-wrap{display:flex;align-items:center;column-gap:80px;}\r\n.cust-box-wrap .image-wrap{width:60%;max-width:100%;}\r\n.cust-box-wrap .image-wrap img{width:100%;}\r\n.cust-box-wrap .cust-content-wrap{width:40%;max-width:100%;}\r\n\r\n@media(max-width:1199px){\r\n    .cust-row-gap{row-gap:40px;padding-top:60px;padding-bottom:60px;}\r\n    .cust-box-wrap{column-gap:40px;}\r\n}\r\n@media(max-width:991px){\r\n    .cust-row-gap{padding-top:20px;row-gap:60px;}\r\n    .cust-box-wrap:nth-of-type(odd){display:flex;flex-direction:column-reverse;}\r\n    .cust-box-wrap:nth-of-type(even){display:flex;flex-direction:column;}\r\n    .cust-box-wrap .image-wrap{width:100%;}\r\n    .cust-box-wrap .cust-content-wrap{width:100%;}\r\n    .cust-box-wrap .cust-content-wrap{width:100%;margin:25px 0 0;}\r\n    .cust-box-wrap .cust-content-wrap .heading-600-32-20{margin:15px 0;}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
