import React, { useEffect } from 'react'
import BlogFirstSection from './BlogFirstSection'
import BlogSecond from './BlogSecond'
import NetworkSecondsection from '../NetworkSecondsection'
import Footer from '../../Footer/Footer'

const Blog = () => {
useEffect(()=>{
    window.scrollTo(0,0)
})
  return (
    <>
    <BlogFirstSection/>
    <BlogSecond/>
    <NetworkSecondsection title={"Recoomended Blogs"}/>
    <div className='max-container'>
    <div className='text-end p-r' style={{top:"44px"}}>
    <img src='/asset/Frame1000004018.png' alt='Frame1000004018.png'/>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default Blog