// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reasons-wrap{display:flex;flex-direction:column;width:100%;row-gap:5px;margin-top:15px;}
.reasons-wrap .reason{display:flex;padding:10px 20px;border:1px solid #DDD;cursor:pointer;border-radius:6px;position: relative;overflow: hidden;}
.reasons-wrap .reason span{z-index:1;}
.reasons-wrap .selected span{color:#fff;}

.reasons-wrap .reason .tick{
    display: none;
}
.reasons-wrap .selected .tick{
    position: absolute;
    right: -4px;
    top: -1px;
    display: block;
}
.reasons-wrap .selected .tick .main-tick{
    position: absolute;
    right: 4px;
    top: -2px;
}
.reasons-wrap .selected:after{
    content: "";
    background: #9B9E51;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/component/Selling/Modals/RemoveMachineModal.css"],"names":[],"mappings":"AAAA,cAAc,YAAY,CAAC,qBAAqB,CAAC,UAAU,CAAC,WAAW,CAAC,eAAe,CAAC;AACxF,sBAAsB,YAAY,CAAC,iBAAiB,CAAC,qBAAqB,CAAC,cAAc,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,gBAAgB,CAAC;AAChJ,2BAA2B,SAAS,CAAC;AACrC,6BAA6B,UAAU,CAAC;;AAExC;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,kBAAkB;AACtB","sourcesContent":[".reasons-wrap{display:flex;flex-direction:column;width:100%;row-gap:5px;margin-top:15px;}\r\n.reasons-wrap .reason{display:flex;padding:10px 20px;border:1px solid #DDD;cursor:pointer;border-radius:6px;position: relative;overflow: hidden;}\r\n.reasons-wrap .reason span{z-index:1;}\r\n.reasons-wrap .selected span{color:#fff;}\r\n\r\n.reasons-wrap .reason .tick{\r\n    display: none;\r\n}\r\n.reasons-wrap .selected .tick{\r\n    position: absolute;\r\n    right: -4px;\r\n    top: -1px;\r\n    display: block;\r\n}\r\n.reasons-wrap .selected .tick .main-tick{\r\n    position: absolute;\r\n    right: 4px;\r\n    top: -2px;\r\n}\r\n.reasons-wrap .selected:after{\r\n    content: \"\";\r\n    background: #9B9E51;\r\n    height: 100%;\r\n    width: 100%;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    border-radius: 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
