// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .head-wrap{flex-direction: column;align-items: flex-start;margin:0;}
.bi-popup-wrap .inner .head-wrap .heading-600-20 {color:#211E24;margin:0 0 8px;}
.bi-popup-wrap .inner .field-group{max-width: 600px;width:100%;padding-top: 15px;margin:15px 0 0;position: relative;}
.bi-popup-wrap .inner .field-group .input-field{width: 100%;outline: 0;border: 1px solid #73509E; padding: 10px;background: transparent;}
.bi-popup-wrap .inner .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #fff;color:#73509E;padding:5px;}
.bi-popup-wrap .inner .text{color:#211E24;opacity: 0.8;text-align: left;}
.bi-popup-wrap .inner .otp-btn{flex:54px 0;background:#73509E99;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}

@media(max-width:767px){
    .bi-popup-wrap .inner .head-wrap .heading-600-20{margin:0;}
    .bi-popup-wrap .inner .field-group{max-width:420px;}
}
@media(max-width:576px){
    .bi-popup-wrap .inner .head-wrap{row-gap:1px;}
    .bi-popup-wrap .inner .field-group{margin: 10px 0 0;}
}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/PhoneModal.css"],"names":[],"mappings":"AAAA,iCAAiC,sBAAsB,CAAC,uBAAuB,CAAC,QAAQ,CAAC;AACzF,kDAAkD,aAAa,CAAC,cAAc,CAAC;AAC/E,mCAAmC,gBAAgB,CAAC,UAAU,CAAC,iBAAiB,CAAC,eAAe,CAAC,kBAAkB,CAAC;AACpH,gDAAgD,WAAW,CAAC,UAAU,CAAC,yBAAyB,EAAE,aAAa,CAAC,uBAAuB,CAAC;AACxI,gDAAgD,aAAa,CAAC,iBAAiB,CAAC,QAAQ,CAAC,SAAS,CAAC,gBAAgB,CAAC,aAAa,CAAC,WAAW,CAAC;AAC9I,4BAA4B,aAAa,CAAC,YAAY,CAAC,gBAAgB,CAAC;AACxE,+BAA+B,WAAa,CAAC,oBAAoB,CAAC,UAAU,CAAC,kBAAkB,CAAC,WAAW,CAAC,iBAAiB,CAAC,cAAc,CAAC,kBAAkB,CAAC;;AAEhK;IACI,iDAAiD,QAAQ,CAAC;IAC1D,mCAAmC,eAAe,CAAC;AACvD;AACA;IACI,iCAAiC,WAAW,CAAC;IAC7C,mCAAmC,gBAAgB,CAAC;AACxD","sourcesContent":[".bi-popup-wrap .inner .head-wrap{flex-direction: column;align-items: flex-start;margin:0;}\r\n.bi-popup-wrap .inner .head-wrap .heading-600-20 {color:#211E24;margin:0 0 8px;}\r\n.bi-popup-wrap .inner .field-group{max-width: 600px;width:100%;padding-top: 15px;margin:15px 0 0;position: relative;}\r\n.bi-popup-wrap .inner .field-group .input-field{width: 100%;outline: 0;border: 1px solid #73509E; padding: 10px;background: transparent;}\r\n.bi-popup-wrap .inner .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #fff;color:#73509E;padding:5px;}\r\n.bi-popup-wrap .inner .text{color:#211E24;opacity: 0.8;text-align: left;}\r\n.bi-popup-wrap .inner .otp-btn{flex:54px 0 0;background:#73509E99;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}\r\n\r\n@media(max-width:767px){\r\n    .bi-popup-wrap .inner .head-wrap .heading-600-20{margin:0;}\r\n    .bi-popup-wrap .inner .field-group{max-width:420px;}\r\n}\r\n@media(max-width:576px){\r\n    .bi-popup-wrap .inner .head-wrap{row-gap:1px;}\r\n    .bi-popup-wrap .inner .field-group{margin: 10px 0 0;}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
