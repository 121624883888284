// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacypolicy-maindiv {
    margin-top: 2rem;
}

.accordion-header {
    width: 100% !important;
}

.accordion-header button {
    width: 100% !important;
    height: 4rem;
    border: none;
    background-color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
}

.privacypolicy-para {
    opacity: 60%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
    display: flex;
    margin: 0;
}

.privacypolicy-heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #211E24;
}

.listp {
    padding: 0;
    margin: 0;
}

.accordionborder {
    border-bottom: 1px solid rgb(210, 199, 199);
}

@media only screen and (max-width: 600px) {
    .accordion-header button {
        font-size: 14px !important;
        font-weight: 600;
        line-height: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Legal/PrivacyPolicy/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI;QACI,0BAA0B;QAC1B,gBAAgB;QAChB,iBAAiB;IACrB;AACJ","sourcesContent":[".privacypolicy-maindiv {\r\n    margin-top: 2rem;\r\n}\r\n\r\n.accordion-header {\r\n    width: 100% !important;\r\n}\r\n\r\n.accordion-header button {\r\n    width: 100% !important;\r\n    height: 4rem;\r\n    border: none;\r\n    background-color: #FFF;\r\n    font-family: Poppins;\r\n    font-size: 20px;\r\n    font-weight: 600;\r\n    line-height: 32px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    color: #211E24;\r\n}\r\n\r\n.privacypolicy-para {\r\n    opacity: 60%;\r\n    font-family: Poppins;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    line-height: 28px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    color: #211E24;\r\n    display: flex;\r\n    margin: 0;\r\n}\r\n\r\n.privacypolicy-heading {\r\n    font-family: Poppins;\r\n    font-size: 20px;\r\n    font-weight: 600;\r\n    line-height: 32px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    color: #211E24;\r\n}\r\n\r\n.listp {\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.accordionborder {\r\n    border-bottom: 1px solid rgb(210, 199, 199);\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    .accordion-header button {\r\n        font-size: 14px !important;\r\n        font-weight: 600;\r\n        line-height: 24px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
