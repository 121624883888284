import React, { useState ,useEffect} from 'react'
import Breadcrumbs from '../SubComponent/Breadcrumbs';
import SeventhPage from '../HomePage/SeventhPage/SeventhPage';
import Slider7 from '../SubComponent/AllSlider/Slider7';
import RightArrow from '../SubComponent/RightArrow';
import ImageWithHP from '../SubComponent/ImageWithHP';
import WishListBlock from '../SubComponent/AllBlock/WishListBlock';

import AccountFooter from './AccountFooter';
import { gql } from '@apollo/client';
import client from '../Services/ServicesPopup/apolloclient';
import {useNavigate } from 'react-router-dom';
import WishlistRatingBlock from '../SubComponent/AllBlock/WishlistRatingBlock';
import Loader from '../SubComponent/Loader';
const GET_WISHLIST_DATA=gql`
query userWishlists($userid:String!) {
    userWishlists(userId: $userid) {
      message
      code
      response
    }
  }
  
`
const Wishlist = () => {
    const [wishlistData,setWishListData]=useState();
    const navigate=useNavigate();
const productCategory=<WishListBlock/>
    const isSmallScreen=window.innerWidth<=576
    const breadcrumbsItems = [ { name: "Home Page", link: "/" }, { name: "My Account", link: "/myaccount" }];
    const boldtitle="Wishlists";
   const breakpoints={
        a:2.8,
        b:2.4,
        c:2.1,
        d:1.5,
        e:1.1
      };
    //   let containerData=wishlistData

    
      const containerData = [
        {
            title: "PRO Plan",
            price:'₹25,000',
            message: "Get a one time repair service if your machin has broken down",
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",

        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",
        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",
        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",
        },
        {
            title: "PRO Plan",
            price:'₹25,000',
            message: "Get a one time repair service if your machin has broken down",
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",

        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",
        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "/asset/machine-half.png",
        }, {
            title: "PRO Plan",
            price:'₹25,000',
            message: 'Get a one time repair service if your machin has broken down',
            discount: 25,
            productQuentity: 200,
            imageUrl: "asset/machine-half.png",
        }
    ];

    const userId=localStorage.getItem('id');
    useEffect(() => {
        const fetchWishListData=async()=>{
            const {data}=await client.mutate({
                mutation:GET_WISHLIST_DATA,
                variables:{
                    "userid":userId
                }
             })
        if(data){
            setWishListData(data.userWishlists.response)
            console.log("wishlistes Data12345==>>>",data.userWishlists.response);
        }
        }
     
        fetchWishListData();

    }, [userId])

    if (!wishlistData) {
        return <Loader/>;
      }
    const Wishlisted_Machines = wishlistData.find((wishlist) => wishlist.category === "MACHINE")?.items;
    const Wishlisted_Store=wishlistData.find((wishlist) => wishlist.category === "INSTORE")?.items;
    // console.log("Wishlisted_Store==>",Wishlisted_Store);
    

    const Wishlisted_Machines_Data=
   ( Wishlisted_Machines && Wishlisted_Machines?.length>0) ?  Wishlisted_Machines.map((productData)=>{ return{
            productid:productData.product_details.productid,
            imageurl: productData.product_details.image,
      title: productData.product_details.product_name,
      para: productData?.product_details?.brand,
      time: productData?.product_details?.year,
      location:productData?.product_details?.location,
      price: `${productData.product_details.price}`,
      status:productData.status,
      quantity:productData.quantity,
      isWishlistBlock:true
        }
    }):[];

    const Wishlisted_Machines_Store=(Wishlisted_Store && Wishlisted_Store?.length>0) ? 
    Wishlisted_Store.map((productData)=>{
        return{
            productid:productData.product_details.productid,
            imageurl: productData.product_details.image,
      title: productData.product_details.product_name,
      para: "",
      time: productData?.product_details?.year,
      loction:productData?.product_details?.location,
      price: `₹${productData.product_details.price}`,
      status:productData.status,
      quantity:productData.quantity
        }
    }):[];
    // console.log("Wishlisted_Machines_data===>>>",Wishlisted_Machines_Data);
    const WishlistedMachines = { heading: `Wishlisted Machines (${Wishlisted_Machines_Data.length})`, message: 'From Machines to tools to finance everything you need in one place', buttonName: "View All", navi:`${Wishlisted_Machines_Data.length>0 ? "/wishlisted-machines":"/buy/product-listing"}`}
    
    const WishlistedStore= { heading: `Wishlisted Products in Store(${Wishlisted_Machines_Store.length})`, message: 'From Machines to tools to finance everything you need in one place', buttonName: "View All", navi:`${Wishlisted_Machines_Store.length>0 ? "/wishlist2":"/buy/product-listing"}`}
    
   const storeBlock=<WishlistRatingBlock/>
    return (
        <>

        <div className='max-container pt-3'>
        <Breadcrumbs items={breadcrumbsItems} boldtitle={boldtitle} backnavi={()=>navigate('/myaccount')}/>
        </div>
 
    <SeventhPage topsectionData={WishlistedMachines} sliderData={Wishlisted_Machines_Data}/>
    
    <div className='container-fluid pt-5'>
    <div className='max-container'>
    <div className='row pb-5'>
          <div className='col col-lg-8 col-12 p-0'>
          <ImageWithHP imageurl={""} heading={"Wishlisted AMC Plans (20)"} para={"Enjoy the flexibility of selecting from our diverse range of plans"}/>
          </div>
        {<div className={`col col-lg-4 d-flex align-self-center ${isSmallScreen ? "justify-content-center":"justify-content-end"}`}>
        <p className='heading-600-14-12 v-center'>View all </p>
        <div className='arrow-div ml-2'>
        <RightArrow callFun={()=>navigate('/wishlist3')}/>
        </div> 
          </div>}
         
            </div>
       <Slider7 breakpoints={breakpoints} hide={"show-992"} productCategory={productCategory} listofdata={containerData}/>
       
    </div>
    </div>
    
    <SeventhPage topsectionData={WishlistedStore} sliderData={Wishlisted_Machines_Store} productCategory={storeBlock}/>

    <AccountFooter/>
    </>
  )
}

export default Wishlist