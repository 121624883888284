import React ,{useEffect}from 'react'
import NetworkFirstSection from './NetworkFirstSection'
import NetworkThirdSection from './NetworkThirdSection'
import Footer from '../Footer/Footer'
import NetworkFifthSection from './NetworkFifthSection'
import NetworkSixthSection from './NetworkSixthSection'
import NetworkSecondsection from './NetworkSecondsection'
import NetworkFourthSection from './NetworkFourthSection'

const OrigaNetwork = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
    <NetworkFirstSection/>
    <NetworkSecondsection title={"Blogs"}/>
    <NetworkThirdSection/>
  <NetworkFourthSection/>
    <NetworkFifthSection/>
    <NetworkSixthSection/>
    <div className='max-container'>
    <div className='text-end p-r' style={{top:"44px"}}>
    <img src='/asset/Frame1000004018.png' alt='Frame1000004018.png'/>
    </div>
    </div>
    <Footer/>
    </>
    
  )
}

export default OrigaNetwork