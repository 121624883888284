import React,{ useState } from 'react'
import LeftArrow from '../../SubComponent/LeftArrow'
import { useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../SubComponent/Breadcrumbs'
const BlogFirstSection = () => {

    const navigate=useNavigate();
    const heading="Blogs"
    const breadcrumbsItems = [{ name: "Origa Network", link: "/origanetwork" }];
    const sectionData = {
        headingLeft: " ",
        paraLeft: "Connect with genuine buyers for a hassle-free selling experience",
        headingRight: "",
        paraRight: "",
        banner: "/asset/image555a.png",
    }
    return (

       <div className="container-fluid liner-background-anual">
       <div className="max-container ">

           <div className='pt-5'>
           <Breadcrumbs backnavi={()=>navigate('/origanetwork')} boldtitle={heading} items={breadcrumbsItems}/>
               <div className='container-fluid p-0 m-0 row pb-5'>
                   <div className="col col-lg-7 col-12 p-0 blog-top-padding">
                       <div className="row d-flex flex-column pt-3">
                       <div className='col col-12'><span className='heading-500-14-10 op-60 blog-healthcare'>Healthcare</span> </div>
                           <div className="col col-lg-9 pt-4 pr-5">
                           
                               <p className="heading-600-40-20 c-green pt-2">The impact of financing <br/>( leasing/Loaning) on changing Healthcare?</p>
                           </div>
                           <div className="col col-lg-7 pt-4 ">
                           <div className='d-flex align-items-center'>
                           <div><img src='/asset/userpic.png'/></div><p className='heading-500-12 op-60 m-0 pl-2'>By Shrirang Tambe</p><p className='m-0 heading-400-12-10 op-60 pl-2'>28th Oct, 2015 </p>
                           </div>
                           </div>
                       </div>
                   </div>
                   <div className="col col-lg-5 col-12 p-0 d-flex align-items-center">
                       <div className="row text-end justify-content-end">
                           <div className='col col-12 '>
                               <div className='banner-image-div-blog'>
                                   <img className='banner-image' src={sectionData.banner} alt={sectionData.banner} />
                                  
                               </div>
                           </div>
                          
                          
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>

  )
}

export default BlogFirstSection