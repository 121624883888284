import React,{useEffect, useState} from 'react'
import './multirange.css'
import MultiRangeSlider from "multi-range-slider-react";
import { max } from 'moment';
const MultiRangeSliderComponent = () => {
    const [minValue, set_minValue] = useState(10000);
const [maxValue, set_maxValue] = useState(40000);
const [inputValue,setInputValue]=useState({inputMin:10000,inputMax:40000})
const handleInput = (e) => {
	set_minValue(e.minValue);
	set_maxValue(e.maxValue);
};
const onMinValue=(e)=>{
	if(e.key==="Enter")
	if(e.target.value<maxValue){
		set_minValue(e.target.value)
	}else{
		set_minValue(e.target.value-1)
	}
	
}
const onMaxValue=(e)=>{
	
	if(e.key==="Enter"){
		if(inputValue.inputMin<e.target.value){
			set_maxValue(e.target.value)
		}else{
			set_maxValue(minValue+1)
		}
		console.log("max-value--->>>",e);
	}
	
	

}

// console.log("min value->>",minValue);

useEffect(()=>{
	setInputValue((prev)=>({...prev,inputMin:minValue,inputMax:maxValue}))
},[minValue,maxValue])
  return (
    <div className='multi-range'>
    <MultiRangeSlider
			min={0}
			max={50000}
			step={5}
			barInnerColor="#9B9E51"
			barRightColor="#D9D9D9"
			barLeftColor="#D9D9D9"
			minValue={minValue}
			maxValue={maxValue}
			onInput={(e) => {
				handleInput(e);
			}}
		/>
        <div className='price-container'>
        <div className='price-box'> <div className='price-title'>Min. ₹</div><input className='price-input' value={inputValue.inputMin} onChange={(e)=>setInputValue((prev)=>({...prev,inputMin:e.target.value}))} onKeyDown={onMinValue}/></div>
        <div className='price-box'><div className='price-title'>Max. ₹</div><input className='price-input' style={{paddingLeft:"37px"}} value={inputValue.inputMax} onChange={(e)=>setInputValue((prev)=>({...prev,inputMax:e.target.value}))}  onKeyDown={onMaxValue}/></div>
        </div>
    </div>
  )
}

export default MultiRangeSliderComponent