// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchBox.css */

.search1-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .search1-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 60%;
  }
  .search1-icon-left{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .search1-box input {
    padding: 10px 0px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .search1-icon {
      left: 5px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/SubComponent/Search/Search1.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;EAC7B;EACA;IACE,2BAA2B;IAC3B,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE;MACE,SAAS;IACX;EACF","sourcesContent":["/* SearchBox.css */\r\n\r\n.search1-box {\r\n    position: relative;\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n  }\r\n  \r\n  .search1-icon {\r\n    position: absolute;\r\n    left: 10px;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    opacity: 60%;\r\n  }\r\n  .search1-icon-left{\r\n    position: absolute;\r\n    right: 10px;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n  }\r\n  .search1-box input {\r\n    padding: 10px 0px 10px 40px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  @media (max-width: 600px) {\r\n    .search1-icon {\r\n      left: 5px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
