// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LookingForBuySellLeft-maindiv{
    background-color:white;
    /* padding: 0; */
    margin: 3rem 0rem 0rem 0rem ;
}
.LookingForBuySellLeft-headingdiv{
    width: 25.5rem;
    height: 2rem;
    display: flex;
    gap: 1rem;
}
.LookingForBuySellLeft-navbar {
    /* width: 9.1875rem; */
    height: 2rem;
    text-decoration: none;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0px;
text-align: left;
color: #211E24;
opacity: 60%;
}
.LookingForBuySellLeft-navbar:hover{
    text-decoration: none;
    color: #211E24;
}
.LookingForBuySellLeft-navbar.active{
font-size: 1.25rem;
font-weight: 600;
opacity: 100%;
border-bottom: 3px solid #9BA24C;
}
`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/LokkingBuySell/LookingForBuySellLeft/LookingForBuySellLeft.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,4BAA4B;AAChC;AACA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,SAAS;AACb;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,qBAAqB;AACzB,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;AACd,YAAY;AACZ;AACA;IACI,qBAAqB;IACrB,cAAc;AAClB;AACA;AACA,kBAAkB;AAClB,gBAAgB;AAChB,aAAa;AACb,gCAAgC;AAChC","sourcesContent":[".LookingForBuySellLeft-maindiv{\r\n    background-color:white;\r\n    /* padding: 0; */\r\n    margin: 3rem 0rem 0rem 0rem ;\r\n}\r\n.LookingForBuySellLeft-headingdiv{\r\n    width: 25.5rem;\r\n    height: 2rem;\r\n    display: flex;\r\n    gap: 1rem;\r\n}\r\n.LookingForBuySellLeft-navbar {\r\n    /* width: 9.1875rem; */\r\n    height: 2rem;\r\n    text-decoration: none;\r\nfont-size: 1rem;\r\nfont-weight: 400;\r\nline-height: 1.5rem;\r\nletter-spacing: 0px;\r\ntext-align: left;\r\ncolor: #211E24;\r\nopacity: 60%;\r\n}\r\n.LookingForBuySellLeft-navbar:hover{\r\n    text-decoration: none;\r\n    color: #211E24;\r\n}\r\n.LookingForBuySellLeft-navbar.active{\r\nfont-size: 1.25rem;\r\nfont-weight: 600;\r\nopacity: 100%;\r\nborder-bottom: 3px solid #9BA24C;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
