// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .heading-600-20{margin: 0 0 10px;}
.bi-popup-wrap .inner .heading-400-14-12{opacity:.8;}
.bi-popup-wrap .inner .field-group{max-width: 600px;width:100%;padding-top: 15px;margin:15px 0 0;position: relative;}
.bi-popup-wrap .inner .field-group .input-field{width: 100%;outline: 0;border: 1px solid #AAA; padding: 10px;background: transparent;}
.bi-popup-wrap .inner .field-group .calendar-icon{position: absolute;right:4px;top:25px;background: #fff;}
.bi-popup-wrap .inner .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #fff;color:#211E24;padding:5px;opacity: 1;}
.bi-popup-wrap .inner .field-group .selected-date{display: flex;flex-wrap: wrap;}
.bi-popup-wrap .inner .field-group .date{display:flex;flex-wrap:wrap;}
.bi-popup-wrap .inner .schedule-btn{flex:54px 0;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}
@media(max-width:767px){
    .bi-popup-wrap .inner .schedule-btn{flex:50px 0;padding:0 30px;}
}
@media(max-width:576px){
    .bi-popup-wrap .inner .schedule-btn{flex:45px 0;padding:0 30px;}
}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/DateModal.css"],"names":[],"mappings":"AAAA,sCAAsC,gBAAgB,CAAC;AACvD,yCAAyC,UAAU,CAAC;AACpD,mCAAmC,gBAAgB,CAAC,UAAU,CAAC,iBAAiB,CAAC,eAAe,CAAC,kBAAkB,CAAC;AACpH,gDAAgD,WAAW,CAAC,UAAU,CAAC,sBAAsB,EAAE,aAAa,CAAC,uBAAuB,CAAC;AACrI,kDAAkD,kBAAkB,CAAC,SAAS,CAAC,QAAQ,CAAC,gBAAgB,CAAC;AACzG,gDAAgD,aAAa,CAAC,iBAAiB,CAAC,QAAQ,CAAC,SAAS,CAAC,gBAAgB,CAAC,aAAa,CAAC,WAAW,CAAC,UAAU,CAAC;AACzJ,kDAAkD,aAAa,CAAC,eAAe,CAAC;AAChF,yCAAyC,YAAY,CAAC,cAAc,CAAC;AACrE,oCAAoC,WAAa,CAAC,kBAAkB,CAAC,UAAU,CAAC,kBAAkB,CAAC,WAAW,CAAC,iBAAiB,CAAC,cAAc,CAAC,kBAAkB,CAAC;AACnK;IACI,oCAAoC,WAAa,CAAC,cAAc,CAAC;AACrE;AACA;IACI,oCAAoC,WAAa,CAAC,cAAc,CAAC;AACrE","sourcesContent":[".bi-popup-wrap .inner .heading-600-20{margin: 0 0 10px;}\r\n.bi-popup-wrap .inner .heading-400-14-12{opacity:.8;}\r\n.bi-popup-wrap .inner .field-group{max-width: 600px;width:100%;padding-top: 15px;margin:15px 0 0;position: relative;}\r\n.bi-popup-wrap .inner .field-group .input-field{width: 100%;outline: 0;border: 1px solid #AAA; padding: 10px;background: transparent;}\r\n.bi-popup-wrap .inner .field-group .calendar-icon{position: absolute;right:4px;top:25px;background: #fff;}\r\n.bi-popup-wrap .inner .field-group .input-label{display:block;position:absolute;top:15px;left:10px;background: #fff;color:#211E24;padding:5px;opacity: 1;}\r\n.bi-popup-wrap .inner .field-group .selected-date{display: flex;flex-wrap: wrap;}\r\n.bi-popup-wrap .inner .field-group .date{display:flex;flex-wrap:wrap;}\r\n.bi-popup-wrap .inner .schedule-btn{flex:54px 0 0;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}\r\n@media(max-width:767px){\r\n    .bi-popup-wrap .inner .schedule-btn{flex:50px 0 0;padding:0 30px;}\r\n}\r\n@media(max-width:576px){\r\n    .bi-popup-wrap .inner .schedule-btn{flex:45px 0 0;padding:0 30px;}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
