import React,{useState} from "react";
import {closeIcon} from "../../helpers/Icons";
// import "./UserModal.css";


const FinanceModel = ({modalAction,interestRate}) => {
    const [form, setForm] = useState({name: "",email: "",});      
    const [validation,setvalidaion]=useState({ename:false,evalidation:false});

    const onChangeHandelr=(e)=>{
const {name,value}=e.target;
if(name==="name"){

        const newInputString = e.target.value;
        const sanitizedInput = newInputString.replace(/[^a-zA-Z\s]/g,"");
    
        // Update the state only if the input is empty or contains valid characters
        if (newInputString === '' || sanitizedInput === newInputString) {
            setForm((prev)=>({...prev,[name]:value}))
        }
      
}else{

      
  const newInputString = value;
  const sanitizedInput = newInputString.replace(/[^0-9]/g, '');

  // Update the state only if the input is empty or contains valid characters
  if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 10) {
    setForm((prev)=>({...prev,[name]:sanitizedInput}))  
 
}
}

console.log("check name and email-->",name ,value)
    }
   
        
    const onSaveHandler=()=>{

        if(form.name==="" || form.email==="" || form.email.length!==10){
           if(form.name==="" ){setvalidaion((prev)=>({...prev,ename:true}));}
            if(form.email===""|| form.email.length!==10){setvalidaion((prev)=>({...prev,evalidation:true}));}
            
        return}

        modalAction(false)
        interestRate()
    }
    

    return (
        <div className="bi-popup-wrap">
            <div className="back" onClick={() => modalAction(false)}></div>
            <div className="inner f-popup">
                <button onClick={() => modalAction(false)} className="close">{closeIcon({width:16,height:16})}</button>
                <div className="head-wrap">
                    <div className="heading-600-20-16">Point of contact details</div>
                    <div className="heading-400-14-12 text">Provide your details to access the interest rate</div>
                </div>
                <div className={`field-group-wrap`}>
                    <div className={`bi-form-group  ${validation.ename ? "error-red":""}`}>
                        <input type="text" name="name" id="name" className={`bi-form-field heading-600-14-12  ${validation.ename ? "error-red":""}`} placeholder="Enter First Name" autoComplete='off' onChange={onChangeHandelr} value={form.name} onClick={()=>setvalidaion((prev)=>({...prev,ename:false}))}/>
                        <label htmlFor="name" className={`bi-form-label light-txt  ${validation.ename ? "error-red":""}`}>Name</label>
                    </div>
                    
                </div>
               
               
                <div className={`bi-form-group `}>
                    <input type="text" name="email" id="email" className={`bi-form-field heading-600-14-12 ${validation.evalidation ? "error-red":""}`} placeholder="Enter Email Address" autoComplete='off' onChange={onChangeHandelr} value={form.email} onClick={()=>setvalidaion((prev)=>({...prev,evalidation:false}))}/>
                    <label htmlFor="email" className={`bi-form-label light-txt ${validation.evalidation ? "error-red":""}`}>Mobile Number</label>
                </div>
                <div className="d-flex justify-content-between w-100 bi-form-group">
                <button type="button" className="button-outline heading-600-16" onClick={() => modalAction(false)}>Cancel</button>
                <button type="button" className="button heading-600-16" onClick={onSaveHandler}>Save</button>
                </div>
            </div>
        </div>
    );
}
export default FinanceModel