// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-group-wrap{display:flex;align-items:center;column-gap:20px;width:100%;}
@media(max-width:767px){
    .field-group-wrap{flex-direction:column;}
}`, "",{"version":3,"sources":["webpack://./src/component/Selling/Modals/UserModal.css"],"names":[],"mappings":"AAAA,kBAAkB,YAAY,CAAC,kBAAkB,CAAC,eAAe,CAAC,UAAU,CAAC;AAC7E;IACI,kBAAkB,qBAAqB,CAAC;AAC5C","sourcesContent":[".field-group-wrap{display:flex;align-items:center;column-gap:20px;width:100%;}\r\n@media(max-width:767px){\r\n    .field-group-wrap{flex-direction:column;}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
