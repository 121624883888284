import React,{useState} from 'react'

const NewsBlock = ({product,index}) => {
    const [isHovered, setIsHovered] = useState(false);
    const onMouseEnterhandler = () => {
      setIsHovered(true);
    };
    const onMouseLeavehandler = () => {
      setIsHovered(false);
    };
  return (
    <div className={`h-320 ishoverd ${isHovered ? "hovered":""}`} key={index} onMouseEnter={onMouseEnterhandler} onMouseLeave={onMouseLeavehandler}>
    <div>
    <img className='w-100' src={product.imageurl} alt={product.imageurl}/></div>
    <div className='p-2'>
    <p className={`heading-600-16 ${isHovered ? "bottom-halfline1":"bottom-halfline"}`}>{product.title}</p>  
    <p className={`heading-400-14-12`}>{product.message}</p>
    <div className='row d-flex justify-content-between'>
    <div className='col col-4'>{product.date}</div>
    <div className='col col-4'>Read more</div>
    </div>
    </div>

    
    </div>
  )
}

export default NewsBlock