import React,{useState} from 'react'

const CategoryPopup = ({onHide}) => {
  const [count,setCount]=useState(5)
  const items=[{name:"Alcon"},{name:"AMO"},{name:"Appasamy"},{name:"Ace Designer LTD"},{name:"BFW"},{name:"Alcon"},{name:"AMO"},{name:"Appasamy"},{name:"Ace Designer LTD"},{name:"BFW"}]
  
  const onShowMore=()=>{
setCount(items.length)
  }
  const onLessMore=()=>{
    setCount(5)
  }
  return (
    <>


    <div className='p-fixed w-100 h-100 bg-white'>
<div className='p-r bg-white h-100 w-100'>
<div className="row bg-white h-100 w-100">
    <div className="col col-md-4 col-5 bg-white border h-100 padding-right-0">
<div className='category-pop-btn'><p>Category</p></div>
<div className='category-pop-btn'><p>Brand</p></div>
<div className='category-pop-btn'><p>Sub Category</p></div>
<div className='category-pop-btn'><p>Price</p></div>


    </div>
    <div className="col col-md-8 col-7 h-100 pt-4">
    <div className='filter-wrap d-flex p-0' style={{boxShadow:"none"}}>
    <div className="filter">
        <div className="filter-body">
          <div className="checkbox-wrap">
            {items.slice(0,count).map((item,index) => (
              <div className="checkbox-item justify-content-start gap-2" key={index} >
              <input type="checkbox" name={item.name}   />
                <label className="heading-400-16">{item.name}</label>
               
              </div>
            ))}
          </div>
        </div>
        <p className='heading-600-16 text-end pt-3' onClick={count==5 ? onShowMore:onLessMore}>{count===5 ? "More Options":"Less Options"}</p>
    </div>
  
    </div>
    </div>
</div>

</div>

    </div>
    <div className='p-f bg-white shadow1 w-100 fixed-category-bottom'>
    <div className='container-fluid h-100'>
    <p className='heading-600-14-12 m-0 curser-pointer' onClick={()=>onHide()}>Reset</p>
      <button className='button' onClick={()=>onHide()}>Show Results</button>
    </div>
</div>
    </>
  )
}

export default CategoryPopup