import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
// import Button from '../Button/Button'
import './product.css'
import './success.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import VectorBlock from '../Annual/Vector/VectorBlock';
import ProductBlock from '../Annual/Block/ProductBlock';
import Footer from '../Footer/Footer';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ButtonOutline from '../Button/ButtonOutline';
import LoginModel from '../Authentication/LoginModel/LoginModel';
import { secondClient, CreateAmc, ServiceArea } from './mutations';
import Form from 'react-bootstrap/Form';
import Heart from '../SubComponent/AllSvgs/Heart';
const priceConvert = (price) => {
    price = typeof price === 'string' ? price : String(price);
  

        let count=1;
        let comma=3;
         let formatedPrice=""
         for(let i=price.length-1;i>=0;i--){
             formatedPrice=price[i]+formatedPrice
             if(count===comma){
                  formatedPrice=","+formatedPrice
                 comma=2;
                 count=0;
             }count++;
          
         }
         console.log("==>>",formatedPrice)
            if(formatedPrice[0]===","){
                formatedPrice =formatedPrice.slice(1, formatedPrice.length)
             }
             return formatedPrice;
     

   
};
const Index = () => {
    const [SuccessPopUp, setSuccessPopUp] = useState(false);
    const [PaymentLink, setPaymentLink] = useState([]);
    const location = useLocation();
    const serviceRequest = location.state?.productId;
    const serviceName = location.state?.serviceName;
    const [loginPortal, setLoginPortal] = useState(false);
    const [machineData, setMachineData] = useState();
    const [serviceType, setserviceType] = useState();
    const token = localStorage.getItem('userToken');
    const machineType = localStorage.getItem('MachineType');
    const Model = localStorage.getItem('Model');
    const machinenumber = localStorage.getItem('machinenumber');
    const mobileNo = localStorage.getItem('phoneNumber');
    const [box, setBox] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const sliderRef = useRef(null);
    const [selectedCategoryType, setSelectedCategoryType] = useState('');
    const [enteredPincode, setEnteredPincode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [heartColor,setHeartColor]=useState(false)
    //const [BuyingProcess, setBuyingProcess] = useState(false);
    const containerData = []

    //-------------------------------------------------Pin Code Availability For Payment Process----------------------------------------------------------
    const onCategoryTypeChange = (event) => {
        setErrorMessage("")
        setSelectedCategoryType(event.target.value);
    };

    const onPincodeChange = (event) => {
        setErrorMessage("")
        setEnteredPincode(event.target.value);
    };



    // ----------------------------------------------------Fetching Product Details--------------------------------------------------------------------------
    const fetchProductDetails = async () => {
        console.log('serviceRequest--->', serviceRequest);
        if(serviceRequest !== undefined){
            if (serviceRequest === 'serviceRequest') {
                const machineTypePayload = JSON.stringify({type : "VMC"});
                // const machineTypePayload = JSON.stringify({});
                const response = await fetch('https://contacts.origaleasing.com/basedonTypefetchOncallServiceDetails', {
                // const response = await fetch('https://contacts.origaleasing.com/fetchOncallServiceDetails', {
                    method: 'POST',
                    body: machineTypePayload,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseData = await response.json();
                setMachineData(responseData);
                setserviceType(serviceName)
    
            }
            else {
                const machineTypePayload = JSON.stringify({ plan_id: serviceRequest });
                const response = await fetch('https://contacts.origaleasing.com/fetchAMCPlan', {
                    method: 'POST',
                    body: machineTypePayload,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseData = await response.json();
                console.log('fetchAMCPlan');
                setMachineData(responseData);
                setserviceType("AMC")
    
            }

        }
        


    };

    useEffect(() => {
        //Scroll To Top of the page
        window.scrollTo(0, 0);
        fetchProductDetails();
    }, []);

    console.log('machineData', machineData);
    const Single_Product = machineData?.jsondata[0]
    const AmcPlanId = machineData?.jsondata[0]?.id
    const Description = machineData?.jsondata[0]?.description;
    const formattedDescription = Description && Description.includes('["') && Description.includes('"]')
        ? Description.slice(2, -2)
        : Description || '';

    // ----------------------------------------------------Payment Process----------------------------------------------------------------------------------

    const PaymentProcess = async () => {
        if (serviceRequest === "serviceRequest") {
            if (selectedCategoryType === "") {
                setErrorMessage("Please Select Category Type")
            }
            else if (enteredPincode !== '') {
                try {
                    const { data: IsServiceAvailable } = await secondClient.query({
                        query: ServiceArea,
                        variables: {
                            pincode: parseInt(enteredPincode)
                        },
                        fetchPolicy: 'network-only',
                    });
                    const availabilityCode = IsServiceAvailable?.nearestServicearea?.code;
                    const message = IsServiceAvailable?.nearestServicearea?.message;
                    if (availabilityCode === 1000) {
                        setErrorMessage("")
                        if (token === null) {
                            setLoginPortal(true);
                        }
                        else {
                            try {
                                const Userid = localStorage.getItem('id');
                                const { data: AddTOCart } = await secondClient.mutate({
                                    mutation: CreateAmc,
                                    variables: {
                                        amcPlanData: {
                                            //mobileNo:mobileNo,
                                            appAmcPlanId: AmcPlanId,
                                            customerId: Userid,
                                            //customerId : "VXTD=",
                                            stage: "ADD_TO_CART",
                                            serviceType: serviceType
                                        }
                                    }
                                    ,
                                });
                                console.log('AddTOCart----->', AddTOCart?.createAmc?.success);
                                const Success_Status = AddTOCart?.createAmc?.success
                                if (Success_Status) {
                                    const { data: updateUser } = await secondClient.mutate({
                                        mutation: CreateAmc,
                                        variables: {
                                            amcPlanData: {
                                                //mobileNo:mobileNo,
                                                appAmcPlanId: AmcPlanId,
                                                customerId: Userid,
                                                //customerId: "VXTD=",
                                                stage: "PAYMENT",
                                                serviceType: serviceType
                                            }
                                        }
                                        ,
                                    });
                                    console.log('updateUser---->', updateUser?.createAmc?.paymenturl);
                                    const paymentURL = updateUser?.createAmc?.paymenturl
                                    setSuccessPopUp(true)
                                    setPaymentLink(paymentURL)

                                    document.getElementById('paymentButton').style.display = '';
                                    document.getElementById('buyButton').style.display = 'none';
                                    document.getElementById('paymentButton').href = paymentURL
                                }

                            }
                            catch (error) {
                                console.log('error while CreateAmc------->', error);
                            }
                        }

                    }
                    else {
                        //setBuyingProcess(true)
                        setErrorMessage(message)
                    }

                }
                catch (error) {
                    console.log('ServiceArea----Error->', error);
                    setErrorMessage("We are not serving in this area.");
                }

            }
            else {
                setErrorMessage("Please enter a 6-digit valid PIN code");
            }

        }
        //----------------------------------------AMC Type--------------------
        else {
            if (token === null) {
                setLoginPortal(true);
            }
            else {
                try {
                    const Userid = localStorage.getItem('id');

                    const { data: AddTOCart } = await secondClient.mutate({
                        mutation: CreateAmc,
                        variables: {
                            amcPlanData: {
                                //mobileNo:mobileNo,
                                appAmcPlanId: AmcPlanId,
                                customerId: Userid,
                                //customerId : "VXTD=",
                                stage: "ADD_TO_CART",
                                serviceType: serviceType
                            }
                        }
                        ,
                    });
                    console.log('AddTOCart----->', AddTOCart?.createAmc?.success);
                    const Success_Status = AddTOCart?.createAmc?.success
                    if (Success_Status) {
                        const { data: updateUser } = await secondClient.mutate({
                            mutation: CreateAmc,
                            variables: {
                                amcPlanData: {
                                    //mobileNo:mobileNo,
                                    appAmcPlanId: AmcPlanId,
                                    customerId: Userid,
                                    //customerId: "VXTD=",
                                    stage: "PAYMENT",
                                    serviceType: serviceType
                                }
                            }
                            ,
                        });
                        console.log('updateUser---->', updateUser?.createAmc?.paymenturl);
                        const paymentURL = updateUser?.createAmc?.paymenturl
                        setSuccessPopUp(true)
                        setPaymentLink(paymentURL)

                        document.getElementById('paymentButton').style.display = '';
                        document.getElementById('buyButton').style.display = 'none';
                        document.getElementById('paymentButton').href = paymentURL
                    }

                }
                catch (error) {
                    console.log('error while CreateAmc------->', error);
                }

            }
        }


    }

    // Login Process
    const onHidePortal = () => {
        setLoginPortal(false);
    }

    useEffect(() => {

        setBox(sliderRef.current);
    }, [])



    const renderScopes = () => {

        const scopeData = machineData?.jsondata[0]?.scope_of_work;

        if (scopeData) {
            const scopeDataStr = (machineData?.jsondata[0].scope_of_work);
            const scopeDataNewStr = scopeDataStr.replace(/&amp;/gi, '&');
            const scopeDataNew = JSON.parse(scopeDataNewStr);
            const visibleParagraphs = showMore ? scopeDataNew : scopeDataNew.slice(0, 4);
            const toggleShowMore = () => {
                setShowMore(!showMore);
            };

            return (
                <div>
                    {visibleParagraphs.map((paragraph, index) => (
                        // <p key={index} className='scopes_list'><img src="asset/program-img.svg" className='icon__img' alt="icon" />{paragraph}</p>

                        <div key={index} className='scopes_list' style={{'display': 'flex'}}>

                            <div><img src="/asset/program-img.svg" className='icon__img' alt="icon" /></div>
                            <div><span>{paragraph}</span></div>
                        </div>

                    ))}
                    <br></br>
                    {scopeDataNew.length > 4 && (
                        <Button variant="primary" onClick={toggleShowMore}>
                            {showMore ? 'Show Less' : 'Show More'}
                        </Button>
                    )}
                </div>
            );
        }
    };

    const renderKeyFeatures = () => {

        const keyFeaturesBD = machineData?.jsondata[0]?.free_bd;
        const keyFeaturesPM = machineData?.jsondata[0]?.free_pm;
        const total_visit = parseInt(keyFeaturesBD) + parseInt(keyFeaturesPM)
        if (keyFeaturesBD && keyFeaturesPM) {
            return (
                <div >
                    <p className='feature_list'>Total Visits  : {total_visit}</p>
                    <p className='feature_list'>Break Downs  : {keyFeaturesBD}</p>
                    <p className='feature_list'>Preventive Measures  : {keyFeaturesPM}</p>
                </div>
            );
        }
    };


    const renderGstValue = () => {

        const gstPercent = machineData?.jsondata[0]?.gst_percent;
        const price = machineData?.jsondata[0]?.price;
        const totalGSTValue = machineData?.jsondata[0]?.gst_amount;
        const totalValue = machineData?.jsondata[0]?.total_amount;
        // const totalGSTValue = (parseFloat(gstPercent) * parseFloat(price)) / 100;
        // const totalValue = (parseFloat(totalGSTValue) + parseFloat(price));

        if (gstPercent && price) {
            return (
                <div >
                    <p className='scopes_list'>Plan Amount : <span className='price'>₹{price} </span> </p>
                    <p className='scopes_list'>GST  (18%) : <span className='price'>₹{totalGSTValue} </span> </p>
                    <p className='scopes_list'>Total Amount: <span className='price'>₹{totalValue} </span></p>
                </div>
            );
        }
    };


    const renderHeader = () => {

        const keyPlan = machineData?.jsondata[0]?.plan;
        const dataFromLocalStorageMachineType = localStorage.getItem('MachineType');
        const dataFromLocalStorageModel = localStorage.getItem('Model');
        if (serviceName !== undefined) {
            return (
                <div>
                    <h1 className='cart-heading'>{serviceName}</h1>
                </div>
            );
        }

        if (keyPlan) {
            return (
                <div>
                    <h1 className='cart-heading'>{keyPlan} Annual maintenance contract (AMC) plan for  {dataFromLocalStorageModel}  {dataFromLocalStorageMachineType} Machine</h1>
                </div>
            );
        }
    };


    return (
        <>
            <section className='lyt-section lyt-section-for-alert'>
                {SuccessPopUp ? (
                    <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
                        <div className='App'>
                            <div className='success-login'>
                                <div className='contents'>
                                    <h3 className='heading-600-28'>Confirm Payment</h3>
                                    <p className='heading-400-16 op-60'>Proceed to the payment</p>
                                    <div className="d-flex justify-content-between mt-3">
                                        <a href={PaymentLink} rel="noreferrer" >
                                            <Button variant="success" className="me-2">
                                                Pay Now
                                            </Button>
                                        </a>
                                        <Button variant="danger" style={{ marginLeft: '8px' }} onClick={() => setSuccessPopUp(false)}>
                                            Cancel
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Container>
                ) : null}
                <Container>
                    <div className='heading-box'>
                        {renderHeader()}
                       <div className='pr-1' onClick={()=>setHeartColor(!heartColor)}><Heart  fill={heartColor ? "#73509E" : ""}/></div>
                    </div>
                    <div className='product-swiper'>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}
                            className="productSwiper">
                            <SwiperSlide>
                                <div className='img-box'>
                                    <img src="/asset/machine-full.png" className='img-fluid' alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='img-box'>
                                    <img src="/asset/machine-full.png" className='img-fluid' alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='img-box'>
                                    <img src="/asset/machine-full.png" className='img-fluid' alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='img-box'>
                                    <img src="/asset/machine-full.png" className='img-fluid' alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='cart-lyt-box'>
                        <div className='top-cart-box'>
                            <div className='first__row__box'>
                                <div className='typ-custom-width'>
                                    <p className='plan-name'>The Plan Price&nbsp;(Excluding GST)</p>
                                    <p className='price'>₹{priceConvert(Single_Product?.price)}</p>
                                    
                                </div>
                            </div>
                            <div className='first__row__box'>
                                <div className='typ-addBtn'>
                                    <p className='scopes_list'>Plan Amount: <span className='price'>₹{priceConvert(Single_Product?.price)}</span></p>
                                    <p className='scopes_list'>GST (18%): <span className='price'>₹{priceConvert(Single_Product?.gst_amount)}</span></p>
                                    <p className='scopes_list'>Total Amount: <span className='price'>₹{priceConvert(Single_Product?.total_amount)}</span></p>
                                </div>
                            </div>


                            <div className="col-lg-3">
                                <div>
                                    {serviceRequest === "serviceRequest" ? (
                                        <div>
                                            <div className={`bi-form-group mb-3`}>
                                          
                                          <select className={`bi-form-field  bi-select-dropdown r-4`}   placeholder="Select Categories Types *"   onChange={onCategoryTypeChange} autoCapitalize='off' >
                                          <option  value="" className='display-none'></option> 
                                          <option value="HMC">HMC</option>
                                                <option value="VMC">VMC</option>
                                                <option value="CNC LATHE">CNC LATHE</option>
                                          
                                      </select>
                                          <label className="heading-400-14-12 bi-form-label bg-white c-drop-color">Select Categories Types *</label>
                                        
                                          </div>
                                          
                                            <div className='location_icon '>
                                                <Form.Control
                                                    className='form__control'
                                                    type="text"
                                                    id="inputText"
                                                    aria-describedby="passwordHelpBlock"
                                                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                                    onChange={onPincodeChange}
                                                    maxLength={6}
                                                    placeholder='Pincode *'
                                                    required
                                                />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M17.4863 12.757C17.4863 13.0625 17.3649 13.3555 17.1489 13.5715C16.9329 13.7875 16.6399 13.9089 16.3344 13.9089C16.029 13.9089 15.736 13.7875 15.52 13.5715C15.304 13.3555 15.1826 13.0625 15.1826 12.757C15.1826 12.4516 15.304 12.1586 15.52 11.9426C15.736 11.7266 16.029 11.6052 16.3344 11.6052C16.6399 11.6052 16.9329 11.7266 17.1489 11.9426C17.3649 12.1586 17.4863 12.4516 17.4863 12.757Z" stroke="#211E24" strokeWidth="1.5" strokeLinejoin="round" />
                                                    <path d="M22.67 13.335C22.67 16.8342 20.3664 20.8218 16.335 25.4291C12.3036 20.8218 10 16.8342 10 13.335C10 11.6549 10.6674 10.0435 11.8555 8.85548C13.0435 7.66744 14.6549 7 16.335 7C18.0151 7 19.6265 7.66744 20.8145 8.85548C22.0026 10.0435 22.67 11.6549 22.67 13.335Z" stroke="#211E24" strokeWidth="1.5" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    ) : (null)}
                                    <Button className="typ-transparent-button buynowbtn" id="buyButton" onClick={() => PaymentProcess()} >Buy Now</Button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-start mt-3'>
                            <Container>
                                <p className="error-message errormsg">{errorMessage}</p>
                            </Container>
                        </div>
                        <div className='d-flex align-items-start mt-3'>
                            <h3 className='typ-features me-2'>Description:&nbsp;&nbsp;&nbsp;&nbsp;</h3>
                            <p className='rating'>{formattedDescription}</p>
                        </div>


                        <div className='second-cart-box'>
                            <div className='typ-second-row-width'>
                                <h3 className='typ-features'>Key features</h3>
                                <div className='key-flex'>
                                    <p className='feature_list'>Service type :</p>
                                    {serviceRequest === "serviceRequest" ? (
                                        <p className='feature_para'>{serviceName}</p>
                                    ):(
                                        <p className='feature_para'>Annual Maintenance Contract</p>
                                    )}
                                    
                                </div>
                                <div className='key-flex'>
                                    <p className='feature_list'>Product Covered  :</p>
                                    {serviceRequest === "serviceRequest" ? (
                                        <p className='feature_para'>{selectedCategoryType}</p>
                                    ) :
                                        <p className='feature_para'>{machineType}&nbsp;{Model}</p>}

                                    {/* <p className='feature_para'>{machineType}&nbsp;{Model}</p> */}
                                </div>

                                <p className='feature_list'>Zero Documentation Required  </p>
                                {serviceRequest !== "serviceRequest" ? (
                                    <p className='feature_list'>1 year Service period  </p>
                                ):null}
                                
                                {renderKeyFeatures()}
                            </div>
                            <div className='typ-second-row-width'>
                                <h3 className='typ-features'>Service Scope</h3>
                                {renderScopes()}
                            </div>
                            {/*{window.innerWidth < 768 ? (
                                <div className='typ-addBtn'>
                                    {renderGstValue()}

                                </div>
                            ) : null}*/}
                        </div>
                    </div>
                    <div className='cart-lyt-box'>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3 annual_tabs"
                            style={{ "overflow- x": "auto"}}
                        >
                            <Tab eventKey="home" title="Evolve program">
                                <div className='tab-first-row'>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>About</h2>
                                        <p className='tab--para'>An advance program focusing on comprehensive coverage for all major components and subsystems of your equipment</p>
                                    </div>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>Benefits</h2>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/protection.svg" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/pie-img.png" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='tab--heading'>What it includes?</h2>
                                        <div className='typ-tab-flex' >
                                            <img src="/asset/slideshow-img.svg" alt="" />
                                            <p className='typ--para'>The program includes related training and updates for your existing equipment</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/update-img.svg" alt="" />
                                            <p className='typ--para'>For many machines, additional software versions will be provided as they become available</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/settings.png" alt="" />
                                            <p className='typ--para'>To drive hardware compatibility and performance, one computer hardware upgrade kit is included in the service</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title="Remote assistance">
                                <div className='tab-first-row'>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>About</h2>
                                        <p className='tab--para'>An advance program focusing on comprehensive coverage for all major components and subsystems of your equipment</p>
                                    </div>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>Benefits</h2>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/protection.svg" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/pie-img.png" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='tab--heading'>What it includes?</h2>
                                        <div className='typ-tab-flex' >
                                            <img src="/asset/slideshow-img.svg" alt="" />
                                            <p className='typ--para'>The program includes related training and updates for your existing equipment</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/update-img.svg" alt="" />
                                            <p className='typ--para'>For many machines, additional software versions will be provided as they become available</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/settings.png" alt="" />
                                            <p className='typ--para'>To drive hardware compatibility and performance, one computer hardware upgrade kit is included in the service</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="contact" title="Regular updates">
                                <div className='tab-first-row'>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>About</h2>
                                        <p className='tab--para'>An advance program focusing on comprehensive coverage for all major components and subsystems of your equipment</p>
                                    </div>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>Benefits</h2>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/protection.svg" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/pie-img.png" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='tab--heading'>What it includes?</h2>
                                        <div className='typ-tab-flex' >
                                            <img src="/asset/slideshow-img.svg" alt="" />
                                            <p className='typ--para'>The program includes related training and updates for your existing equipment</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/update-img.svg" alt="" />
                                            <p className='typ--para'>For many machines, additional software versions will be provided as they become available</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/settings.png" alt="" />
                                            <p className='typ--para'>To drive hardware compatibility and performance, one computer hardware upgrade kit is included in the service</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="details" title="Other Details">
                                <div className='tab-first-row'>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>About</h2>
                                        <p className='tab--para'>An advance program focusing on comprehensive coverage for all major components and subsystems of your equipment</p>
                                    </div>
                                    <div className='typ-first-lyt'>
                                        <h2 className='tab--heading'>Benefits</h2>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/protection.svg" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="//asset/pie-img.png" className='img-fluid' alt="icon" />
                                            <p className='typ--para'>Protect your investment and optimize operating costs</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='tab--heading'>What it includes?</h2>
                                        <div className='typ-tab-flex' >
                                            <img src="/asset/slideshow-img.svg" alt="" />
                                            <p className='typ--para'>The program includes related training and updates for your existing equipment</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/update-img.svg" alt="" />
                                            <p className='typ--para'>For many machines, additional software versions will be provided as they become available</p>
                                        </div>
                                        <div className='typ-tab-flex'>
                                            <img src="/asset/settings.png" alt="" />
                                            <p className='typ--para'>To drive hardware compatibility and performance, one computer hardware upgrade kit is included in the service</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
                <Container fluid className="tenthSectionMaindiv">
                </Container>
                <Container fluid className="sixthsection-maindiv">
                    <Container className="sixthSectionSlider" ref={sliderRef}>
                        {containerData.map((product, index) => (
                            <ProductBlock
                                key={index}
                                title={product.title}
                                message={product.message}
                                imageUrl={product.imageUrl}
                                discount={product.discount}
                                productQuentity={product.productQuentity}
                            />
                        ))}
                    </Container>
                    {loginPortal && <LoginModel onHide={onHidePortal} />}
                </Container>
            </section>
        </>
    )
}

export default Index