// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legalfluidcontainer {
    display: flex;
    flex-direction: column;
}

.routescontainer {
    margin-top: 2rem;
    padding-bottom: 5px;
    margin-bottom: 2rem !important;
}

.routescontainer-navlink,
.routescontainer-navlink:hover {
    text-decoration: none;
    margin-right: 0.8rem;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #211E24;
    opacity: 60%;
    padding-bottom: 5px;
}

.routescontainer-navlink.active {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    opacity: 100%;
    margin-bottom: 3px;
    border-bottom: 3px solid #9BA24C;
}

@media only screen and (max-width: 600px) {

    .routescontainer-navlink,
    .routescontainer-navlink:hover {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Legal/Legal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;;IAEI,qBAAqB;IACrB,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;;IAEI;;QAEI,cAAc;IAClB;AACJ","sourcesContent":[".legalfluidcontainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.routescontainer {\r\n    margin-top: 2rem;\r\n    padding-bottom: 5px;\r\n    margin-bottom: 2rem !important;\r\n}\r\n\r\n.routescontainer-navlink,\r\n.routescontainer-navlink:hover {\r\n    text-decoration: none;\r\n    margin-right: 0.8rem;\r\n    font-family: Poppins;\r\n    font-size: 16px;\r\n    font-weight: 400;\r\n    line-height: 24px;\r\n    letter-spacing: 0px;\r\n    text-align: left;\r\n    color: #211E24;\r\n    opacity: 60%;\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.routescontainer-navlink.active {\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n    color: #000;\r\n    opacity: 100%;\r\n    margin-bottom: 3px;\r\n    border-bottom: 3px solid #9BA24C;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n\r\n    .routescontainer-navlink,\r\n    .routescontainer-navlink:hover {\r\n        display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
