// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .img-wrap{padding: 30px 20px;width:100%;max-height:400px;}
.bi-popup-wrap .inner .img-wrap .machine-img{width:100%;border-radius:8px;overflow:hidden;max-height:400px;object-fit:cover;}
.bi-popup-wrap .inner .pagination-main{display: flex;gap: 20px;}
.bi-popup-wrap .inner .pagination-main .left-btn,
.bi-popup-wrap .inner .pagination-main .right-btn{width: 46px;height: 46px;border: 1px solid #211E24;border-radius: 100%;background: none;}

@media(max-width:767px){
    .bi-popup-wrap .inner .pagination-main .left-btn,
    .bi-popup-wrap .inner .pagination-main .right-btn{width:30px;height: 30px;}
}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/ImageSlider.css"],"names":[],"mappings":"AAAA,gCAAgC,kBAAkB,CAAC,UAAU,CAAC,gBAAgB,CAAC;AAC/E,6CAA6C,UAAU,CAAC,iBAAiB,CAAC,eAAe,CAAC,gBAAgB,CAAC,gBAAgB,CAAC;AAC5H,uCAAuC,aAAa,CAAC,SAAS,CAAC;AAC/D;kDACkD,WAAW,CAAC,YAAY,CAAC,yBAAyB,CAAC,mBAAmB,CAAC,gBAAgB,CAAC;;AAE1I;IACI;sDACkD,UAAU,CAAC,YAAY,CAAC;AAC9E","sourcesContent":[".bi-popup-wrap .inner .img-wrap{padding: 30px 20px;width:100%;max-height:400px;}\r\n.bi-popup-wrap .inner .img-wrap .machine-img{width:100%;border-radius:8px;overflow:hidden;max-height:400px;object-fit:cover;}\r\n.bi-popup-wrap .inner .pagination-main{display: flex;gap: 20px;}\r\n.bi-popup-wrap .inner .pagination-main .left-btn,\r\n.bi-popup-wrap .inner .pagination-main .right-btn{width: 46px;height: 46px;border: 1px solid #211E24;border-radius: 100%;background: none;}\r\n\r\n@media(max-width:767px){\r\n    .bi-popup-wrap .inner .pagination-main .left-btn,\r\n    .bi-popup-wrap .inner .pagination-main .right-btn{width:30px;height: 30px;}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
