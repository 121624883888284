// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pninthbar::after{
    content: "";
    background: #735197;
    width: 80px;
    height: 1px;
    display: block;
    position: absolute;
    left: calc(50% - 40px);
    margin: 7px 0;
}
.pninthbar{
    position: relative;
}
 
  .marquee_image{
height:200px;
width: 408px;
padding:0px 10px;
  }
 
  @media (max-width:576px){
    .marquee_image{
      height:153px ;
      width: 241px;
      padding:0px 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/HomePage/ninth/ninth.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;;EAEE;AACF,YAAY;AACZ,YAAY;AACZ,gBAAgB;EACd;;EAEA;IACE;MACE,aAAa;MACb,YAAY;MACZ,gBAAgB;IAClB;EACF","sourcesContent":[".pninthbar::after{\r\n    content: \"\";\r\n    background: #735197;\r\n    width: 80px;\r\n    height: 1px;\r\n    display: block;\r\n    position: absolute;\r\n    left: calc(50% - 40px);\r\n    margin: 7px 0;\r\n}\r\n.pninthbar{\r\n    position: relative;\r\n}\r\n \r\n  .marquee_image{\r\nheight:200px;\r\nwidth: 408px;\r\npadding:0px 10px;\r\n  }\r\n \r\n  @media (max-width:576px){\r\n    .marquee_image{\r\n      height:153px ;\r\n      width: 241px;\r\n      padding:0px 10px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
