import React from "react";

const LightBlub = ({fill}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
    >
      <g opacity="0.6">
        <path
          d="M63.1796 35.19C62.2796 27.81 54.8096 19.8 45.1796 19.8C37.3496 19.8 30.3296 24.84 27.6296 32.4C26.9996 34.11 26.7296 35.82 26.8196 37.62C26.9096 40.5 27.5396 43.1101 28.7996 45.3601C30.0596 47.5201 31.1396 48.96 32.3096 50.49C33.6596 52.2001 35.0996 54 36.8096 57.24C37.3496 58.32 37.8896 59.4901 38.2496 60.6601C37.0796 61.1101 36.3596 62.19 36.3596 63.5401V67.5C36.3596 69.2101 37.7096 70.5601 39.4196 70.5601H50.7596C52.4696 70.5601 53.8196 69.2101 53.8196 67.5V63.4501C53.8196 62.1901 53.0096 61.1101 51.9296 60.6601C52.1996 59.4001 52.6496 58.1401 53.1896 57.0601C54.7196 53.8201 56.0696 52.2001 57.4196 50.49C58.4996 49.23 59.4896 47.97 60.7496 45.99C61.1996 45.09 63.8096 40.41 63.1796 35.19ZM52.0196 67.5C52.0196 68.2201 51.3896 68.8501 50.6696 68.8501H39.3296C38.6096 68.8501 37.9796 68.2201 37.9796 67.5V63.4501C37.9796 62.91 38.2496 62.46 38.6996 62.28H39.3296C40.5896 62.28 43.6496 62.28 44.9996 62.28C46.3496 62.28 49.4996 62.28 50.7596 62.28H51.2096C51.6596 62.46 51.9296 62.91 51.9296 63.4501V67.5H52.0196ZM59.1296 45C57.9596 46.89 56.9696 48.15 55.9796 49.32C54.4496 51.12 53.0996 52.8301 51.4796 56.25C50.8496 57.6001 50.3996 58.9501 50.1296 60.39H39.9596C39.5096 58.9501 38.9696 57.6 38.3396 56.34C36.6296 52.92 35.0996 51.12 33.6596 49.32C32.4896 47.88 31.4096 46.5301 30.2396 44.4601C29.1596 42.4801 28.5296 40.1401 28.4396 37.5301C28.3496 36 28.6196 34.38 29.1596 32.94C31.6796 26.1 37.9796 21.51 44.9996 21.51H45.0896C53.8196 21.51 60.5696 28.71 61.3796 35.37C62.0096 40.05 59.6696 44.2801 59.1296 45Z"
          fill={fill ? fill:"#211E24"}
        />
        <path
          d="M45 27.1799C39.15 27.1799 34.29 31.9499 34.29 37.8899C34.29 43.8299 39.15 48.5099 45 48.5099C50.85 48.5099 55.71 43.7399 55.71 37.7999C55.71 31.8599 50.85 27.1799 45 27.1799ZM45 46.7999C40.05 46.7999 36.09 42.7499 36.09 37.8899C36.09 33.0299 40.14 28.9799 45 28.9799C49.86 28.9799 53.91 33.0299 53.91 37.8899C53.91 42.7499 49.95 46.7999 45 46.7999Z"
          fill={fill ? fill:"#211E24"}
        />
        <path
          d="M52.6497 72.0901H37.0797C36.6297 72.0901 36.1797 72.4501 36.1797 72.9901C36.1797 73.5301 36.5397 73.8901 37.0797 73.8901H52.6497C53.0997 73.8901 53.5497 73.5301 53.5497 72.9901C53.5497 72.4501 53.1897 72.0901 52.6497 72.0901Z"
          fill={fill ? fill:"#211E24"}
        />
        <path
          d="M48.2396 35.1899L44.4596 38.6999L42.8396 36.9899C42.4796 36.6299 41.9396 36.6299 41.5796 36.9899C41.2196 37.3499 41.2196 37.8899 41.5796 38.2499L43.8296 40.4999C44.0096 40.6799 44.1896 40.7699 44.4596 40.7699C44.6396 40.7699 44.9096 40.6799 45.0896 40.4999L49.4096 36.3599C49.7696 35.9999 49.7696 35.4599 49.4096 35.0999C49.1396 34.8299 48.5096 34.8299 48.2396 35.1899Z"
          fill={fill ? fill:"#211E24"}
        />
      </g>
    </svg>
  );
};

export default LightBlub;
