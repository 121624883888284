

import React, { useState } from 'react'
import LeftArrow from '../../SubComponent/LeftArrow';
import StepperTick from '../../SubComponent/AllSvgs/StepperTick';
import FooterBottom2 from '../../Footer/FooterBottom2';
import { useNavigate } from 'react-router';
import SuccessPopup from '../../SubComponent/AllBlock/SuccessPopup';
import './FinanceStepper.css'
import InterestRateDiv from '../InterestRateDiv';
import FinanceDocumentUploder from './FinanceDocumentUploder';
import { tickIcon } from '../../../helpers/Icons';

const priceConvert = (price) => {
  price = typeof price === 'string' ? price : String(price);


      let count=1;
      let comma=3;
       let formatedPrice=""
       for(let i=price.length-1;i>=0;i--){
           formatedPrice=price[i]+formatedPrice
           if(count===comma){
                formatedPrice=","+formatedPrice
               comma=2;
               count=0;
           }count++;
        
       }
       console.log("==>>",formatedPrice)
          if(formatedPrice[0]===","){
              formatedPrice =formatedPrice.slice(1, formatedPrice.length)
           }
           return formatedPrice;
   

 
};
const FinanceStepper = () => {

  const[isProfit,setIsProfit]=useState("We are Profitable");

  const [showIntrestRate,SetshowIntrestRate]=useState(false);
  const [selectOption,setSelectOoption]=useState('')

  const [selectOption1,setSelectOoption1]=useState('')
  const [selectOption2,setSelectOoption2]=useState('')
  const [selectOption3,setSelectOoption3]=useState('')


const onOptionChange = (event) => {
 setSelectOoption(event.target.value);
};
const onOptionChange1 = (event) => {
  setSelectOoption1(event.target.value);
 };
 const onOptionChange2 = (event) => {
  setSelectOoption2(event.target.value);
 };
 const onOptionChange3 = (event) => {
  setSelectOoption3(event.target.value);
 };

 console.log("select option-->>>",selectOption3);
  const [activeStep,setActiveStep]=useState(1)
  
 const [validation,setvalidaion]=useState({descriptions:false,vselectedOption:false,vselectedOption1:false,vselectedOption2:false,vselectedOption3:false,Mrevenue:false,MNamevalidation:false,MMakevalidation:false,MAdd1validation:false,MPinValidation:false,fvalidation :false,lvalidation:false,evalidation:false,pvalidation :false });

  const navigate=useNavigate();

const [onetTimeRepairformData, setOnetTimeRepairFormData] = useState({
  machinename: '',
  machineaddress1: '',
  descriptions: '',
  pincode: '',
  problemwithequipment: '',
  phonenumber: '',
  revenue:""
});
const [showModel,setShowModel]=useState(false);


const uploderData=[
  {title:"Upload Quotation / Proforma Invoice of equipment"},
  {title:"Upload Latest audited financial statements / ITRs"},
  {title:"Upload Company / Individual PAN"},
  {title:"Upload Company / Individual PAN"}]
const onStepOneBtn=()=>{
  setActiveStep(1);
   }
const onStepTwoBtn=()=>{
  if(onetTimeRepairformData.machinename==="" || onetTimeRepairformData.descriptions==="" ||
 onetTimeRepairformData.machineaddress1==="" || onetTimeRepairformData.pincode==="" ||
 onetTimeRepairformData.phonenumber==="" ||  onetTimeRepairformData.phonenumber.length!==10
 )
  {
    if(onetTimeRepairformData.machinename==="" ){setvalidaion(prev=>({...prev,MNamevalidation:true}))}
    if(onetTimeRepairformData.descriptions===""){setvalidaion(prev=>({...prev,descriptions:true}))}
    if(onetTimeRepairformData.machineaddress1===""){setvalidaion(prev=>({...prev,MAdd1validation:true}))}
    if(onetTimeRepairformData.pincode==="" ){setvalidaion(prev=>({...prev,MPinValidation:true}))}
    if(onetTimeRepairformData.phonenumber==="" || onetTimeRepairformData.phonenumber.length!==10){setvalidaion(prev=>({...prev,pvalidation:true}))}
    return
  }
          setActiveStep(2);
       }


const onMachineNameChange=(e)=>setOnetTimeRepairFormData({ ...onetTimeRepairformData, machinename:e.target.value})

const onPhoneChange=(e)=>{
  
  const newInputString = e.target.value;
  const sanitizedInput = newInputString.replace(/[^0-9]/g, '');

  // Update the state only if the input is empty or contains valid characters
  if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 10) {
    setOnetTimeRepairFormData({ ...onetTimeRepairformData, phonenumber:sanitizedInput})}
  }

  
const onMachineaddress1Change=(e)=>setOnetTimeRepairFormData({ ...onetTimeRepairformData,   machineaddress1: e.target.value })
    

  const ondescriptionsChange=(e)=>setOnetTimeRepairFormData({ ...onetTimeRepairformData,   descriptions: e.target.value })
      
  const onGSTChange=(e)=>{
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, '')
      setOnetTimeRepairFormData({ ...onetTimeRepairformData, pincode:priceConvert(sanitizedInput)})
    
  }

  const topHeading=activeStep===2 ? "Finance Calculator":"Finance Application"
  const topPara=activeStep===2 ? "":"Fill out this form and apply for a lease, loan or both and finance your machine with ease."

 
const step1FormField={
  centerHeading:"Personal Details",
  centerPara:"Enter the details of the person applying for finance",
 bottomHeading:"Machine Details",
 bottomPara:"Enter the details of your machine to help us better understand your requirements",
}

const onHide=()=>{
  setShowModel(false)
  window.scrollTo(0, 0);
  navigate('/service')
}
const getButtonColor1 = () => {
  if (activeStep === 1) {
    return '#73509E';
  }  else {
    return '#3C7D0E';
  }
};
const getButtonColor2 = () => {
  if (activeStep === 2) {
    return '#73509E';
  } else if (activeStep < 2) {
    return '#DDDDDD';
  } else {
    return '#3C7D0E';
  }
};
const getButtonColor3 = () => {
  if (activeStep === 3) {
    return '#73509E';
  } else if (activeStep < 3) {
    return '#DDDDDD';
  } else {
    return '#3C7D0E';
  }
};
const onRevanueHandler=(e)=>{
  const newInputString = e.target.value;
  const sanitizedInput = newInputString.replace(/[^0-9]/g, '');

     setOnetTimeRepairFormData((prev)=>({...prev,revenue:priceConvert(sanitizedInput)}))


}

const onStepTwoMoveHandler=()=>{
  if(onetTimeRepairformData.revenue==="" || selectOption===""){
    if(onetTimeRepairformData.revenue===""){ setvalidaion((prev)=>({...prev,Mrevenue:true}))}
    if(selectOption===""){setvalidaion((prev)=>({...prev,vselectedOption:true}))}
    return
  }
  setActiveStep(3);

}
const onStepThreeHandler=()=>{
  if(selectOption1==="" || selectOption2==="" || selectOption3===""){
if(selectOption1===""){setvalidaion((prev)=>({...prev,vselectedOption1:true}))}
if(selectOption2===""){setvalidaion((prev)=>({...prev,vselectedOption2:true}))}
if(selectOption3===""){setvalidaion((prev)=>({...prev,vselectedOption3:true}))}
    return
  }
  navigate('/finance')
}
const onBackNaviagte=()=>{
 if(activeStep>1){
  setActiveStep(activeStep-1)
 }else{
  navigate('/finance')
 }
 
}
  return (
<>
{showModel && <SuccessPopup message={"Request Created successfully"} onHide={onHide}/>}
    <div className='max-container'>
     <div className='pt-5'>
    {/* Stepper Top Section*/}
   <div className='max-container pb-5'>
   <div className='container-fluid row p-0 m-0'>
      <div className='col col-lg-2 col-12 pl-0'>
         <LeftArrow callFun={onBackNaviagte}/>
      </div>
      {/* for desktop */}
   <div className='col col-lg-10 hide-992'>
      <div className='d-flex'>

      {/* step 1 btn */}
      <div className='d-flex'>
      <button className="stepperBtn" style={{backgroundColor:getButtonColor1()}} >{activeStep>1 ? tickIcon({width:20,height:20}):1}</button>
      <p className={`${activeStep===1 ? "heading-600-16-12":"heading-400-16-12 op-60 "}  v-center pl-2 pr-2`}>General Details</p>
      </div>
      
      {/* line */}
      { <div className=' mr-2 stepper-line v-center'></div> }
      {/* step 2 btn */}
      {<div className='d-flex'>
      <button className="stepperBtn" style={{backgroundColor:getButtonColor2()}} >{activeStep>2 ? tickIcon({width:20,height:20}):2}</button>
      <p className={`${activeStep===2 ? "heading-600-16-12":"heading-400-16-12 op-60 "} v-center pl-2 pr-2`}>Calculate interest rate</p>
      </div>}

{/* line */}
<div className='mr-2 stepper-line v-center'></div>
      {/* step 3 btn*/}
      <div className='d-flex'>
      <button className="stepperBtn" style={{backgroundColor:getButtonColor3()}} >{activeStep>3 ? tickIcon({width:20,height:20}):3}</button>
      <p className={`${activeStep===3 ? "heading-600-16-12":"heading-400-16-12 op-60 "}  v-center pl-2`}>Lending Details</p>
      </div>
      </div>
    </div>

      {/* for tablet start*/}
      <div className='Container show-992'>

        <div className='row'>
        {/* stepper button start*/}
          <div className='col col-12'>
            <div className="row ">
              <div className="col col-4 p-0 d-flex justify-content-end">
             
              <button className="stepperBtn" style={{backgroundColor:getButtonColor1()}} >{activeStep>1 ? tickIcon({width:15,height:15}):1}</button>
               <p style={{backgroundColor:" #00000066",width:"40%",height:'2px',margin:"auto 0px"}}></p>
               </div>
              <div className="col col-4 p-0 d-flex justify-content-center"> 
              <p style={{backgroundColor:" #00000066",width:"45%",height:'2px',margin:"auto 0px"}}></p>
              <button className="stepperBtn" style={{backgroundColor:getButtonColor2()}}>{activeStep>2 ? tickIcon({width:15,height:15}):2}</button>
              <p style={{backgroundColor:" #00000066",width:"45%",height:'2px',margin:"auto 0px"}}></p>
              </div>
              <div className="col col-4 p-0 d-flex justify-content-start">
              <p style={{backgroundColor:" #00000066",width:"40%",height:'2px',margin:"auto 0px"}}></p>
               <button className="stepperBtn" style={{backgroundColor:getButtonColor3()}} >{activeStep>3 ? tickIcon({width:20,height:20}):3}</button>
              
               </div>
            </div>
          </div>
          {/* stepper button end*/}

          {/* stepper button description start*/}
          <div className='col col-12'>
            <div className="row">
              <div className={`col-4 p-0 pt-2 text-center ${activeStep===1 ? "heading-600-16-12 pl-2":"heading-400-16-12 op-60 pl-2"} `}>General Details</div>
              <div className={`col-4 pt-2 text-center ${activeStep===2 ? " heading-600-16-12":" heading-400-16-12 op-60"}`}>Calculate interest rate</div>
              <div className={`col-4 pt-2 p-0 text-center ${activeStep===3 ? " heading-600-16-12":" heading-400-16-12 op-60"}`}>Lending Details</div>
            </div>
          </div>
           {/* stepper button description start*/}
        </div>
      </div>

      {/* for tablet end*/}
   </div>
   </div>
{/* Stepper Bottom Section*/}

 <div className='f-stpper-container bg-gray'>

  {/*Form top section start*/}
  <div className='row '>
  <div className='col col-12'>
  <h1 className='heading-600-24-20'>{topHeading}</h1>
  </div>
  <div className='col col-lg-11 col-12 '>
  <p className='heading-400-14 op-60 m-0'>{topPara}</p>
  </div>
</div>
{/*Form top section end*/}

{/* -----------------------Steper 1---------------------------------------- */}

 {activeStep===1 ?  <div className='container p-0'>
   
 {/*Form middle section start*/}
     <div className='row'>
     <div className='col col-lg-4 hide-992 pt-30'>
     
         <h1 className='heading-500-20 m-0'>{step1FormField.centerHeading}</h1>
         <p className='heading-400-14 op-60 pt-12'>{step1FormField.centerPara}</p>
         
     </div>

     <div className='col col-lg-8 col-12'>

     <div className={`bi-form-group ${validation.MNamevalidation ? "error-red":""}`}>
     <input type="text" name="machine-name" id="machine-name" className={`bi-form-field bg-white ${validation.MNamevalidation ? "error-red":""}`}  value={onetTimeRepairformData.machinename} onChange={onMachineNameChange} onClick={()=>setvalidaion(prev=>({...prev,MNamevalidation:false}))} placeholder="Machine Name"/>
     <label htmlFor="machine-name" className="heading-400-14-12 bi-form-label">Enter Name of the applicant{<span style={{ color: '#CB1923' }}>*</span>}</label>
 
</div>
     
  <div className={`bi-form-group ${validation.pvalidation ? "error-red":""}`}>
  <input type="text" name="machine-make" id="machine-make" className={`bi-form-field bg-white ${validation.pvalidation ? "error-red":""}`}  value={onetTimeRepairformData.phonenumber} onChange={onPhoneChange} onClick={()=>setvalidaion(prev=>({...prev,pvalidation:false}))} placeholder="Machine Make"/>
  <label htmlFor="machine-make" className="heading-400-14-12 bi-form-label">Enter Contact Details{<span style={{ color: '#CB1923' }}>*</span>}</label>

</div>
     </div>
     </div>
 {/*Form middle section end*/}

{/*Form bottom section start*/}
<div className='row pt-32-992'>
<div className="row border-top hide-992 m-0" style={{paddingTop:"2px"}}></div>
<div className='col col-lg-4 hide-992 pt-30'>

  <h1 className='heading-500-20 m-0'>{step1FormField.bottomHeading}</h1>
  <p className='heading-400-14 op-60 pt-12'>{step1FormField.bottomPara}</p>
  
</div>

<div className='col col-lg-8'>
<div className={`bi-form-group ${validation.MAdd1validation ? "error-red":""}`}>
<input type="text" name="machine-add1" id="machine-add1" className={`bi-form-field bg-white ${validation.MAdd1validation ? "error-red":""}`}  value={onetTimeRepairformData.machineaddress1} onChange={onMachineaddress1Change} onClick={()=>setvalidaion(prev=>({...prev,MAdd1validation:false}))} placeholder="Machine Make"/>
<label htmlFor="machine-add1" className="heading-400-14-12 bi-form-label">Enter Machine Name{<span style={{ color: '#CB1923' }}>*</span>}</label>

</div>
       
<div className={`bi-form-group  ${validation.descriptions ? "error-red":""}`}>
<input type="text" name="machine-add2" id="machine-add2" className={`bi-form-field bg-white  ${validation.descriptions ? "error-red":""}`}  value={onetTimeRepairformData.descriptions} onChange={ondescriptionsChange} onClick={()=>setvalidaion(prev=>({...prev,descriptions:false}))}  placeholder="Address Line 2"/>
<label htmlFor="machine-add2" className="heading-400-14-12 bi-form-label">Enter Machine Description{<span style={{ color: '#CB1923' }}>*</span>}</label>
</div>

<div className={`bi-form-group  ${validation.MPinValidation ? "error-red":""}`}>
<input type="text" name="machine-add1" id="machine-add1" className={`bi-form-field bg-white ${validation.MPinValidation ? "error-red":""}`} value={onetTimeRepairformData.pincode} onChange={onGSTChange} onClick={()=>setvalidaion(prev=>({...prev,MPinValidation:false}))} placeholder="Pine Code"/>
<label htmlFor="machine-add1" className="heading-400-14-12 bi-form-label">Enter Machine Value (Excl GST){<span style={{ color: '#CB1923' }}>*</span>}</label>

</div>
     <div className='pt-5 d-flex justify-content-end'>

     <button className='step_button heading-600-16-14' onClick={onStepTwoBtn}>Calculate interest rate</button> 
     </div>
</div>
</div>

{/*Form bottom section start*/}
 </div>:null}

 {/*-------------------------Step 2nd------------------------------------- */}

 {activeStep===2 ? <div className='container p-0'>
 <div className='row d-flex justify-content-between'>
 <div className='col col-lg-4 hide-992 pt-30'>
 
     <h1 className='heading-500-20'>Enter your details</h1>
     <p className='heading-400-14 op-60'>Enter the details given below to calculate your loan/lease interest rate</p>
     
 </div>

 <div className='col col-lg-8 col-12'>

<div className='f-check-select'>

<div className={`finance-bi-form-group`}>
<input type="text" name="machine-add1" id="machine-add1" className={`bi-form-field bg-white ${validation.Mrevenue ? "error-red":""}`}  value={onetTimeRepairformData.revenue} onChange={onRevanueHandler} onClick={()=>setvalidaion(prev=>({...prev,Mrevenue:false}))} placeholder="Machine Make"/>
<label htmlFor="machine-add1" className={` heading-400-14-12 bi-form-label c-gray ${validation.Mrevenue ? "error-red":""}`}>Enter your revenue in ₹</label>
</div>

<p className='heading-400-14-12 op-80 pt-24'>Select a relevant option</p>
<div className=''>
     
<div className={`d-flex justify-content-between f-border ${isProfit==="We are Profitable" ? 'bg-purple':'bg-white'}`} onClick={()=>setIsProfit("We are Profitable")}>
<p className={`heading-400-14 v-center pl-3  ${isProfit==="We are Profitable" ? 'text-white':null} `}>We are Profitable</p>
<StepperTick fill={` ${isProfit==="We are Profitable"  ? '#73509E':"#FFFFFF"}`}/>
</div>
</div>

<div className='pt-24'>

<div className={`d-flex justify-content-between f-border ${isProfit==="We are not Profitable" ? 'bg-purple':'bg-white'}`} onClick={()=>setIsProfit("We are not Profitable")}>
   <p className={`heading-400-14 v-center pl-3  ${isProfit==="We are not Profitable" ? 'text-white':null} `}>We are not Profitable</p>
   <StepperTick fill={` ${isProfit==="We are not Profitable" ? '#73509E':"#FFFFFF"}`}/>
</div>
</div>
<div>
                      <div className={`finance-bi-form-group ${validation.vselectedOption ? "error-red" : ""} mb-24`}>
                        <select className={`bi-form-field bi-select-dropdown ${selectOption ? "" : "empty"} ${selectOption ==="" ? "heading-400-14-12 c-gray":"heading-400-14-12" } ${validation.vselectedOption ? "error-red" : ""}`}  placeholder="state" onChange={onOptionChange} autoCapitalize='off'  onClick={()=>setvalidaion((prev)=>({...prev,selectedOption:false}))}>
                   
                        <option style={{display:"none"}}></option>
                        <option value="11.00 to 12.75%">11.00 to 12.75%</option>
                        <option value="14.00 to 15.75%">14.00 to 15.75%</option>
                        <option value="16.00 to 18.75%">16.00 to 18.75%</option>
                          
                        </select>
                        <label className="heading-400-14-12 bi-form-label">Select Credit Rating{<span style={{ color: '#CB1923' }}>*</span>}</label>

                      </div>
                    </div>
<InterestRateDiv rate={selectOption}/> 
   <div className='f-second-stepper-btn'>
  <button className='f-button-outline heading-600-16-14'>Call back</button>
   <button className='step_button heading-600-16-14' onClick={onStepTwoMoveHandler}>Apply with Documentation</button>
   </div>
</div>
 </div>
 </div>
 </div>:null}

 {/* --------------------- Step3----------------------------------------*/}
 {activeStep===3 ? <div className='container p-0'>
 <div className='row pt-5'>
 <div className='col col-lg-4 hide-992'>
 
     <h1 className='heading-500-20 m-0'>Lending Details</h1>
     <p className='heading-400-14 op-60 pt-12'>Select the factors of your loan</p>
     
 </div>

 <div className='col col-lg-8'>
 <div>
     <div className={`finance-bi-form-group ${validation.vselectedOption1 ? "error-red" : ""} finance-dropdown`}>
        <select className={`bi-form-field bi-select-dropdown ${selectOption1 ? "" : "empty"} bg-white ${selectOption1 ==="" ? "heading-400-14-12 c-gray":"heading-400-14-12" } ${validation.vselectedOption1 ? "error-red" : ""}`}  placeholder="state" onChange={onOptionChange1} onClick={()=>setvalidaion((prev)=>({...prev,vselectedOption1:false}))}>
                   
          <option style={{display:"none"}}></option>
          <option value="option1">option1</option>
          <option value="option2">option2</option>
          <option value="option3">option3</option>
                          
        </select>
     <label className="heading-400-14-12 f-dropdown-form-label">Select your lending preference{<span style={{ color: '#CB1923' }}>*</span>}</label>

       </div>

       <div className={`finance-bi-form-group ${validation.vselectedOption2 ? "error-red" : ""} finance-dropdown`}>
        <select className={`bi-form-field bi-select-dropdown ${selectOption2 ? "" : "empty"} bg-white ${selectOption2 ==="" ? "heading-400-14-12 c-gray":"heading-400-14-12" } ${validation.vselectedOption2 ? "error-red" : ""}`}  placeholder="state" onChange={onOptionChange2} onClick={()=>setvalidaion((prev)=>({...prev,vselectedOption2:false}))}>        
          <option style={{display:"none"}}></option>
          <option value="option1">option1</option>
          <option value="option2">option2</option>
          <option value="option3">option3</option>              
        </select>
        <label className="heading-400-14-12 f-dropdown-form-label">Select Tenure{<span style={{ color: '#CB1923' }}>*</span>}</label>
       </div>

       <div className={`finance-bi-form-group ${validation.vselectedOption3 ? "error-red" : ""} finance-dropdown`}>
       <select className={`bi-form-field bi-select-dropdown ${selectOption3 ? "" : "empty"} bg-white ${selectOption3 ==="" ? "heading-400-14-12 c-gray":"heading-400-14-12" } ${validation.vselectedOption3 ? "error-red" : ""}`}  placeholder="state" onChange={onOptionChange3} onClick={()=>setvalidaion((prev)=>({...prev,vselectedOption3:false}))}>        
         <option style={{display:"none"}}></option>
         <option value="option1">option1</option>
         <option value="option2">option2</option>
         <option value="option3">option3</option>              
       </select>
       <label className="heading-400-14-12 f-dropdown-form-label">Select Tenure{<span style={{ color: '#CB1923' }}>*</span>}</label>
      </div>
  </div>
 </div>
 </div>

 <div className='row pt-32-992'>
<div className=" border-top hide-992 m-0" style={{paddingTop:"2px"}}></div>
<div className='col col-lg-4 hide-992 pt-30'>

  <h1 className='heading-500-20 m-0'>Upload Documents</h1>
  <p className='heading-400-14 op-60 pt-12'>You can choose to upload these documents to speed up your application process. Although you can proceed with the same as well.</p>
  
</div>

<div className='col col-lg-8 f-pt-30'>
{uploderData.map((item,index)=>(
  <FinanceDocumentUploder key={index} id={index} title={item.title}/>
))}


<div className='pt-2 d-flex justify-content-end'>
    
    <button className='button heading-600-16-14' onClick={onStepThreeHandler}>Submit</button> 
    </div>
</div>
</div>
 </div>:null}
 </div>
    </div>
    <div className='text-end'>
    <img src='/asset/Frame1000004018.png' alt='Frame1000004018.png'/>
    </div>
    </div>
<FooterBottom2/>
    </>
  )
}

export default FinanceStepper