import React from "react";
import './ListofService.css'
import ImagewithTitlep from "../../SubComponent/ImagewithTitlep/ImagewithTitlep";
const ListofService = ({services}) => {

  return (
    <>
      {
        services.map(({ title, imageSource, message }, index) =>
          <div className="col-lg-2 col-md-3 col-6 p-0 curser-pointer" key={index} style={{height:"200px"}}>
           <ImagewithTitlep title={title} imageSource={imageSource} message={message} fillColor={"#9BA24C"}/>
          </div>
        )
      }
    </>
  );
};

export default ListofService;
