// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagewithtpb:hover p,
.imagewithtpb:hover h1 {
    color: #fff;
}

.imagewithtpb:hover {
    border-radius: 8px;
    background: #9B9E51;
    color: #fff;
    box-shadow: 0px 32px 64px 0px rgba(101, 105, 4, 0.24);
}

.homeCardBorder {
    border-right: 1px solid rgba(115, 80, 158,0.1);
    border-left: 1px solid rgba(115, 80, 158,0.1);
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage/FifthPage/FifthPage.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,qDAAqD;AACzD;;AAEA;IACI,8CAA8C;IAC9C,6CAA6C;AACjD","sourcesContent":[".imagewithtpb:hover p,\r\n.imagewithtpb:hover h1 {\r\n    color: #fff;\r\n}\r\n\r\n.imagewithtpb:hover {\r\n    border-radius: 8px;\r\n    background: #9B9E51;\r\n    color: #fff;\r\n    box-shadow: 0px 32px 64px 0px rgba(101, 105, 4, 0.24);\r\n}\r\n\r\n.homeCardBorder {\r\n    border-right: 1px solid rgba(115, 80, 158,0.1);\r\n    border-left: 1px solid rgba(115, 80, 158,0.1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
