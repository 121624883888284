// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amc-plan-slider{position: relative; display: flex;justify-content: space-between;}
.amc-plan-slider .slider-button-wrap{display:flex;align-items:center;column-gap:30px;}
.amc-plan-slider .slider-button-wrap .slider-button{width:42px;height:42px;overflow:hidden;display:flex;align-items:center;justify-content:center;border:1px solid #211E24;border-radius:100%;background:none;}
.amc-slider-container{padding-right: 10px;}
.amc-slider-container .cust-slider .slick-list .slick-track{display:flex;column-gap:20px;padding:10px 0;margin: 0;width: 100%;}

@media (max-width:576px) {
    .amc-plan-slider .slider-button-wrap .slider-button{width:32px;height:32px;overflow:hidden;display:flex;align-items:center;justify-content:center;border:1px solid #211E24;border-radius:100%;background:none;}
    .amc-plan-slider .slider-button-wrap{position: absolute; display:flex; left: 50%;transform: translateX(-50%);column-gap:30px;top:540px;}
    
}
@media (max-width:463px){
    .amc-plan-slider .slider-button-wrap{top:580px}
} `, "",{"version":3,"sources":["webpack://./src/component/Annual/ThirdSection.css"],"names":[],"mappings":"AAAA,iBAAiB,kBAAkB,EAAE,aAAa,CAAC,8BAA8B,CAAC;AAClF,qCAAqC,YAAY,CAAC,kBAAkB,CAAC,eAAe,CAAC;AACrF,oDAAoD,UAAU,CAAC,WAAW,CAAC,eAAe,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,wBAAwB,CAAC,kBAAkB,CAAC,eAAe,CAAC;AAC9M,sBAAsB,mBAAmB,CAAC;AAC1C,4DAA4D,YAAY,CAAC,eAAe,CAAC,cAAc,CAAC,SAAS,CAAC,WAAW,CAAC;;AAE9H;IACI,oDAAoD,UAAU,CAAC,WAAW,CAAC,eAAe,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,wBAAwB,CAAC,kBAAkB,CAAC,eAAe,CAAC;IAC9M,qCAAqC,kBAAkB,EAAE,YAAY,EAAE,SAAS,CAAC,2BAA2B,CAAC,eAAe,CAAC,SAAS,CAAC;;AAE3I;AACA;IACI,qCAAqC,SAAS;AAClD","sourcesContent":[".amc-plan-slider{position: relative; display: flex;justify-content: space-between;}\r\n.amc-plan-slider .slider-button-wrap{display:flex;align-items:center;column-gap:30px;}\r\n.amc-plan-slider .slider-button-wrap .slider-button{width:42px;height:42px;overflow:hidden;display:flex;align-items:center;justify-content:center;border:1px solid #211E24;border-radius:100%;background:none;}\r\n.amc-slider-container{padding-right: 10px;}\r\n.amc-slider-container .cust-slider .slick-list .slick-track{display:flex;column-gap:20px;padding:10px 0;margin: 0;width: 100%;}\r\n\r\n@media (max-width:576px) {\r\n    .amc-plan-slider .slider-button-wrap .slider-button{width:32px;height:32px;overflow:hidden;display:flex;align-items:center;justify-content:center;border:1px solid #211E24;border-radius:100%;background:none;}\r\n    .amc-plan-slider .slider-button-wrap{position: absolute; display:flex; left: 50%;transform: translateX(-50%);column-gap:30px;top:540px;}\r\n    \r\n}\r\n@media (max-width:463px){\r\n    .amc-plan-slider .slider-button-wrap{top:580px}\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
