import React from "react";

const Shield = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g opacity="0.6">
        <path
          d="M43.4118 17.1199L31.807 10.4504C30.7399 9.85018 29.406 9.85018 28.3389 10.4504L16.7341 17.1199C15.6669 17.7201 15 18.9206 15 20.1211V32.0594C15 38.0619 18.2013 43.6643 23.4035 46.6655L28.3389 49.5334C28.8724 49.8669 29.4727 50.0002 30.0729 50.0002C30.6732 50.0002 31.2734 49.8669 31.807 49.5334L36.8091 46.6655C41.9446 43.731 45.1459 38.1953 45.1459 32.2595V20.1211C45.1459 18.8539 44.4789 17.7201 43.4118 17.1199ZM43.8787 32.1928C43.8787 37.6618 40.9441 42.7972 36.2088 45.5317L31.2068 48.3996C30.5398 48.7997 29.6728 48.7997 29.0058 48.3996L24.0704 45.5317C19.2684 42.7972 16.2672 37.5951 16.2672 32.0594V20.1211C16.2672 19.3208 16.6674 18.5872 17.3343 18.2537L28.9391 11.5842C29.2726 11.3842 29.6728 11.3175 30.0063 11.3175C30.4064 11.3175 30.7399 11.3842 31.0734 11.5842L42.6782 18.2537C43.3451 18.6538 43.7453 19.3875 43.7453 20.1211V32.1928H43.8787Z"
          fill={fill ? fill:"#211E24"}
        />
        <path
          d="M30.0729 21.3218C25.7377 21.3218 22.1362 24.8566 22.1362 29.2584C22.1362 33.5936 25.671 37.1951 30.0729 37.1951C34.408 37.1951 38.0095 33.6602 38.0095 29.2584C38.0095 24.8566 34.408 21.3218 30.0729 21.3218ZM30.0729 35.8612C26.4047 35.8612 23.4701 32.9266 23.4701 29.2584C23.4701 25.5902 26.4047 22.6557 30.0729 22.6557C33.7411 22.6557 36.6756 25.5902 36.6756 29.2584C36.6756 32.9266 33.7411 35.8612 30.0729 35.8612Z"
          fill={fill ? fill:"#211E24"}
        />
        <path
          d="M32.8741 26.3902L28.8725 30.1918L27.2051 28.5911C26.9383 28.3243 26.5382 28.3243 26.2714 28.5911C26.0046 28.8579 26.0046 29.2581 26.2714 29.5248L28.3389 31.5924C28.4723 31.7258 28.6057 31.7924 28.8058 31.7924C28.9392 31.7924 29.1392 31.7258 29.2726 31.5924L33.6745 27.3906C33.9412 27.1238 33.9412 26.7237 33.6745 26.4569C33.5411 26.1234 33.1409 26.1234 32.8741 26.3902Z"
          fill={fill ? fill:"#211E24"}
        />
      </g>
    </svg>
  );
};

export default Shield;
